import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./SearchCompany.css";  

const SearchCompany = ({ userId, onSelectResult, styleClass, initialCompany }) => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [location, setLocation] = useState(null);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setLocation({
                lat: position.coords.latitude,
                lon: position.coords.longitude
            });
        }, (error) => {
            console.error("Error getting location:", error);
        });
    }, []);

    useEffect(() => {
        if (initialCompany) {
            setQuery(initialCompany.companyUser || '');
            if (initialCompany.id && initialCompany.companyUser) {
                onSelectResult(initialCompany);
            }
        }
    }, [initialCompany, onSelectResult]);

    const handleSearch = async (e) => {
        setQuery(e.target.value);
        if (e.target.value.length > 1) {
            try {
                const requestBody = {
                    query: e.target.value,
                    userId,
                };

                if (location) {
                    requestBody.userLocation = `${location.lat},${location.lon}`;
                }

                const response = await axios.post(process.env.REACT_APP_SEARCH_API, requestBody);
                const filteredResults = response.data.filter(result => result.__typename === 'Company');
                setResults(filteredResults);
            } catch (error) {
                console.error("Error making API call:", error);
            }
        } else {
            setResults([]);
        }
    };

    const handleSelect = (result) => {
        setQuery(result.companyUser); 
        onSelectResult(result);
        setResults([]); 
    };

    function createDefaultProfileImage(name) {
        const firstChar = name ? name.charAt(0).toUpperCase() : '';
        return `data:image/svg+xml;base64,${btoa(`<svg xmlns="http://www.w3.org/2000/svg" width="150" height="150">
          <circle cx="75" cy="75" r="75" fill="#23313d"/>
          <text x="50%" y="50%" font-family="Arial, sans-serif" font-size="64" dy=".3em" fill="#FFF" text-anchor="middle">${firstChar}</text>
        </svg>`)}`;
    }

    return (
        <div className={`search-company ${styleClass}`}>
            <input 
                type="text" 
                value={query} 
                onChange={handleSearch} 
                placeholder="Search companies" 
            />
            {results.length > 0 && (
                <ul>
                    {results.map((result) => (
                        <li key={result.id} onClick={() => handleSelect(result)}>
                            <img 
                                src={result.profilepicturekey ? `https://media.spiggl.com/public/${result.profilepicturekey}` : createDefaultProfileImage(result.companyName)} 
                                alt={`${result.companyName}`} 
                            />
                            <div>
                                <strong>{result.companyName}</strong>
                                <p>{result.companyUser}</p>
                            </div>
                            <span>Company</span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SearchCompany;
