import React, { useState, useEffect, useRef } from "react";
import "./UserPastReviews.css";
import { useNavigate } from "react-router-dom";
import { generateClient } from 'aws-amplify/api';
import { useAuth } from "../../../auth/auth";
import { reviewsByUserID, getCompany } from '../../../graphQL/queries';
import { deleteReview } from '../../../graphQL/mutations';
import { format } from 'date-fns';
import EditReviewModal from '../EditReviewModal/EditReviewModal'; 
import ImageModal from "../../Syn/ImageModal/ImageModal";
import DeleteConfirmationModal from "../../Syn/DeleteConfirmationModal/DeleteConfirmationModal";

const UserPastReviews = () => {
  const [pastReviews, setPastReviews] = useState([]);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [showMenu, setShowMenu] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [reviewToDelete, setReviewToDelete] = useState(null);
  const { userID } = useAuth();
  const navigate = useNavigate();
  const client = generateClient();
  const [showModal, setShowModal] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [currentAttachments, setCurrentAttachments] = useState([]);
  const [editReview, setEditReview] = useState(null);
  const menuRefs = useRef({});

  const [currentPage, setCurrentPage] = useState(1);
  const reviewsPerPage = 7;

  const openModal = (index, attachments) => {
    setSelectedImageIndex(index);
    setCurrentAttachments(attachments);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const fetchReviews = async () => {
    if (userID) {
      try {
        const userData = await client.graphql({
          query: reviewsByUserID,
          variables: { userID: userID }
        });
        const reviews = userData.data.reviewsByUserID.items;

        const reviewsWithCompanyData = await Promise.all(reviews.map(async (review) => {
          try {
            const companyData = await client.graphql({
              query: getCompany,
              variables: { id: review.companyID }
            });

            const company = companyData.data.getCompany;

            const companyWithImageUrls = {
              ...company,
              profilePictureUrl: company?.profilepicturekey
                ? `https://media.spiggl.com/public/${company.profilepicturekey}`
                : "placeholder-profile-image.png"
            };

            const reviewWithAttachmentUrls = {
              ...review,
              attachments: review.attachmentkey ? review.attachmentkey.map(att => `https://media.spiggl.com/public/${att}`) : []
            };

            return { ...reviewWithAttachmentUrls, company: companyWithImageUrls };
          } catch (companyError) {
            console.error('Error fetching company data:', companyError);
            return review;
          }
        }));

        reviewsWithCompanyData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        setPastReviews(reviewsWithCompanyData);
      } catch (error) {
        console.error("Error fetching user reviews:", error);
      }
    } else {
      console.error('No user ID provided in URL parameters.');
    }
  };

  useEffect(() => {
    fetchReviews();
  }, [userID, client]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      Object.keys(menuRefs.current).forEach(key => {
        if (menuRefs.current[key] && !menuRefs.current[key].contains(event.target)) {
          setShowMenu(prev => ({ ...prev, [key]: false }));
        }
      });
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const renderStars = (rating) => {
    return [...Array(5)].map((e, i) => (
      <span key={i} className={`star ${i < rating ? 'filled' : 'empty'}`}>★</span>
    ));
  };

  const renderAttachments = (attachments) => {
    const additionalCount = attachments.length > 4 ? attachments.length - 4 : 0;

    return (
      <div className="user-review-attachments">
        {attachments.slice(0, 4).map((attachment, index) => (
          <div key={index} className="attachment-wrapper" onClick={() => openModal(index, attachments)}>
            <img
              src={attachment}
              alt={`Attachment ${index + 1}`}
              className="user-review-attachments__icon"
            />
            {index === 3 && additionalCount > 0 && (
              <div className="overlay">
                +{additionalCount}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const navigateToCompanyProfile = (companyUser) => {
    navigate(`/contractor/${companyUser}`);
  };

  const toggleDescriptionExpansion = (reviewId) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [reviewId]: !prev[reviewId]
    }));
  };

  const renderDescription = (description, reviewId) => {
    const limit = 550;
    const isExpanded = expandedDescriptions[reviewId];

    return (
      <div className="review-description">
        {isExpanded ? description : `${description.slice(0, limit)}`}
        {description.length > limit && (
          <button className="more-button" onClick={() => toggleDescriptionExpansion(reviewId)}>
            {isExpanded ? 'Show less' : 'More'}
          </button>
        )}
      </div>
    );
  };

  const handleMenuToggle = (reviewId) => {
    setShowMenu((prev) => ({
      ...prev,
      [reviewId]: !prev[reviewId]
    }));
  };

  const handleEditReview = (review) => {
    setEditReview(review);
  };

  const handleDeleteReview = (reviewId) => {
    setReviewToDelete(reviewId);
    setShowDeleteModal(true);
  };

  const confirmDeleteReview = async () => {
    try {
      await client.graphql({
        query: deleteReview,
        variables: { input: { id: reviewToDelete } },
      });
      setPastReviews((prev) => prev.filter((review) => review.id !== reviewToDelete));
      setShowDeleteModal(false);
      setReviewToDelete(null);
    } catch (error) {
      console.error("Error deleting review:", error);
    }
  };

  const handleUpdateReview = (updatedReview) => {
    setPastReviews((prevReviews) => prevReviews.map((review) =>
      review.id === updatedReview.id ? updatedReview : review
    ));
  };

  const indexOfLastReview = currentPage * reviewsPerPage;
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
  const currentReviews = pastReviews.slice(indexOfFirstReview, indexOfLastReview);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(pastReviews.length / reviewsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0); 
  };

  return (
    <div className="user-reviews-container">
      <ImageModal 
        show={showModal} 
        attachments={currentAttachments} 
        selectedIndex={selectedImageIndex} 
        onClose={closeModal}
        onNext={() => setSelectedImageIndex((prevIndex) => (prevIndex + 1) % currentAttachments.length)}
        onPrev={() => setSelectedImageIndex((prevIndex) => (prevIndex - 1 + currentAttachments.length) % currentAttachments.length)}
      />
      {showDeleteModal && (
        <DeleteConfirmationModal
          onClose={() => setShowDeleteModal(false)}
          onConfirm={confirmDeleteReview}
        />
      )}
      {editReview && (
        <EditReviewModal
          review={editReview}
          show={!!editReview}
          onClose={() => setEditReview(null)}
          onUpdate={handleUpdateReview}
        />
      )}
      {currentReviews.length === 0 ? (
        <div className="no-posts-container">
          <p>No Posts</p>
          <img src="/icons/write.png" alt="No posts" className="no-posts-icon" />
        </div>
      ) : (
        currentReviews.map((review) => (
          <div className="user-review" key={review.id}>
              <div className="user-review-header">
                {review.company?.profilePictureUrl && (
                  <img src={review.company.profilePictureUrl} alt="Company Profile" />
                )}
                <div className="company-id-container">
                  <span className="company-id" onClick={() => navigateToCompanyProfile(review.company?.companyUser)}>
                    {review.company?.companyName || review.company?.companyUser}
                  </span>
                  <div className="review-menu" ref={el => menuRefs.current[review.id] = el}>
                    <span className="review-date">{format(new Date(review.createdAt), 'M/d/yy')}</span>
                    <button className="review-menu-button" onClick={() => handleMenuToggle(review.id)}>...</button>
                    {showMenu[review.id] && (
                      <div className="edit-review-dropdown-menu">
                        <button onClick={() => handleEditReview(review)}>Edit Post</button>
                        <button onClick={() => handleDeleteReview(review.id)}>Delete Post</button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="user-review-title">{review.title}</div>
              <div className="user-review-body">
                <div className="star-ratings-container">
                  <div className="user-review-ratings">
                    <div className="user-star-ratings">
                      <span>Quality:</span>
                      {renderStars(review.quality)}
                    </div>
                    <div className="user-star-ratings">
                      <span>Cost:</span>
                      {renderStars(review.cost)}
                    </div>
                    <div className="user-star-ratings">
                      <span>Timeliness:</span>
                      {renderStars(review.timeliness)}
                    </div>
                    <div className="user-star-ratings">
                      <span>Overall:</span>
                      {renderStars(review.overall)}
                    </div>
                  </div>
                </div>
                <div className="user-review-attachments">
                  {renderAttachments(review.attachments || [])}
                </div>
              </div>
              <div className="user-review-description">
                {renderDescription(review.description, review.id)}
              </div>
          </div>
        ))
      )}
      <div className="pagination">
        {pageNumbers.map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => handlePageClick(pageNumber)}
            className={`page-number ${currentPage === pageNumber ? 'active' : ''}`}
          >
            {pageNumber}
          </button>
        ))}
      </div>
    </div>
  );
};

export default UserPastReviews;