import React, { useEffect, useState } from 'react';
import { generateClient } from 'aws-amplify/api';
import { useAuth } from "../../../../auth/auth";
import * as queries from '../../../../graphQL/queries';
import './PaymentHistory.css';

const PaymentHistory = () => {
  const [history, setHistory] = useState({
    transactions: 0,
    projects: 0,
    amount: 0,
    latePayments: 0,
    onTimePercentage: 0,
  });
  const client = generateClient();
  const { userID } = useAuth();

  useEffect(() => {
    const fetchPaymentHistory = async () => {
      try {
        const paymentHistoryData = await client.graphql({
          query: queries.invoicesByUserID,
          variables: { userID, filter: { status: { eq: "Complete" } } }
        });
  
        if (paymentHistoryData.data.invoicesByUserID && paymentHistoryData.data.invoicesByUserID.items.length > 0) {
          const completedInvoices = paymentHistoryData.data.invoicesByUserID.items;
  
          const totalProjects = completedInvoices.length;
          let totalTransactions = 0;
          let totalAmount = 0;
          let totalLatePayments = 0;
          let totalOnTimePayments = 0;
  
          completedInvoices.forEach(invoice => {
            const numPayments = invoice.latePayments?.length || 0;
            totalTransactions += numPayments;
            
            if (invoice.feeAmountIncluded && Array.isArray(invoice.feeAmountIncluded)) {
              const invoiceTotal = invoice.feeAmountIncluded.reduce((sum, amount) => sum + parseFloat(amount || 0), 0);
              totalAmount += invoiceTotal;
            }
  
            if (invoice.latePayments && Array.isArray(invoice.latePayments)) {
              invoice.latePayments.forEach(payment => {
                const lateDays = parseInt(payment, 10);
                if (lateDays > 0) {
                  totalLatePayments++;
                } else {
                  totalOnTimePayments++;
                }
              });
            }
          });
  
          const onTimePercentage = totalTransactions > 0 ? (totalOnTimePayments / totalTransactions) * 100 : 0;
  
          setHistory({
            transactions: totalTransactions,
            projects: totalProjects,
            amount: totalAmount,
            latePayments: totalLatePayments,
            onTimePercentage: onTimePercentage.toFixed(2),
          });
        } else {
          setHistory({
            transactions: 0,
            projects: 0,
            amount: 0,
            latePayments: 0,
            onTimePercentage: 0,
          });
        }
      } catch (error) {
        console.error("Error fetching payment history:", error);
      }
    };
  
    if (userID) {
      fetchPaymentHistory();
    }
  }, [userID, client]);

  const formatAmountWithCommas = (amount) => {
    return amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  return (
    <div className="payment-history">
      {history.projects > 0 ? (
        <>
          <div className="invoice-history-item">
            <div className="invoice-history-label">Total Amount:</div>
            <div className="invoice-history-value">${formatAmountWithCommas(history.amount)}</div>
          </div>
          <div className="invoice-history-item">
            <div className="invoice-history-label">Total Transactions:</div>
            <div className="invoice-history-value">{history.transactions}</div>
          </div>
          <div className="invoice-history-item">
            <div className="invoice-history-label">Total Projects:</div>
            <div className="invoice-history-value">{history.projects}</div>
          </div>
          <div className="invoice-history-item">
            <div className="invoice-history-label">Late Payments:</div>
            <div className="invoice-history-value">{history.latePayments}</div>
          </div>
          <div className="invoice-history-item">
            <div className="invoice-history-label">On-time Payments:</div>
            <div className="invoice-history-value">{history.onTimePercentage}%</div>
          </div>
        </>
      ) : (
        <div className="invoice-history-no-history">No payment history available</div>
      )}
    </div>
  );
};

export default PaymentHistory;