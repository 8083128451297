import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import FormHeader from '../../../Headers/FormHeader/FormHeader';
import { useAuth } from '../../../../auth/auth';
import { generateClient } from 'aws-amplify/api';
import { Helmet } from 'react-helmet';
import './LinkBank.css';
import * as mutations from '../../../../graphQL/mutations';

const LinkBank = () => {
  const stripe = useStripe();
  const [routeNumber, setRouteNumber] = useState('');
  const [acctNumber, setAcctNumber] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [showRouteNumber, setShowRouteNumber] = useState(false);
  const [showAcctNumber, setShowAcctNumber] = useState(false);
  const client = generateClient();
  const navigate = useNavigate();
  const { userID } = useAuth();

  const validateNumber = (num, type) => {
    const maxLength = type === 'routing' ? 9 : 12;
    const minLength = type === 'routing' ? 9 : 8;
    const regex = /^\d+$/;
    if (!regex.test(num)) return `${type === 'routing' ? 'Routing' : 'Account'} number must contain only digits.`;
    if (num.length > maxLength) return `${type === 'routing' ? 'Routing' : 'Account'} number cannot exceed ${maxLength} digits.`;
    if (num.length < minLength) return `${type === 'routing' ? 'Routing' : 'Account'} number must be at least ${minLength} digits.`;
    return '';
  };

  const handleAddBankMethod = async (e) => {
    e.preventDefault();
    const routeError = validateNumber(routeNumber, 'routing');
    const acctError = validateNumber(acctNumber, 'account');
    
    if (routeError || acctError) {
      setError(routeError || acctError);
      return;
    }

    if (!stripe) {
      setError('Stripe has not loaded correctly.');
      return;
    }

    try {
      const { token, error: stripeError } = await stripe.createToken('bank_account', {
        country: 'US', 
        currency: 'usd',
        routing_number: routeNumber,
        account_number: acctNumber,
        account_holder_name: name,
        account_holder_type: 'individual',
      });

      if (stripeError) {
        setError(stripeError.message);
        return;
      }

      const input = {
        stripeBankToken: token.id,
        last4: acctNumber.slice(-4),
        companyID: userID, 
      };

      await client.graphql({
        query: mutations.createBankMethod,
        variables: { input },
      });

      navigate(-1);
    } catch (err) {
      console.error('Error linking bank method:', err);
      setError('Failed to link bank method.');
    }
  };

  return (
    <div className="link-bank">
      <FormHeader />
      <Helmet>
        <title>Link bank</title>
      </Helmet>
      <div className="link-bank-container">
        <div className="link-bank-label method">
          <div className="link-bank-method">Link Bank Account</div>
        </div>
        <form className="link-bank-input-container" onSubmit={handleAddBankMethod}>
          <input
            type="text"
            placeholder="Account Holder Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <input
            type={showRouteNumber ? 'text' : 'password'}
            placeholder="Routing Number"
            value={routeNumber}
            maxLength="9"
            pattern="\d*"
            onChange={(e) => setRouteNumber(e.target.value.replace(/\D/g, '').slice(0, 9))}
            required
          />
          <i
            className={`eye-icon ${showRouteNumber ? 'fa fa-eye' : 'fa fa-eye-slash'}`}
            onClick={() => setShowRouteNumber(!showRouteNumber)}
          ></i>
          <input
            type={showAcctNumber ? 'text' : 'password'}
            placeholder="Account Number"
            value={acctNumber}
            maxLength="12"
            pattern="\d*"
            onChange={(e) => setAcctNumber(e.target.value.replace(/\D/g, '').slice(0, 12))}
            required
          />
          <i
            className={`link-bank-eye-icon ${showAcctNumber ? 'fa fa-eye' : 'fa fa-eye-slash'}`}
            onClick={() => setShowAcctNumber(!showAcctNumber)}
          ></i>
          <button className="link-bank-method-button" type="submit">
            Link Bank
          </button>
          {error && <div className="link-bank-error-message">{error}</div>}
        </form>
      </div>
    </div>
  );
};

export default LinkBank;
