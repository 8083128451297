import { useState, useEffect } from 'react';

const useStripeKey = () => {
  const [apiKey, setApiKey] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchApiKey = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_STRIPE_KEY, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        });

        if (!response.ok) {
          const errorText = await response.text();
          let errorData;
          try {
            errorData = JSON.parse(errorText);
          } catch {
            errorData = { error: errorText };
          }
          
          throw new Error(
            errorData.details || 
            errorData.error || 
            `HTTP error! status: ${response.status}`
          );
        }

        const data = await response.json();
        if (!data.apiKey) {
          throw new Error('API key not found in response');
        }

        setApiKey(data.apiKey);
        setError(null);
      } catch (err) {
        setError(err.message);
        setApiKey(null);
      } finally {
        setLoading(false);
      }
    };

    fetchApiKey();
  }, []);

  return { apiKey, loading, error };
};

export default useStripeKey;
