import React, { useEffect, useState } from 'react';
import { generateClient } from 'aws-amplify/api';
import { useAuth } from "../../../../auth/auth";
import * as queries from '../../../../graphQL/queries';
import * as mutations from '../../../../graphQL/mutations';
import Modal from 'react-modal';
import './CurrentInvoice.css';

Modal.setAppElement('#root');

const CurrentInvoice = () => {
  const [invoices, setInvoices] = useState([]);
  const [users, setUsers] = useState({});
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const client = generateClient();
  const { userID } = useAuth();

  useEffect(() => {
    const fetchCurrentInvoices = async () => {
      try {
        const invoiceData = await client.graphql({
          query: queries.invoicesByCompanyID,
          variables: { companyID: userID, filter: { status: { eq: "Pending" } } }
        });

        if (invoiceData.data.invoicesByCompanyID && invoiceData.data.invoicesByCompanyID.items.length > 0) {
          const pendingInvoices = invoiceData.data.invoicesByCompanyID.items;

          const userPromises = pendingInvoices.map(invoice => 
            client.graphql({
              query: queries.getUser,
              variables: { id: invoice.userID }
            })
          );

          const userData = await Promise.all(userPromises);
          const userMap = {};
          userData.forEach((response, index) => {
            if (response.data.getUser) {
              userMap[pendingInvoices[index].userID] = response.data.getUser;
            }
          });

          const sortedInvoices = pendingInvoices.sort((a, b) => {
            const nextPaymentA = getCurrentOrNextPaymentDetails(a);
            const nextPaymentB = getCurrentOrNextPaymentDetails(b);
            return new Date(nextPaymentA?.dueDate || Infinity) - new Date(nextPaymentB?.dueDate || Infinity);
          });

          setInvoices(sortedInvoices);
          setUsers(userMap);
        } else {
          console.error("No pending invoices found for userID:", userID);
        }
      } catch (error) {
        console.error("Error fetching current invoices:", error);
      }
    };

    if (userID) {
      fetchCurrentInvoices();
    }
  }, [userID, client]);

  const formatDate = (dateString) => {
    if (!dateString) return 'Invalid date';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'Invalid date';
    const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };

  const formatAmount = (amount) => {
    return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  const getCurrentOrNextPaymentDetails = (invoice) => {
    const now = new Date();
    const today = new Date(now.setHours(0, 0, 0, 0)); 
    let currentOrNextPayment = null;
  
    for (let i = 0; i < invoice.payments.length; i++) {
      let dueDate = new Date(invoice.dueDates[i]);
      const dueDateWithoutTime = new Date(dueDate.setHours(0, 0, 0, 0));
  
      if (invoice.oneTimePayment) {
        dueDateWithoutTime.setDate(dueDateWithoutTime.getDate() + 1);
      }
  
      if (dueDateWithoutTime <= today) {
        currentOrNextPayment = {
          amount: invoice.payments[i],
          total: invoice.payments[i],
          dueDate: dueDateWithoutTime.toISOString(),
          isPastDue: dueDateWithoutTime < today,
          isToday: dueDateWithoutTime.getTime() === today.getTime()
        };
      }
    }
  
    if (!currentOrNextPayment) {
      const nextPaymentIndex = invoice.payments.findIndex((_, index) => 
        new Date(invoice.dueDates[index]).setHours(0, 0, 0, 0) > today
      );
  
      if (nextPaymentIndex !== -1) {
        let nextDueDate = new Date(invoice.dueDates[nextPaymentIndex]);
        
        if (invoice.oneTimePayment) {
          nextDueDate.setDate(nextDueDate.getDate() + 1);
        }
  
        currentOrNextPayment = {
          amount: invoice.payments[nextPaymentIndex],
          total: invoice.payments[nextPaymentIndex],
          dueDate: nextDueDate.toISOString(),
          isPastDue: false,
          isToday: false
        };
      }
    }
  
    if (currentOrNextPayment) {
      return {
        amount: formatAmount(currentOrNextPayment.amount),
        total: formatAmount(currentOrNextPayment.total),
        dueDate: formatDate(currentOrNextPayment.dueDate),
        isPastDue: currentOrNextPayment.isPastDue,
        isToday: currentOrNextPayment.isToday
      };
    }
  
    return null;
  };
  

  const handleCancelInvoice = async () => {
    try {
      await client.graphql({
        query: mutations.deleteInvoices,
        variables: { input: { id: selectedInvoice.id } }
      });

      setInvoices(invoices.filter(invoice => invoice.id !== selectedInvoice.id));
      setIsCancelModalOpen(false);
      setSelectedInvoice(null);
    } catch (error) {
      console.error('Error canceling invoice:', error);
      alert('Failed to cancel invoice');
    }
  };

  const openCancelModal = (invoice) => {
    setSelectedInvoice(invoice);
    setIsCancelModalOpen(true);
  };

  const closeCancelModal = () => {
    setIsCancelModalOpen(false);
    setSelectedInvoice(null);
  };

  return (
    <div className="current-invoice">
      {invoices.length > 0 ? (
        invoices.map((invoice) => {
          const paymentDetails = getCurrentOrNextPaymentDetails(invoice);
          const user = users[invoice.userID];
          return paymentDetails ? (
            <div key={invoice.id} className="current-invoice-details">
              <div className="current-invoice-header">
                <div className="current-invoice-company-name">
                  {user.name || user.user ? (
                    <a 
                      href={`/user/${user.user}`} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="company-link"
                    >
                      {user.name || user.user}
                    </a>
                  ) : (
                    user.name || user.user
                  )}
                </div>
                <div className="current-invoice-amount">Amount: {paymentDetails.total}</div>
              </div>
              <div className="current-invoice-bottom">
                <div 
                  className={`current-invoice-due-date ${
                    paymentDetails?.isPastDue ? 'due-text-red' :
                    paymentDetails?.isToday ? 'due-text-yellow' :
                    'due-text-default'
                  }`}
                >
                  Due: {paymentDetails.dueDate}
                </div>
                <button className="current-invoice-cancel-button" onClick={() => openCancelModal(invoice)}>Cancel</button>
              </div>
            </div>
          ) : null;
        })
      ) : (
        <div className="current-invoice-no-invoice">No current payments</div>
      )}
      <Modal
        isOpen={isCancelModalOpen}
        onRequestClose={closeCancelModal}
        className="confirm-payment-modal-content"
        overlayClassName="confirm-payment-modal-overlay"
      >
        <div className="confirm-payment-modal-content">
          <h2>Cancel Entire Invoice?</h2>
          <div className="confirm-payment-modal-actions">
            <button className="confirm-payment-confirm-button" onClick={handleCancelInvoice}>Confirm</button>
            <button className="confirm-payment-cancel-button" onClick={closeCancelModal}>Cancel</button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CurrentInvoice;
