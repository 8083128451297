import React, { useEffect } from 'react';
import './Support.css';
import { Helmet } from 'react-helmet';
import Footer from '../../Footer/Footer';
import FormHeader from '../../Headers/FormHeader/FormHeader';

function Support() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Support">
      <FormHeader />
      <Helmet>
        <title>Support</title>
      </Helmet>
      <div className="support-title-banner">
        <div className="support-title">
          Support
        </div>
      </div>
      <div className="support-container">
        <div className="support-header">1. Introduction</div>
        <p className="support-paragraph">Welcome to The Spiggl support page. This guide provides comprehensive information on how to use our platform effectively. 
          Whether you're a homeowner looking to hire a contractor or a contractor offering services, this support page covers all the features and functionalities of our platform.</p>
       
        <div className="support-header">2. Account Management</div>
        <p className="support-paragraph">Creating an Account</p>
        <ol className="support-list">
          <li>Click the log in button located at the top right of the screen.</li>
          <li>Click "Create Profile" under the log in button.</li>
          <li>Choose account type.</li>
          <li>For users, enter your email, username of your choice, and create a password.</li>
          <li>For contractors, create a username of your choice, enter your email, contractor type and specialties, general price range, zipcode, and create a password.</li>
          <li>For both user types please fill in a profile picture and a background picture, if you do not have a background picture click "Choose Default Background" and there will be 
            a list of images to use.</li>
          <li>Make sure to read Terms of Service before creating an account.</li>
          <li>Verify your email address by typing in the code sent to your inbox.</li>
          <li>Upon valid confirmation code you will be logged in and brought to your account.</li>
          <li>If errors occur please try again, if that does not work please contact support@spiggl.com.</li>
        </ol>
        <p className="support-paragraph">Logging In</p>
        <ol className="support-list">
          <li>Click the log in button located at the top right of the screen.</li>
          <li>Enter your registered email address and password.</li>
          <li>Click "Log In" to access your account.</li>
          <li>If you forgot your password, click "Forgot Password" to reset it.</li>
        </ol>
        <p className="support-paragraph">Updating Profile Information</p>
        <ol className="support-list">
          <li>Navigate to your profile page.</li>
          <li>Click the icon that looks like a headshot.</li>
          <li>Update your personal and or company information.</li>
          <li>Click "Update" to update your profile.</li>
        </ol>
        <p className="support-paragraph">Changing Password</p>
        <ol className="support-list">
          <li>Navigate to your profile page.</li>
          <li>Click the icon that looks like a headshot.</li>
          <li>On the side bar click "Password".</li>
          <li>Enter the correct password and then enter the new password you wish to have.</li>
          <li>Click "Update Passcode" to confirm.</li>
        </ol>
        <p className="support-paragraph">Deleting Profile</p>
        <ol className="support-list">
          <li>Navigate to your profile page.</li>
          <li>Click the icon that looks like a headshot.</li>
          <li>On the side bar click "Delete Profile".</li>
          <li>Enter the correct password and click "Confirm".</li>
        </ol>
        
       
        <div className="support-header">3. Searching for Contractors</div>
        <p className="support-paragraph">Using Filters</p>
        <ol className="support-list">
          <li>Go to the home page.</li>
          <li>Enter the type of contractor you are looking for and where.</li>
          <li>Click "Search" to see the results.</li>
          <li>At the top of the screen there are fields to change the search input, change the contactor type or town and click "Search" again.</li>
          <li>Use filters to narrow down your search by distance, specialties, price range, and ratings.</li>
          <li>The filters are located next to the search inputs on the search results page.</li>
          <li>Apply the filters to view the most relevant contractors.</li>
        </ol>
        <p className="support-paragraph">Viewing Contractor Profiles</p>
        <ol className="support-list">
          <li>Click on a contractor's name in the search results to view their profile.</li>
          <li>Contractor profiles include their specialties, contact information, availability, reviews, and highlights.</li>
          <li>Use the messaging button to contact the contractor directly from their profile.</li>
        </ol>

        <div className="support-header">4. Scheduling and Availability</div>
        <p className="support-paragraph">Viewing Contractor Schedules</p>
        <ol className="support-list">
          <li>Visit a contractor's profile page.</li>
          <li>Click the icon that looks like a calendar.</li>
          <li>View the contractor's schedule to see when they are available for a consultation or work.</li>
        </ol>
        <p className="support-paragraph">Requesting Contractor Times</p>
        <ol className="support-list">
          <li>Click on a date that fits your schedule and find a time that is open.</li>
          <li>Click the "Request" button.</li>
          <li>Fill out the request form with details about the job.</li>
          <li>Submit the request to the contractor.</li>
          <li>Wait for the contractor to confirm the requested time.</li>
        </ol>

        <div className="support-header">5. Messaging Contractors</div>
        <p className="support-paragraph">Initiating a Conversation</p>
        <ol className="support-list">
          <li>Go to the contractor's profile page.</li>
          <li>Click the "Message" button.</li>
          <li>Type your message in the chat window.</li>
          <li>Click "Send" to initiate the conversation.</li>
        </ol>
        <p className="support-paragraph">Managing Conversations</p>
        <ol className="support-list">
          <li>Access your messages inbox from the dropdown header located at the top right of the screen when you click on your profile picture.</li>
          <li>Click on a conversation to open it.</li>
          <li>Continue the conversation by typing and sending messages.</li>
          <li>Delete conversations as needed.</li>
        </ol>
        <p className="support-paragraph">Blocking and Reporting Users</p>
        <ol className="support-list">
          <li>From the inbox, hover your mouse over the specific user, you should see three dots where the profile picture is located.</li>
          <li>Click the "More Options" button (three dots).</li>
          <li>Select "Block User" to prevent further messages or "Report Conversation" to report inappropriate behavior.</li>
        </ol>

        <div className="support-header">6. Invoicing</div>
        <p className="support-paragraph">Viewing and Paying Invoices</p>
        <ol className="support-list">
          <li>Contractors will send invoices through the platform.</li>
          <li>Go to the "Invoices" section in your account, located in the dropdown header located at the top right of the screen when you click on your profile picture.</li>
          <li>Click on an invoice from the invoices list to view details.</li>
          <li>Choose your preferred payment method and complete the payment.</li>
        </ol>
        <p className="support-paragraph">Payment History and Tracking</p>
        <ol className="support-list">
          <li>View the "Payment History" section in your account, populated by Total Transactions, Total Projects, Etc.</li>
          <li>View all payments made, total projects, total amount, and on-time payment percentage.</li>
        </ol>

        <div className="support-header">7. Payment Support</div>
        <p className="support-paragraph">Payment Processing Overview</p>
        <p className="support-paragraph">
            Our platform uses secure and efficient payment processing systems to facilitate transactions between users and contractors. We utilize Stripe, a leading payment processor, to handle all payment transactions. This ensures that your payment information is managed securely and efficiently.
        </p>

        <p className="support-paragraph">Fee Structure</p>
        <ul className="support-list">
            <li>All payments processed through our platform are subject to transaction fees, which include a processing fee of 2.9% of the transaction amount plus a fixed fee of $0.30 charged by Stripe.</li>
            <li>In addition, our platform may charge a service fee of 3% on the transaction amount. These fees will be clearly disclosed to users before processing any payment.</li>
        </ul>

        <p className="support-paragraph">How We Use Stripe</p>
        <p className="support-paragraph">
            Stripe is responsible for securely processing your payment information. When you enter your payment details on our platform, that information is sent directly to Stripe. We do not store or have access to your sensitive payment information, such as credit card numbers or banking details. This approach minimizes your risk and enhances security.
        </p>

        <p className="support-paragraph">Data Security and Privacy</p>
        <p className="support-paragraph">
            We take data security seriously. Our platform is designed to comply with industry standards and best practices to protect your information. We do not hold any sensitive payment information, ensuring that your financial data is only accessible to Stripe.
        </p>

        <p className="support-paragraph">Transaction Confirmation and Support</p>
        <p className="support-paragraph">
            After a payment is processed, you will receive a confirmation via email detailing the transaction. If you have any questions or concerns regarding a payment or transaction, please contact our support team as outlined below.
        </p>

        <p className="support-paragraph">Getting Help</p>
        <ol className="support-list">
            <li>If you encounter issues or have questions regarding payments, please reach out to our support team through the "Contact Us" section available on our platform.</li>
        </ol>

        <p className="support-paragraph">Contacting Support</p>
        <ol className="support-list">
            <li>For immediate assistance, contact our support team via email at support@spiggl.com.</li>
            <li>Provide relevant details, including your account information and a description of your issue, to help expedite the support process.</li>
        </ol>

        <div className="support-header">8. Reviews and Ratings</div>
        <p className="support-paragraph">Leaving a Review</p>
        <ol className="support-list">
          <li>After the invoice is paid off fully, you will receive a prompt to leave a review.</li>
          <li>Rate the contractor on Quality, Cost, Timeliness, and Overall experience using the five-star system.</li>
          <li>Write a title and a detailed review.</li>
          <li>Upload any pictures of the completed work.</li>
          <li>Submit the review to have it posted on both your profile and the contractor's profile.</li>
        </ol>
        <p className="support-paragraph">Rating Criteria</p>
        <ol className="support-list">
          <li><strong>Quality:</strong> Evaluate the quality of the work done.</li>
          <li><strong>Cost:</strong> Assess if the cost was reasonable and transparent.</li>
          <li><strong>Timeliness:</strong> Rate the punctuality and adherence to the timeline.</li>
          <li><strong>Overall:</strong> Give an overall rating based on your experience</li>
        </ol>

        <div className="support-header">9. Contractor Features</div>
        <p className="support-paragraph">Managing Availability</p>
        <ol className="support-list">
          <li>Go to your contractor profile.</li>
          <li>Click the icon that looks like a calendar.</li>
          <li>Add, edit, or delete available time slots by clicking on each individual day.</li>
          <li>Accept or decline incoming requests, these requests are automatically added to your schedule.</li>
          <li>If anything changes, you can always delete availability or cancel requests.</li>
          <li>Save changes to update your schedule.</li>
        </ol>
        <p className="support-paragraph">Creating Invoices</p>
        <ol className="support-list">
          <li>Go to the "Invoices" section in your account, located in the dropdown header located at the top right of the screen when you click on your profile picture.</li>
          <li>Click "Create Invoice."</li>
          <li>Fill out the invoice form with payment amount, payment frequency, and payment schedule.</li>
          <li>Attach any necessary documents.</li>
          <li>Send the invoice to the homeowner.</li>
        </ol>
        <p className="support-paragraph">Posting Highlights</p>
        <ol className="support-list">
          <li>Click "+ highlight" located on your profile.</li>
          <li>Upload pictures and write a description of the job.</li>
          <li>Save and post the highlight to your profile.</li>
        </ol>
        <p className="support-paragraph">Privacy</p>
        <ol className="support-list">
          <li>Hide email, phone number, or price range on your profile from users.</li>
          <li>Click the icon that looks like a headshot.</li>
          <li>On the side bar, click the "Privacy" button.</li>
          <li>Click on the toggle to hide contact information or pricing from users.</li>
        </ol>

        <div className="support-header">10. Data Privacy and Security</div>
        <p className="support-paragraph">Data Collection</p>
        <ul className="support-list">
          <li>We collect and store personal information such as names 
            and payment details to facilitate transactions and communication between homeowners and 
            contractors.</li>
        </ul>
        <p className="support-paragraph">Payment Security</p>
        <ul className="support-list">
          <li>All payments are processed through Stripe, ensuring secure and encrypted transactions.</li>
          <li>We do not store payment information on our servers.</li>
        </ul>
        
        <div className="support-header">11. Frequently Asked Questions (FAQ)</div>
        <p className="support-paragraph">How do I reset my password?</p>
        <ul className="support-list">
          <li>Click the log in button at the top right of the screen and click "Forgot Password." Follow the instructions to reset your password.</li>
        </ul>
        <p className="support-paragraph">How do I contact customer support?</p>
        <ul className="support-list">
          <li>Visit the "Contact Us" page and fill out the support form. Our team will get back to you within 24 hours.</li>
        </ul>
        <p className="support-paragraph">Can I edit or delete my review?</p>
        <ul className="support-list">
          <li>Yes, click the three dots in the top right cornder under the company name and click "Delete Post".</li>
        </ul>
        <p className="support-paragraph">How are contractors ranked in search results?</p>
        <ul className="support-list">
          <li>Contractors are ranked based on overall ratings and proximity to the user's location.</li>
        </ul>
     
        <div className="support-header">12. Contact Us</div>
        <p className="support-paragraph">If you have any questions or need further assistance, please visit our "Contact Us" 
          page and fill out the form. Our team is here to help you with any issues or inquiries you may have.</p>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </div>
     <Footer/> 
    </div>
  );
};

export default Support;