import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./SearchFunction.css";

const SearchFunction = ({ userType, userId, onSelectResult, styleClass }) => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [location, setLocation] = useState(null);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setLocation({
                lat: position.coords.latitude,
                lon: position.coords.longitude
            });
        }, (error) => {
            console.error("Error getting location:", error);
        });
    }, []);

    const handleSearch = async (e) => {
        setQuery(e.target.value);
        if (e.target.value.length > 1) {
            try {
                const requestBody = {
                    query: e.target.value,
                    userType,
                    userId,
                };
    
                if (location) {
                    requestBody.userLocation = `${location.lat},${location.lon}`;
                }
    
                const response = await axios.post(process.env.REACT_APP_SEARCH_API, requestBody);
                setResults(response.data);
            } catch (error) {
                console.error("Error making API call:", error);
            }
        } else {
            setResults([]);
        }
    };

    function createDefaultProfileImage(user) {
        const firstChar = user ? user.charAt(0).toUpperCase() : '';
        return `data:image/svg+xml;base64,${btoa(`<svg xmlns="http://www.w3.org/2000/svg" width="150" height="150">
          <circle cx="75" cy="75" r="75" fill="#23313d"/>
          <text x="50%" y="50%" font-family="Arial, sans-serif" font-size="64" dy=".3em" fill="#FFF" text-anchor="middle">${firstChar}</text>
        </svg>`)}`;
    }

    return (
        <div className={`search-function ${styleClass}`}>
            <input type="text" value={query} onChange={handleSearch} placeholder="Search user" />
            <ul>
                {results.map((result) => (
                    <li key={result.id} onClick={() => onSelectResult(result)}>
                        <img 
                            src={result.profilepicturekey ? `https://media.spiggl.com/public/${result.profilepicturekey}` : createDefaultProfileImage(result.name || result.companyName)} 
                            alt={`${result.name || result.companyName}`} 
                        />
                        <div>
                            <strong>{result.__typename === 'Company' ? result.companyName : result.name}</strong>
                            <p>{result.__typename === 'Company' ? result.companyUser : result.user}</p>
                        </div>
                        <span>{result.__typename}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SearchFunction;