import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { generateClient } from "aws-amplify/api";
import { uploadData } from "aws-amplify/storage";
import { updateCompany, deleteCompany, createSpecialty, updateSpecialty, deleteSpecialty } from '../../../graphQL/mutations';
import { getCompany, specialtiesByCompanyID } from "../../../graphQL/queries";
import { useAuth } from "../../../auth/auth";
import { Helmet } from 'react-helmet';
import UpdateCheckUsername from '../UpdateCheckUsername';
import DefaultBackgroundModal from '../../Syn/DefaultBackgroundModal/DefaultBackgroundModal';
import ImageCropModal from '../../Syn/ImageCrop/ImageCropModal';
import FormHeader from '../../Headers/FormHeader/FormHeader';
import Select from 'react-select';
import ChangePassword from '../ChangePassword/ChangePassword';
import PrivateInfo from '../PrivateInfo/PrivateInfo';
import CompanyNotifications from '../CompanyNotifications/CompanyNotifications';
import Blocked from '../Blocked/Blocked';
import CompanySavedReviews from '../../Company/CompanySavedReviews/CompanySavedReviews';
import './EditProfileCompany.css';
import ContractorTypes from '../../Syn/ContractorTypes';
import ContractorSpecialties from '../../Syn/ContractorSpecialties';
import backgrounds from '../../Syn/backgrounds';

const EditProfileCompany = () => {
  const navigate = useNavigate();
  const client = generateClient();
  const { userID, SignOut } = useAuth();

  const [profileData, setProfileData] = useState({
    profilePicture: { file: null, previewUrl: '' },
    backgroundSelection: { file: null, name: '', previewUrl: '' },
    profilepicturekey: null,
    backgroundpicturekey: null,
    companyUser: '',
    companyName: '',
    companyType: [],
    zipcode: '',
    state: '',
    town: '',
    email: '',
    phone: '',
    companyWebsite: '',
    bio: '',
    emailPrivate: false,
    phonePrivate: false,
    lowRange: '',
    highRange: '',
    lowPrivate: false,
    highPrivate: false,
  });

  const [isDefaultBackgroundModalOpen, setIsDefaultBackgroundModalOpen] = useState(false);
  const [imageToCrop, setImageToCrop] = useState(null);
  const [imageCropModalOpen, setImageCropModalOpen] = useState(false);
  const [croppingFor, setCroppingFor] = useState('');
  const [userError, setUserError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const [errors, setErrors] = useState({
    companyUser: '',
    email: '',
    profilePicture: '',
    backgroundPicture: '',
  });

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isFloatingMenuOpen, setIsFloatingMenuOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [deleteError, setDeleteError] = useState('');

  const [view, setView] = useState('profile');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [specialtyFields, setSpecialtyFields] = useState({});
  const [companyUserError, setCompanyUserError] = useState('');
  const [isUsernameValid, setIsUsernameValid] = useState(true);
  const [originalUsername, setOriginalUsername] = useState('');

  const toggleFloatingMenu = () => {
    setIsFloatingMenuOpen(!isFloatingMenuOpen);
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const { data } = await client.graphql({
          query: getCompany,
          variables: { id: userID },
        });

        const companyData = data.getCompany;
        setProfileData({
          profilePicture: { file: null, previewUrl: `https://media.spiggl.com/public/${companyData.profilepicturekey}` },
          backgroundSelection: { file: null, previewUrl: `https://media.spiggl.com/public/${companyData.backgroundpicturekey}` },
          profilepicturekey: companyData.profilepicturekey,
          backgroundpicturekey: companyData.backgroundpicturekey,
          companyUser: companyData.companyUser,
          companyName: companyData.companyName,
          companyType: companyData.companyType.map(type => ({ label: type, value: type })),
          zipcode: companyData.zipcode,
          state: companyData.state,
          town: companyData.town,
          email: companyData.email,
          phone: companyData.phone || '',
          companyWebsite: companyData.companyWebsite,
          bio: companyData.bio,
          emailPrivate: companyData.emailPrivate,
          phonePrivate: companyData.phonePrivate,
          lowRange: companyData.lowRange || '',
          highRange: companyData.highRange || '',
          lowPrivate: companyData.lowPrivate,
          highPrivate: companyData.highPrivate,
          messageNotifications: companyData.messageNotifications,
          paymentNotifications: companyData.paymentNotifications,
          requestNotifications: companyData.requestNotifications,
          pushMessageNotifications: companyData.pushMessageNotifications,
          pushPaymentNotifications: companyData.pushPaymentNotifications,
          pushRequestNotifications: companyData.pushRequestNotifications,
        });

        const { data: specialtyData } = await client.graphql({
          query: specialtiesByCompanyID,
          variables: { companyID: userID },
        });

        const specialties = {};
        specialtyData.specialtiesByCompanyID.items.forEach(item => {
          specialties[item.type] = item.specialties;
        });

        setOriginalUsername(companyData.companyUser);

        setSpecialtyFields(specialties);

      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    fetchProfileData();
  }, [userID]);

  const handleDefaultBackgroundModal = () => {
    setIsDefaultBackgroundModalOpen(!isDefaultBackgroundModalOpen);
  };

  const handleSelectBackground = async (backgroundName) => {
    const selectedBackground = backgrounds.find(bg => bg.name === backgroundName);
    const response = await fetch(selectedBackground.src);
    if (response.ok) {
      const blob = await response.blob();
      setProfileData((prevState) => ({
        ...prevState,
        backgroundSelection: { file: blob, name: selectedBackground.name, previewUrl: URL.createObjectURL(blob) }
      }));
      handleDefaultBackgroundModal();
    } else {
      console.error('Failed to fetch background image:', response.statusText);
    }
  };

  const handleProfilePictureChange = (event) => {
    if (event.target.files[0]) {
      setImageToCrop(URL.createObjectURL(event.target.files[0]));
      setCroppingFor('profilePicture');
      setImageCropModalOpen(true);
    }
  };

  const handleBackgroundPictureChange = (event) => {
    if (event.target.files[0]) {
      setImageToCrop(URL.createObjectURL(event.target.files[0]));
      setCroppingFor('backgroundPicture');
      setImageCropModalOpen(true);
    }
  };

  const handleCropComplete = (croppedBlob) => {
    if (croppingFor === 'profilePicture') {
      setProfileData((prevState) => ({
        ...prevState,
        profilePicture: {
          file: croppedBlob,
          previewUrl: URL.createObjectURL(croppedBlob),
        },
      }));
    } else if (croppingFor === 'backgroundPicture') {
      setProfileData((prevState) => ({
        ...prevState,
        backgroundSelection: {
          file: croppedBlob,
          previewUrl: URL.createObjectURL(croppedBlob),
        },
      }));
    }
  };

  const uploadToS3 = async (file, key, folder) => {
    const fullKey = `${folder}/${key}`;
    try {
      await uploadData({
        key: fullKey,
        data: file,
        options: {
          contentType: file.type,
          level: 'public',
        }
      });
      return fullKey;
    } catch (error) {
      console.error('Error uploading:', error);
      throw error;
    }
  };

  const handleUsernameCheck = (isAvailable, isValidFormat, newUsername) => {
    if (!isValidFormat) {
      setCompanyUserError('Invalid character');
      setIsUsernameValid(false);
    } else if (isAvailable === null) {
      setCompanyUserError('');
      setIsUsernameValid(false);
    } else if (!isAvailable && newUsername !== originalUsername) {
      setCompanyUserError('Username already exists');
      setIsUsernameValid(false);
    } else {
      setCompanyUserError('');
      setIsUsernameValid(true);
    }
  };

  const handleUsernameChange = (e) => {
    const newUsername = e.target.value.replace(/\s+/g, '');
    setProfileData((prevState) => ({ ...prevState, companyUser: newUsername }));
    setUserError('');
  };

  const handleCompanyNameChange = (e) => {
    const newCompanyName = e.target.value;
    if (newCompanyName.length <= 25) {
      setProfileData((prevState) => ({ ...prevState, companyName: newCompanyName }));
    }
  };

  const handlePhoneChange = (e) => {
    const digits = e.target.value.replace(/\D/g, '');

    let formattedValue = '';

    const parts = [
      digits.slice(0, 3),
      digits.slice(3, 6),
      digits.slice(6, 10)
    ];

    if (parts[0].length) {
      formattedValue += parts[0];
      if (parts[0].length === 3) {
        formattedValue += '-';
      }
    }
    if (parts[1].length) {
      formattedValue += parts[1];
      if (parts[1].length === 3) {
        formattedValue += '-';
      }
    }
    formattedValue += parts[2];

    setProfileData((prevState) => ({ ...prevState, phone: formattedValue }));

    if (formattedValue.length > 0 && formattedValue.length < 12) {
      setPhoneError('Invalid phone number');
    } else {
      setPhoneError('');
    }
  };

  const handleCompanyTypeChange = (selectedOptions) => {
    if (selectedOptions.length > 2) {
      return;
    }
    setProfileData((prevState) => ({ ...prevState, companyType: selectedOptions }));
    const newSpecialtyFields = { ...specialtyFields };
    selectedOptions.forEach(option => {
      if (!newSpecialtyFields[option.value]) {
        newSpecialtyFields[option.value] = [];
      }
    });
    setSpecialtyFields(newSpecialtyFields);
  };

  const handleSpecialtyChange = (type, selectedOptions) => {
    const newSpecialtyFields = { ...specialtyFields, [type]: selectedOptions.map(option => option.value) };
    setSpecialtyFields(newSpecialtyFields);
  };

  const handleBioChange = (e) => {
    const words = e.target.value.split(/\s+/);
    if (words.length <= 50) {
      setProfileData((prevState) => ({ ...prevState, bio: e.target.value }));
    }
  };

  const fetchTownAndStateFromZipcode = async (zipcode) => {
    if (!zipcode) return;

    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${zipcode}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === 'OK') {
        const result = data.results[0];
        const addressComponents = result.address_components;

        const townComponent = addressComponents.find(component =>
          component.types.includes('locality') || component.types.includes('sublocality')
        );
        const stateComponent = addressComponents.find(component =>
          component.types.includes('administrative_area_level_1')
        );

        const town = townComponent ? townComponent.long_name : '';
        const state = stateComponent ? stateComponent.short_name : '';

        setProfileData((prevState) => ({
          ...prevState,
          town: town,
          state: state,
        }));
      } else {
        console.error('Error fetching town and state:', data.status);
      }
    } catch (error) {
      console.error('Error fetching town and state:', error);
    }
  };

  const handleZipcodeChange = (e) => {
    const zipcode = e.target.value;
    setProfileData((prevState) => ({
      ...prevState,
      zipcode: zipcode,
      state: '',
      town: ''
    }));

    if (zipcode.length === 5) {
      fetchTownAndStateFromZipcode(zipcode);
    }
  };

  const handlePriceRangeChange = (e) => {
    const { name, value } = e.target;
    const cleanedValue = value.replace(/\D/g, ''); 

    setProfileData((prevState) => ({
      ...prevState,
      [name]: cleanedValue,
    }));
  };

  const handleWebsiteChange = (e) => {
    const value = e.target.value.replace(/^https?:\/\//, '');
    setProfileData((prevState) => ({
      ...prevState,
      companyWebsite: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let hasErrors = false;
    const newErrors = { ...errors };
    let updatedProfilePictureKey = profileData.profilepicturekey;
    let updatedBackgroundPictureKey = profileData.backgroundpicturekey;

    if (profileData.profilePicture.file) {
      try {
        updatedProfilePictureKey = await uploadToS3(profileData.profilePicture.file, `company_${profileData.companyUser}_profile_picture.jpg`, 'profile');
      } catch (error) {
        setErrors((prevState) => ({ ...prevState, profilePicture: 'Error uploading profile picture' }));
        hasErrors = true;
      }
    }

    if (profileData.backgroundSelection.file) {
      try {
        updatedBackgroundPictureKey = await uploadToS3(profileData.backgroundSelection.file, `company_${profileData.companyUser}_background_picture.jpg`, 'background');
      } catch (error) {
        setErrors((prevState) => ({ ...prevState, backgroundPicture: 'Error uploading background picture' }));
        hasErrors = true;
      }
    }

    const requiredFields = ['companyUser', 'companyType', 'zipcode', 'state', 'town', 'email', 'lowRange', 'highRange'];
    requiredFields.forEach(field => {
      if (!profileData[field] || (Array.isArray(profileData[field]) && profileData[field].length === 0)) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
        hasErrors = true;
      } else {
        newErrors[field] = '';
      }
    });

    if (!isUsernameValid) {
      newErrors.companyUser = 'Invalid username';
      hasErrors = true;
    }

    if (profileData.phone && profileData.phone.replace(/\D/g, '').length !== 10) {
      setPhoneError('Invalid phone number');
      hasErrors = true;
    } else {
      setPhoneError('');
    }

    if (profileData.zipcode.length !== 5 || !profileData.town || !profileData.state) {
      setErrors((prevState) => ({ ...prevState, zipcode: 'Invalid zipcode' }));
      hasErrors = true;
    } else {
      setErrors((prevState) => ({ ...prevState, zipcode: '' }));
    }

    if (!profileData.lowRange || !profileData.highRange) {
      setErrors((prevState) => ({ ...prevState, priceRange: 'Price range is required' }));
      hasErrors = true;
    } else {
      setErrors((prevState) => ({ ...prevState, priceRange: '' }));
    }

    setErrors(newErrors);

    if (hasErrors) return;

    const input = {
      id: userID,
      email: profileData.email,
      companyUser: profileData.companyUser,
      companyName: profileData.companyName,
      companyType: profileData.companyType.map(type => type.value),
      zipcode: profileData.zipcode,
      state: profileData.state,
      town: profileData.town,
      phone: profileData.phone || null,
      companyWebsite: profileData.companyWebsite,
      bio: profileData.bio,
      profilepicturekey: updatedProfilePictureKey,
      backgroundpicturekey: updatedBackgroundPictureKey,
      emailPrivate: profileData.emailPrivate,
      phonePrivate: profileData.phonePrivate,
      lowRange: parseInt(profileData.lowRange, 10),
      highRange: parseInt(profileData.highRange, 10),
      lowPrivate: profileData.lowPrivate,
      highPrivate: profileData.highPrivate,
    };

    try {
      const response = await client.graphql({
        query: updateCompany,
        variables: { input },
      });

      const updatedSpecialtyFields = { ...specialtyFields };

      const { data: currentSpecialtiesData } = await client.graphql({
        query: specialtiesByCompanyID,
        variables: { companyID: userID },
      });

      const currentSpecialties = currentSpecialtiesData.specialtiesByCompanyID.items;

      const typesToDelete = currentSpecialties.filter(cs => !profileData.companyType.find(type => type.value === cs.type));
      const typesToUpdate = profileData.companyType.filter(type => currentSpecialties.find(cs => cs.type === type.value));
      const typesToCreate = profileData.companyType.filter(type => !currentSpecialties.find(cs => cs.type === type.value));

      for (const typeToDelete of typesToDelete) {
        await client.graphql({
          query: deleteSpecialty,
          variables: {
            input: { id: typeToDelete.id }
          }
        });
      }

      for (const typeToUpdate of typesToUpdate) {
        const specialties = updatedSpecialtyFields[typeToUpdate.value] || [];
        const currentSpecialty = currentSpecialties.find(cs => cs.type === typeToUpdate.value);

        await client.graphql({
          query: updateSpecialty,
          variables: {
            input: {
              id: currentSpecialty.id,
              type: currentSpecialty.type,
              specialties: specialties,
              companyID: userID
            }
          }
        });
      }

      for (const typeToCreate of typesToCreate) {
        const specialties = updatedSpecialtyFields[typeToCreate.value] || [];

        await client.graphql({
          query: createSpecialty,
          variables: {
            input: {
              type: typeToCreate.value,
              specialties: specialties,
              companyID: userID
            }
          }
        });
      }

      console.log('Profile updated successfully:', response.data.updateCompany);
      navigate(`/contractor/${profileData.companyUser}`);
      navigate(0);
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const handleDeleteProfile = async () => {
    try {
      await SignOut();
      await client.graphql({
        query: deleteCompany,
        variables: { input: { id: userID } },
      });
      console.log('Profile deleted successfully');
      navigate('/');
    } catch (error) {
      console.error('Error deleting profile:', error);
      setDeleteError('Error deleting profile');
    }
  };

  const confirmDelete = async (event) => {
    event.preventDefault();
    try {
      await client.auth.signIn(profileData.email, password);
      await handleDeleteProfile();
      setDeleteModalOpen(false);
    } catch (error) {
      setDeleteError('Incorrect password');
      console.error('Error confirming password:', error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsFloatingMenuOpen(true);
      } else {
        setIsFloatingMenuOpen(false); 
      }
    };

    handleResize(); 
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderView = () => {
    switch (view) {
      case 'profile':
        return (
          <div className="edit-profile-container">
            <div className="edit-profile-header">Edit Profile</div>
            <form onSubmit={handleSubmit}>
              <div className="attach-profile-picture">
                <label htmlFor="profilePictureInput" className="file-input-profile-picture">
                  <span className="button-text">Choose Profile Picture</span>
                  <input
                    type="file"
                    id="profilePictureInput"
                    accept="image/*"
                    onChange={handleProfilePictureChange}
                    className="file-input"
                  />
                </label>
                {profileData.profilePicture.previewUrl ? (
                  <div>
                    <img src={profileData.profilePicture.previewUrl} alt="Profile Preview" className="profile-picture-preview" />
                    <div className="file-name-display">{profileData.profilePicture.file?.name || 'Current Picture'}</div>
                  </div>
                ) : (
                  <div className="default-profile-picture">
                    <span className="initial">{profileData.companyUser.charAt(0).toUpperCase()}</span>
                  </div>
                )}
              </div>
              <div className="background-picture-options">
                <button type="button" className="default-background-button" onClick={handleDefaultBackgroundModal}>
                  Choose Default Background
                </button>
                <span className="or-divider">or</span>
                <label htmlFor="backgroundPictureInput" className="file-input-background">
                  <span className="background-button">Choose Background Picture</span>
                  <input
                    type="file"
                    id="backgroundPictureInput"
                    accept="image/*"
                    onChange={handleBackgroundPictureChange}
                    className="file-input"
                  />
                </label>
                {isDefaultBackgroundModalOpen && (
                  <DefaultBackgroundModal
                    isOpen={isDefaultBackgroundModalOpen}
                    onClose={handleDefaultBackgroundModal}
                    onSelectBackground={handleSelectBackground}
                  />
                )}
              </div>
              {profileData.backgroundSelection.previewUrl && (
                <div>
                  <img src={profileData.backgroundSelection.previewUrl} alt="Background Preview" className="background-picture-preview" />
                  <div className="file-name-display">{profileData.backgroundSelection.name || 'Current Background'}</div>
                </div>
              )}
              <div className="username">
                <UpdateCheckUsername
                  userType="User"
                  onUsernameCheck={handleUsernameCheck}
                  value={profileData.companyUser}
                  onChange={handleUsernameChange}
                  initialValue={originalUsername}
                />
                {errors.companyUser && <div className="error-message">{errors.companyUser}</div>}
              </div>
              <div className="company-name">
                <input
                  type="text"
                  placeholder="Company Name"
                  value={profileData.companyName}
                  onChange={handleCompanyNameChange}
                  maxLength="25"
                />
              </div>
              <div className="company-type">
                <Select
                  isMulti
                  options={ContractorTypes}
                  value={profileData.companyType}
                  onChange={handleCompanyTypeChange}
                  className="react-select"
                  placeholder="*Company Type (2 max)"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: '5px',
                      border: '1px solid #ccc',
                      padding: '7px 0px',
                      bottom: '7px',
                      fontFamily: 'Poppins, sans-serif',
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      textAlign: 'left',
                    }),
                  }}
                />
              </div>
              {profileData.companyType.map(type => (
                <div key={type.value} className="specialty-dropdown">
                  <label className="specialty-label"></label>
                  <Select
                    isMulti
                    className='react-select'
                    options={ContractorSpecialties[type.value].map(spec => ({ label: spec, value: spec }))}
                    value={(specialtyFields[type.value] || []).map(spec => ({ label: spec, value: spec }))}
                    onChange={(selectedOptions) => handleSpecialtyChange(type.value, selectedOptions)}
                    placeholder={`*Select specialties for ${type.label}`}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                        padding: '7px 0px',
                        bottom: '7px',
                        fontFamily: 'Poppins, sans-serif',
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        textAlign: 'left',
                      }),
                    }}
                  />
                </div>
              ))}
              <div className="price-range-container">
                <div className="price-range-label">Price Range</div>
                <div className="price-range-inputs">
                  <input
                    type="text"
                    name="lowRange"
                    placeholder="Lower Range"
                    value={profileData.lowRange ? `$${profileData.lowRange}` : ''}
                    onFocus={(e) => e.target.value === '' ? e.target.value = '$' : e.target.value}
                    onBlur={(e) => e.target.value === '$' ? e.target.value = '' : e.target.value}
                    onChange={handlePriceRangeChange}
                    maxLength="6"
                    className="price-input"
                  />
                  <span className="price-range-separator">-</span>
                  <input
                    type="text"
                    name="highRange"
                    placeholder="Upper Range"
                    value={profileData.highRange ? `$${profileData.highRange}` : ''}
                    onFocus={(e) => e.target.value === '' ? e.target.value = '$' : e.target.value}
                    onBlur={(e) => e.target.value === '$' ? e.target.value = '' : e.target.value}
                    onChange={handlePriceRangeChange}
                    maxLength="7"
                    className="price-input"
                  />
                </div>
              </div>
              <div className="zipcode">
                <input
                  type="text"
                  placeholder="*Zipcode"
                  value={profileData.zipcode}
                  onChange={handleZipcodeChange}
                />
                {errors.zipcode && <div className="error-message">{errors.zipcode}</div>}
              </div>
              <div className="town">
                <input
                  type="text"
                  placeholder="Town"
                  value={profileData.town}
                  readOnly
                />
              </div>
              <div className="state">
                <input
                  type="text"
                  placeholder="State"
                  value={profileData.state}
                  readOnly
                />
              </div>
              <div className="email">
                <input
                  type="email"
                  placeholder="*Email"
                  value={profileData.email}
                  readOnly
                />
              </div>
              <div className="phone-number">
                <input
                  type="text"
                  placeholder="Phone Number"
                  value={profileData.phone}
                  onChange={handlePhoneChange}
                  maxLength="12"
                />
                {phoneError && <div className="error-message">{phoneError}</div>}
              </div>
              <div className="website-url">
                <input
                  type="text"
                  placeholder="Website URL"
                  value={profileData.companyWebsite ? `https://${profileData.companyWebsite}` : ''}
                  onFocus={(e) => e.target.value === '' ? e.target.value = 'https://' : e.target.value}
                  onBlur={(e) => e.target.value === 'https://' ? e.target.value = '' : e.target.value}
                  onChange={handleWebsiteChange}
                />
              </div>
              <div className="edit-bio">
                <textarea
                  placeholder="Anything to add?"
                  value={profileData.bio}
                  onChange={handleBioChange}
                  rows="4"
                  className="bio-textarea"
                />
                <div className="bio-word-count">
                  {profileData.bio.split(/\s+/).filter(word => word.length > 0).length}/50
                </div>
              </div>
              <div className="buttons-container">
                <button type="button" className="edit-profile-cancel-button" onClick={() => navigate(-1)}>
                  Cancel
                </button>
                <button type="submit" className="update-profile-button">
                  Update
                </button>
              </div>
            </form>
            <ImageCropModal
              isOpen={imageCropModalOpen}
              imageSrc={imageToCrop}
              onClose={() => setImageCropModalOpen(false)}
              onCropComplete={handleCropComplete}
              aspectRatio={croppingFor === 'profilePicture' ? 1 : 5 / 1}
            />
          </div>
        );
      case 'password':
        return <ChangePassword />;
      case 'privacy':
        return (
          <PrivateInfo 
            emailPrivate={profileData.emailPrivate} 
            setEmailPrivate={(value) => setProfileData(prevState => ({ ...prevState, emailPrivate: value }))} 
            phonePrivate={profileData.phonePrivate} 
            setPhonePrivate={(value) => setProfileData(prevState => ({ ...prevState, phonePrivate: value }))} 
            lowPrivate={profileData.lowPrivate} 
            setLowPrivate={(value) => setProfileData(prevState => ({ ...prevState, lowPrivate: value }))}
            highPrivate={profileData.highPrivate} 
            setHighPrivate={(value) => setProfileData(prevState => ({ ...prevState, highPrivate: value }))}
            companyId={userID}
          />
        );
      case 'notifications':
        return (
          <CompanyNotifications 
            messageNotifications={profileData.messageNotifications} 
            setMessageNotifications={(value) => setProfileData(prevState => ({ ...prevState, messageNotifications: value }))} 
            paymentNotifications={profileData.paymentNotifications} 
            setPaymentNotifications={(value) => setProfileData(prevState => ({ ...prevState, paymentNotifications: value }))} 
            requestNotifications={profileData.requestNotifications} 
            setRequestNotifications={(value) => setProfileData(prevState => ({ ...prevState, requestNotifications: value }))} 
            pushMessageNotifications={profileData.pushMessageNotifications} 
            setPushMessageNotifications={(value) => setProfileData(prevState => ({ ...prevState, pushMessageNotifications: value }))} 
            pushPaymentNotifications={profileData.pushPaymentNotifications} 
            setPushPaymentNotifications={(value) => setProfileData(prevState => ({ ...prevState, pushPaymentNotifications: value }))} 
            pushRequestNotifications={profileData.pushRequestNotifications} 
            setPushRequestNotifications={(value) => setProfileData(prevState => ({ ...prevState, pushRequestNotifications: value }))} 
            companyId={userID} 
          />
        );
      case 'blocked':
        return <Blocked />;
      case 'saved':
        return <CompanySavedReviews />;  
      default:
        return null;
    }
  };

  return (
    <div className="edit-profile">
      <FormHeader />
      <Helmet>
        <title>Edit Profile</title>
      </Helmet>
      <div className="edit-profile-layout">
        <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
          <button className={`sidebar-button ${view === 'profile' ? 'active' : ''}`} onClick={() => setView('profile')}>
            <img src="/icons/profile.png" alt="Profile" />
            Profile
          </button>
          <button className={`sidebar-button ${view === 'password' ? 'active' : ''}`} onClick={() => setView('password')}>
            <img src="/icons/password.png" alt="Password" />
            Password
          </button>
          <button className={`sidebar-button ${view === 'privacy' ? 'active' : ''}`} onClick={() => setView('privacy')}>
            <img src="/icons/privacy.png" alt="Privacy" />
            Privacy
          </button>
          <button className={`sidebar-button ${view === 'notifications' ? 'active' : ''}`} onClick={() => setView('notifications')}>
            <img src="/icons/notification.png" alt="notifications" />
            Notifications
          </button>
          <button className={`sidebar-button ${view === 'blocked' ? 'active' : ''}`} onClick={() => setView('blocked')}>
            <img src="/icons/block.png" alt="Blocked" />
            Blocked
          </button>
          <button className={`sidebar-button ${view === 'saved' ? 'active' : ''}`} onClick={() => setView('saved')}>
            <img src="/icons/saved.png" alt="Saved" />
            Saved
          </button>
          <button className="delete-sidebar-button" onClick={() => setDeleteModalOpen(true)}>
            <img src="/icons/delete.png" alt="Delete" />
            Delete Profile
          </button>
        </div>
        <div className="edit-profile-form">
          {renderView()}
        </div>
        {(window.innerWidth < 700 || isFloatingMenuOpen) && (
          <button className="floating-menu-button" onClick={toggleFloatingMenu}>
            ☰
          </button>
        )}
        {isFloatingMenuOpen && (
          <div className="floating-menu">
            <button className={`sidebar-button ${view === 'profile' ? 'active' : ''}`} onClick={() => setView('profile')}>
              <img src="/icons/profile.png" alt="Profile" />
              Profile
            </button>
            <button className={`sidebar-button ${view === 'password' ? 'active' : ''}`} onClick={() => setView('password')}>
              <img src="/icons/password.png" alt="Password" />
              Password
            </button>
            <button className={`sidebar-button ${view === 'privacy' ? 'active' : ''}`} onClick={() => setView('privacy')}>
              <img src="/icons/privacy.png" alt="Privacy" />
              Privacy
            </button>
            <button className={`sidebar-button ${view === 'notifications' ? 'active' : ''}`} onClick={() => setView('notifications')}>
              <img src="/icons/notification.png" alt="notifications" />
              Notifications
            </button>
            <button className={`sidebar-button ${view === 'blocked' ? 'active' : ''}`} onClick={() => setView('blocked')}>
              <img src="/icons/block.png" alt="Blocked" />
              Blocked
            </button>
            <button className={`sidebar-button ${view === 'saved' ? 'active' : ''}`} onClick={() => setView('saved')}>
              <img src="/icons/saved.png" alt="Saved" />
              Saved
            </button>
            <button className="delete-sidebar-button" onClick={() => setDeleteModalOpen(true)}>
              <img src="/icons/delete.png" alt="Delete" />
              Delete Profile
            </button>
          </div>
        )}
      </div>
      {deleteModalOpen && (
        <div className="delete-modal">
          <div className="delete-modal-content">
            <h2>Are you sure you want to delete your profile?</h2>
            <form onSubmit={confirmDelete}>
              <label htmlFor="password">Confirm Password</label>
              <input 
                type="password" 
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <div className="delete-modal-buttons">
                <button type="button" onClick={() => setDeleteModalOpen(false)}>Cancel</button>
                <button type="submit">Confirm</button>
              </div>
              {deleteError && <div className="error-message">{deleteError}</div>}
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditProfileCompany;
