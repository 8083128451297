import React from 'react';
import './DefaultBackgroundModal.css';
import architect from '../../../default-banners/architect.png';
import bench from '../../../default-banners/bench.png';
import bromine from '../../../default-banners/bromine.png';
import city from '../../../default-banners/city.png';
import electric from '../../../default-banners/electric.png';
import growth from '../../../default-banners/growth.png';
import interior from '../../../default-banners/interior.png';
import landscape from '../../../default-banners/landscape.png';
import lucid from '../../../default-banners/lucid.png';
import pool from '../../../default-banners/pool.png';
import rustic from '../../../default-banners/rustic.png';
import shalom from '../../../default-banners/shalom.png';
import solar from '../../../default-banners/solar.png';
import vent from '../../../default-banners/vent.png';
import workshop from '../../../default-banners/workshop.png';



const DefaultBackgroundModal = ({ isOpen, onClose, onSelectBackground }) => {
  if (!isOpen) return null;

  const backgrounds = [
    {
        id: 1,
        src: architect,
        alt: 'architect',
        name: 'architect.png',
        onClick: () => onSelectBackground('architect'),
    },
    {
        id: 2,
        src: bench,
        alt: 'bench',
        name: 'bench.png',
        onClick: () => onSelectBackground('bench'),
    },
    {
        id: 3,
        src: bromine,
        alt: 'bromine',
        name: 'bromine.png',
        onClick: () => onSelectBackground('bromine'),
    },
    {
        id: 4,
        src: city,
        alt: 'city',
        name: 'city.png',
        onClick: () => onSelectBackground('city'),
    },
    {
        id: 5,
        src: electric,
        alt: 'electric',
        name: 'electric.png',
        onClick: () => onSelectBackground('electric'),
    },
    {
        id: 6,
        src: growth,
        alt: 'growth',
        name: 'growth.png',
        onClick: () => onSelectBackground('growth'),
    },
    {
        id: 7,
        src: interior,
        alt: 'interior',
        name: 'interior.png',
        onClick: () => onSelectBackground('interior'),
    },
    {
        id: 8,
        src: landscape,
        alt: 'landscape',
        name: 'landscape.png',
        onClick: () => onSelectBackground('landscape'),
    },
    {
        id: 9,
        src: lucid,
        alt: 'lucid',
        name: 'lucid.png',
        onClick: () => onSelectBackground('lucid'),
    },
    {
        id: 10,
        src: pool,
        alt: 'pool',
        name: 'pool.png',
        onClick: () => onSelectBackground('pool'),
    },
    {
        id: 11,
        src: rustic,
        alt: 'rustic',
        name: 'rustic.png',
        onClick: () => onSelectBackground('rustic'),
    },
    {
        id: 12,
        src: shalom,
        alt: 'shalom',
        name: 'shalom.png',
        onClick: () => onSelectBackground('shalom'),
    },
    {
        id: 13,
        src: solar,
        alt: 'solar',
        name: 'solar.png',
        onClick: () => onSelectBackground('solar'),
    },
    {
        id: 14,
        src: vent,
        alt: 'vent',
        name: 'vent.png',
        onClick: () => onSelectBackground('vent'),
    },
    {
        id: 15,
        src: workshop,
        alt: 'workshop',
        name: 'workshop.png',
        onClick: () => onSelectBackground('workshop'),
    },
  ];

  return (
    <div className="default-modal-overlay" onClick={onClose}>
      <div className="default-modal-container" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>×</button>
        <div className="default-backgrounds">
          {backgrounds.map((background) => (
            <div className="default-background-option" key={background.id}>
              <img 
                src={background.src} 
                alt={background.alt} 
                onClick={() => background.onClick(background.name)}
              />
              <div className="background-name">{background.alt}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DefaultBackgroundModal;