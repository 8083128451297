import React from 'react';
import './DeleteConfirmationModal.css'; 

const DeleteConfirmationModal = ({ onClose, onConfirm }) => (
  <div className="delete-review-modal-overlay">
    <div className="delete-review-modal-content">
      <h2 className="delete-review-modal-sign-in-head">Are you sure you want to delete this post?</h2>
      <div className="delete-review-modal-actions">
        <button className="delete-review-yes-button" onClick={onConfirm}>
          Yes
        </button>
        <button className="delete-review-no-button" onClick={onClose}>
          No
        </button>
      </div>
    </div>
  </div>
);

export default DeleteConfirmationModal;
