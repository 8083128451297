export const processPayment = async ({ payment_method, amount }) => {
    const API_ENDPOINT = process.env.REACT_APP_CREATE_PAYMENT_INTENT_API_ENDPOINT;
  
    try {
      const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          payment_method,
          amount,
          currency: 'usd',
          return_url: 'https://spiggl.com'
        }),
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        throw new Error(data.message || 'Payment failed.');
      }
  
      return data;
    } catch (error) {
      console.error('Error in processPayment:', error);
      throw error;
    }
  };
  