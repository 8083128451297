import React from 'react';
import './PrivateInfo.css';
import { generateClient } from "aws-amplify/api";
import { updateCompany } from '../../../graphQL/mutations';

const PrivateInfo = ({ emailPrivate, setEmailPrivate, phonePrivate, setPhonePrivate, lowPrivate, setLowPrivate, highPrivate, setHighPrivate, companyId }) => {
  const client = generateClient();

  const handleEmailPrivateChange = async () => {
    const newEmailPrivate = !emailPrivate;
    setEmailPrivate(newEmailPrivate);
    try {
      await client.graphql({
        query: updateCompany,
        variables: {
          input: {
            id: companyId,
            emailPrivate: newEmailPrivate
          }
        }
      });
    } catch (error) {
      console.error('Error updating email privacy:', error);
    }
  };

  const handlePhonePrivateChange = async () => {
    const newPhonePrivate = !phonePrivate;
    setPhonePrivate(newPhonePrivate);
    try {
      await client.graphql({
        query: updateCompany,
        variables: {
          input: {
            id: companyId,
            phonePrivate: newPhonePrivate
          }
        }
      });
    } catch (error) {
      console.error('Error updating phone privacy:', error);
    }
  };

  const handleLowPrivateChange = async () => {
    const newLowPrivate = !lowPrivate;
    setLowPrivate(newLowPrivate);
    try {
      await client.graphql({
        query: updateCompany,
        variables: {
          input: {
            id: companyId,
            lowPrivate: newLowPrivate
          }
        }
      });
    } catch (error) {
      console.error('Error updating low range privacy:', error);
    }
  };

  const handleHighPrivateChange = async () => {
    const newHighPrivate = !highPrivate;
    setHighPrivate(newHighPrivate);
    try {
      await client.graphql({
        query: updateCompany,
        variables: {
          input: {
            id: companyId,
            highPrivate: newHighPrivate
          }
        }
      });
    } catch (error) {
      console.error('Error updating high range privacy:', error);
    }
  };

  return (
    <div className="privacy-container">
      <div className="privacy-option">
        <label>Hide Email</label>
        <label className="switch">
          <input type="checkbox" checked={emailPrivate} onChange={handleEmailPrivateChange} />
          <span className="slider"></span>
        </label>
      </div>
      <div className="privacy-option">
        <label>Hide Phone</label>
        <label className="switch">
          <input type="checkbox" checked={phonePrivate} onChange={handlePhonePrivateChange} />
          <span className="slider"></span>
        </label>
      </div>
      <div className="privacy-option">
        <label>Hide Price Range</label>
        <label className="switch">
          <input type="checkbox" checked={lowPrivate} onChange={handleLowPrivateChange} />
          <span className="slider"></span>
        </label>
      </div>
      <div className="privacy-option">
        <label>Hide Upper Range</label>
        <label className="switch">
          <input type="checkbox" checked={highPrivate} onChange={handleHighPrivateChange} />
          <span className="slider"></span>
        </label>
      </div>
    </div>
  );
};

export default PrivateInfo;
