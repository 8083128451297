import React from 'react';
import './PaymentMessageModal.css';

const PaymentMessageModal = ({ isOpen, onClose, isSuccess, message, amount, contractorName }) => (
  isOpen ? (
    <div className="payment-message-modal-overlay">
      <div className="payment-message-modal-content">
        <h2 className="payment-message-modal-header">
          {isSuccess ? "Payment Confirmed ✔" : "Payment Failed"}
        </h2>
        <p className="payment-message-modal-message">
          {isSuccess 
            ? `Payment to ${contractorName} for $${amount} has successfully been processed.` 
            : message}
        </p>
        <button className="payment-message-modal-dismiss-button" onClick={onClose}>
          Dismiss
        </button>
      </div>
    </div>
  ) : null
);

export default PaymentMessageModal;
