import React, { useState, useEffect } from "react";
import './ExternalUserProfile.css';
import { generateClient } from "aws-amplify/api";
import { useParams } from 'react-router-dom';
import { useAuth } from "../../../auth/auth";
import { Helmet } from 'react-helmet';
import * as queries from "../../../graphQL/queries";
import ProfileHeader from '../../Headers/ProfileHeader/ProfileHeader';
import Footer from '../../Footer/Footer';
import ExternalUserReviews from '../ExternalUserReviews/ExternalUserReviews';
import MessagesModal from "../../Syn/Messages/MessagesModal";
import SignInModal from "../../../auth/SignInModal/SignInModal";

const ExternalUserProfile = () => {
  const { user: username } = useParams();
  const client = generateClient();
  const { userID, user } = useAuth();
  const [profileData, setProfileData] = useState({});
  const [background, setBackground] = useState("/icons/default-background.jpg");
  const [profilePicture, setProfilePicture] = useState("");
  const [showMessagesModal, setShowMessagesModal] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [userNotFound, setUserNotFound] = useState(false);
  const [conversationID, setConversationID] = useState(null);

  const handleMessagesClick = async () => {
    if (!user) {
      setShowSignInModal(true);
    } else {
      const existingConversationID = await fetchConversationWithUser();
      if (existingConversationID) {
        setConversationID(existingConversationID);
      } else {
        const newConversationID = `${userID}-${profileData.id}`;
        setConversationID(newConversationID);
      }
      setShowMessagesModal(true);
    }
  };

  const fetchConversationWithUser = async () => {
    try {
      const { data } = await client.graphql({
        query: queries.listMessages,
        variables: {
          filter: {
            or: [
              { senderID: { eq: userID }, receiverID: { eq: profileData.id } },
              { senderID: { eq: profileData.id }, receiverID: { eq: userID } },
            ],
          },
        },
      });
      const conversation = data.listMessages.items.find(
        (msg) =>
          (msg.senderID === userID && msg.receiverID === profileData.id) ||
          (msg.senderID === profileData.id && msg.receiverID === userID)
      );
      return conversation ? conversation.conversationID : null;
    } catch (error) {
      console.error("Error fetching conversation:", error);
      return null;
    }
  };

  const toggleMessagesModal = () => {
    setShowMessagesModal(!showMessagesModal);
  };

  const toggleSignInModal = () => {
    setShowSignInModal(!showSignInModal);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (username) {
        try {
          const { data } = await client.graphql({
            query: queries.listUsers,
            variables: { filter: { user: { eq: username } } },
          });

          if (data.listUsers.items.length > 0) {
            const userData = data.listUsers.items[0];

            const userDataWithImageUrls = {
              ...userData,
              backgroundpicturekey: userData.backgroundpicturekey,
              profilepicturekey: userData.profilepicturekey || null,
            };
            setProfileData(userDataWithImageUrls);

            if (userData.backgroundpicturekey) {
              setBackground(
                `https://media.spiggl.com/public/${userData.backgroundpicturekey}`
              );
            } else {
              setBackground("/icons/default-background.jpg");
            }

            if (userData.profilepicturekey) {
              setProfilePicture(
                `https://media.spiggl.com/public/${userData.profilepicturekey}`
              );
            } else {
              setProfilePicture(createDefaultProfileImage(userData.user));
            }
          } else {
            setUserNotFound(true);
          }
        } catch (error) {
          setUserNotFound(true);
        }
      } else {
        setUserNotFound(true);
      }
    };

    fetchUserData();
  }, [username, client]);

  function createDefaultProfileImage(user) {
    const firstChar = user ? user.charAt(0).toUpperCase() : '';
    return `data:image/svg+xml;base64,${btoa(`<svg xmlns="http://www.w3.org/2000/svg" width="150" height="150">
      <circle cx="75" cy="75" r="75" fill="#23313d"/>
      <text x="50%" y="50%" font-family="Arial, sans-serif" font-size="64" dy=".3em" fill="#FFF" text-anchor="middle">${firstChar}</text>
    </svg>`)}`;
  }

  return (
    <div className="user-profile">
      <ProfileHeader />
      <Helmet>
        <title>{profileData.user}</title>
      </Helmet>
        {userNotFound ? (
          <div className="user-not-found">
            <h2>User does not exist</h2>
          </div>
        ) : (
          <>
            <div className="ei-user">
              <div className="background-picture">
                <img src={background} alt="User Background" />
                <div className="profile-picture">
                  <img src={profilePicture || createDefaultProfileImage(profileData.user)} alt="User Profile" />
                </div>
              </div>
              <div className="ex-user-white-background-container">
                <div className="user-info">
                  <div className="user-info-container">
                    <div className="profile-user-name">{profileData.user}</div>
                    <div className="profile-user-real-name">{profileData.name || <span>&nbsp;</span>}</div>
                  </div>
                  <button className="message-button" onClick={handleMessagesClick}>Message</button>
                </div>
              </div>
            </div>
            {!userNotFound && <ExternalUserReviews userID={profileData.id} />}
            {showMessagesModal && (
              <MessagesModal 
                userID={userID} 
                receiverID={profileData.id} 
                userType={user.userType} 
                conversationID={conversationID}
                onClose={toggleMessagesModal} 
              />
            )}
            {showSignInModal && <SignInModal onClose={toggleSignInModal} />}
          </>
        )}
      <Footer />
    </div>
  );
};

export default ExternalUserProfile;
