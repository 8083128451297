import React, { useState, useEffect } from "react";
import './ExternalCompanyProfile.css';
import { generateClient } from "aws-amplify/api";
import { useNavigate } from 'react-router-dom';
import * as queries from "../../../graphQL/queries";
import { useAuth } from "../../../auth/auth";
import { Helmet } from 'react-helmet';
import ProfileHeader from '../../Headers/ProfileHeader/ProfileHeader';
import Footer from '../../Footer/Footer';
import CompanyReviews from "../CompanyReviews/CompanyReviews";
import ExternalCompanyHighlights from "../Highlights/ExternalCompanyHighlights/ExternalCompanyHighlights";
import MessagesModal from "../../Syn/Messages/MessagesModal";
import SignInModal from "../../../auth/SignInModal/SignInModal";

const ExternalCompanyProfile = ({ companyName }) => {
  const client = generateClient();
  const { userID, user } = useAuth();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Reviews");
  const [companyData, setCompanyData] = useState({});
  const [background, setBackground] = useState("/icons/default-background.jpg");
  const [profilePicture, setProfilePicture] = useState("");
  const [specialties, setSpecialties] = useState([]);
  const [showMessagesModal, setShowMessagesModal] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [companyNotFound, setCompanyNotFound] = useState(false);
  const [conversationID, setConversationID] = useState(null);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleMessagesClick = async () => {
    if (!user) {
      setShowSignInModal(true);
    } else {
      const existingConversationID = await fetchConversationWithCompany();
      if (existingConversationID) {
        setConversationID(existingConversationID);
      } else {
        const newConversationID = `${userID}-${companyData.id}`;
        setConversationID(newConversationID);
      }
      setShowMessagesModal(true);
    }
  };

  const fetchConversationWithCompany = async () => {
    try {
      const { data } = await client.graphql({
        query: queries.listMessages,
        variables: {
          filter: {
            or: [
              { senderID: { eq: userID }, receiverID: { eq: companyData.id } },
              { senderID: { eq: companyData.id }, receiverID: { eq: userID } },
            ],
          },
        },
      });
      const conversation = data.listMessages.items.find(
        (msg) =>
          (msg.senderID === userID && msg.receiverID === companyData.id) ||
          (msg.senderID === companyData.id && msg.receiverID === userID)
      );
      return conversation ? conversation.conversationID : null;
    } catch (error) {
      console.error("Error fetching conversation:", error);
      return null;
    }
  };

  const toggleMessagesModal = () => {
    setShowMessagesModal(!showMessagesModal);
  };

  const toggleSignInModal = () => {
    setShowSignInModal(!showSignInModal);
  };

  const handleCalendarClick = () => {
    navigate(`/contractor/${companyName}/calendar`);
  };

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const { data } = await client.graphql({
          query: queries.listCompanies,
          variables: { filter: { companyUser: { eq: companyName } } },
        });

        if (data.listCompanies.items.length > 0) {
          const company = data.listCompanies.items[0];
          setCompanyData(company);

          if (company.backgroundpicturekey) {
            setBackground(`https://media.spiggl.com/public/${company.backgroundpicturekey}`);
          }

          if (company.profilepicturekey) {
            setProfilePicture(`https://media.spiggl.com/public/${company.profilepicturekey}`);
          } else {
            setProfilePicture(createDefaultProfileImage(company.companyUser));
          }
        } else {
          setCompanyNotFound(true);
        }
      } catch (error) {
        setCompanyNotFound(true);
      }
    };

    fetchCompanyData();
  }, [companyName, client]);

  useEffect(() => {
    const fetchSpecialtiesData = async () => {
      if (companyData.id) {
        try {
          const { data } = await client.graphql({
            query: queries.specialtiesByCompanyID,
            variables: { companyID: companyData.id },
          });
          setSpecialties(data.specialtiesByCompanyID.items);
        } catch (error) {
          console.error("Error fetching specialties:", error);
        }
      }
    };

    fetchSpecialtiesData();
  }, [companyData.id, client]);

  function createDefaultProfileImage(username) {
    const firstChar = username ? username.charAt(0).toUpperCase() : '';
    return `data:image/svg+xml;base64,${btoa(
      `<svg xmlns="http://www.w3.org/2000/svg" width="150" height="150">
        <circle cx="75" cy="75" r="75" fill="#23313d"/>
        <text x="50%" y="50%" font-family="Arial, sans-serif" font-size="64" dy=".3em" fill="#FFF" text-anchor="middle">${firstChar}</text>
      </svg>`
    )}`;
  }

  const renderSpecialties = () => {
    if (specialties.length === 0) {
      return null;
    }

    const contractorTypes = [...new Set(specialties.map(s => s.type))];

    const columns = contractorTypes.map((type, index) => (
      <div className="specialty-column" key={type}>
        <div className="specialty-header">
          <img src={`/icons/contractor/${type.toLowerCase().replace(/\s+/g, '-')}.png`} alt={`${type} Icon`} />
          <strong>{type}</strong>
        </div>
        <ul>
          {specialties.filter(s => s.type === type).flatMap(s => s.specialties).map((spec, index) => (
            <li key={index}>{spec}</li>
          ))}
        </ul>
      </div>
    ));

    return (
      <div className="specialties-columns">
        {columns}
      </div>
    );
  };

  const renderPriceRange = () => {
    const { lowRange, highRange, lowPrivate, highPrivate } = companyData;

    if (lowPrivate && highPrivate) {
      return null;
    }

    if (!lowPrivate && highPrivate) {
      return (
        <div className="price-range-info">
          <div className="info-header">
            <img src="/icons/money.png" alt="Price Icon" /> Starting at
          </div>
          <div className="text-container">
            ${lowRange}
          </div>
        </div>
      );
    }

    return (
      <div className="price-range-info">
        <div className="info-header">
          <img src="/icons/money.png" alt="Price Icon" /> Price Range
        </div>
        <div className="text-container">
          ${lowRange} - ${highRange}
        </div>
      </div>
    );
  };

  const renderContactInfo = () => {
    const showEmail = !companyData.emailPrivate && companyData.email;
    const showPhone = !companyData.phonePrivate && companyData.phone;

    if (!showEmail && !showPhone) {
      return null;
    }

    return (
      <div className="contact-info">
        <div className="info-header">
          <img src="/icons/contact.png" alt="Contact Icon" /> Contact
        </div>
        {showEmail && (
          <div className={`text-container ${companyData.email?.length > 25 ? 'small' : ''}`}>
            {companyData.email}
          </div>
        )}
        {showPhone && (
          <div className={`text-container ${companyData.phone?.length > 25 ? 'small' : ''}`}>
            {companyData.phone}
          </div>
        )}
      </div>
    );
  };

  const renderLocationInfo = () => {
    return (
      <div className="location-info">
        <div className="info-header">
          <img src="/icons/location.png" alt="Location Icon" /> Located
        </div>
        {companyData.town && companyData.state && (
          <div className="text-container">
            {companyData.town}, {companyData.state}
          </div>
        )}
      </div>
    );
  };

  const renderWebsiteInfo = () => {
    if (!companyData.companyWebsite) {
      return null;
    }

    const formattedWebsite = companyData.companyWebsite.startsWith("http://") || companyData.companyWebsite.startsWith("https://")
      ? companyData.companyWebsite
      : `http://${companyData.companyWebsite}`;

    return (
      <div className="website-info">
        <div className="info-header">
          <img src="/icons/www.png" alt="Website Icon" /> Website
        </div>
        <div className="text-container">
          <a href={formattedWebsite} target="_blank" rel="noopener noreferrer" className="visit-website-link">
            Visit Website <img src="/icons/launch.png" alt="Launch Icon" className="launch-icon" />
          </a>
        </div>
      </div>
    );
  };

  return (
    <div className="company-profile">
      <ProfileHeader />
      <Helmet>
        <title>{companyData.companyUser}</title>
      </Helmet>
        <div className="ei-company">
          {companyNotFound ? (
            <div className="company-not-found">
              <h2>Company does not exist</h2>
            </div>
          ) : (
            <>
              <div className="background-picture-company">
                <img src={background} alt="Company Background" />
                <div className="profile-picture-company">
                  <img src={profilePicture || createDefaultProfileImage(companyData.companyUser)} alt="Company Profile" />
                </div>
              </div>
              <div className="company-white-background-container">
                <div className="company-menu">
                  <div className="company-name-container">
                    <div className={`company-profile-username ${companyData.companyUser?.length > 25 ? 'small' : ''}`}>{companyData.companyUser}</div>
                    <div className={`company-profile_name ${companyData.companyName?.length > 25 ? 'small' : ''}`}>{companyData.companyName || <span>&nbsp;</span>}</div>
                  </div>
                  <div className="external-company-buttons">
                    <div className="external-calendar">
                      <img src="/icons/schedule.png" alt="Schedule"  onClick={handleCalendarClick} />
                    </div>
                    <button className="company-message-button" onClick={handleMessagesClick}>Message</button>
                  </div>
                </div>
                <div className="company-info">
                  <div className="company-info-container">
                    <div className="specialties-container">
                      {renderSpecialties()}
                    </div>
                    <div className="contact-column">
                      {renderPriceRange()}
                      {renderContactInfo()}
                      {renderLocationInfo()}
                      {renderWebsiteInfo()}
                    </div>
                  </div>
                  <div className="company-bio">{companyData.bio || ''}</div>
                </div>
              </div>
              <div className="company-review-tabs">
                <button className={activeTab === "Reviews" ? "active" : ""} onClick={() => handleTabClick("Reviews")}> Reviews </button>
                <button className={activeTab === "Highlights" ? "active" : ""} onClick={() => handleTabClick("Highlights")}> Highlights </button>
              </div>
            </>
          )}
        </div>
        <div className="reviews-highlights">
          {activeTab === "Reviews" && !companyNotFound && <CompanyReviews companyID={companyData.id} />}
          {activeTab === "Highlights" && !companyNotFound && <ExternalCompanyHighlights companyID={companyData.id} />}
        </div>
        {showMessagesModal && (
          <MessagesModal 
            userID={userID} 
            receiverID={companyData.id}
            userType={user.userType} 
            conversationID={conversationID}
            onClose={toggleMessagesModal} 
          />
        )}
        {showSignInModal && <SignInModal onClose={toggleSignInModal} />}
      <Footer />
    </div>
  );
};

export default ExternalCompanyProfile;
