import React, { useEffect, useState } from 'react';
import { generateClient } from 'aws-amplify/api';
import { useAuth } from "../../../../auth/auth";
import * as queries from '../../../../graphQL/queries';
import './PastInvoices.css';

const PastInvoices = () => {
  const [history, setHistory] = useState({
    transactions: 0,
    projects: 0,
    amount: 0,
    latePayments: 0,
    onTimePercentage: 0,
    averageDelay: 'N/A'
  });
  const client = generateClient();
  const { userID } = useAuth();

  const platformFeePercent = 0.03;

  useEffect(() => {
    const fetchPastInvoices = async () => {
      try {
        const pastInvoicesData = await client.graphql({
          query: queries.invoicesByCompanyID,
          variables: { companyID: userID, filter: { status: { eq: "Complete" } } }
        });

        if (pastInvoicesData.data.invoicesByCompanyID && pastInvoicesData.data.invoicesByCompanyID.items.length > 0) {
          const completedInvoices = pastInvoicesData.data.invoicesByCompanyID.items;

          let totalProjects = completedInvoices.length;
          let totalTransactions = 0;
          let totalAmount = 0;
          let totalLatePayments = 0;
          let totalOnTimePayments = 0;
          let lateDaysSum = 0;
          let lateDaysCount = 0;

          completedInvoices.forEach(invoice => {
            const numPayments = invoice.latePayments?.length || 0;
            totalTransactions += numPayments;

            const grossAmount = parseFloat(invoice.amount) || 0;
            const platformFee = grossAmount * platformFeePercent;
            const netAmountAfterPlatformFee = grossAmount - platformFee;
            const netAmount = netAmountAfterPlatformFee;

            totalAmount += netAmount;

            if (invoice.latePayments && Array.isArray(invoice.latePayments)) {
              invoice.latePayments.forEach(payment => {
                const lateDays = parseInt(payment, 10);
                if (lateDays > 0) {
                  totalLatePayments++;
                  lateDaysSum += lateDays;
                  lateDaysCount++;
                } else {
                  totalOnTimePayments++;
                }
              });
            }
          });

          const onTimePercentage = totalTransactions > 0 ? (totalOnTimePayments / totalTransactions) * 100 : 0;
          const averageDelay = lateDaysCount > 0 ? (lateDaysSum / lateDaysCount).toFixed(2) : 'N/A';

          setHistory({
            transactions: totalTransactions,
            projects: totalProjects,
            amount: totalAmount,
            latePayments: totalLatePayments,
            onTimePercentage: onTimePercentage.toFixed(2),
            averageDelay
          });
        } else {
          setHistory({
            transactions: 0,
            projects: 0,
            amount: 0,
            latePayments: 0,
            onTimePercentage: 0,
            averageDelay: 'N/A'
          });
        }
      } catch (error) {
        console.error("Error fetching past invoices:", error);
      }
    };

    if (userID) {
      fetchPastInvoices();
    }
  }, [userID, client]);

  const formatAmount = (amount) => {
    return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  return (
    <div className="past-invoices">
      {history.projects > 0 ? (
        <>
          <div className="invoice-history-item">
            <div className="invoice-history-label">Total Amount:</div>
            <div className="invoice-history-value">{formatAmount(history.amount)}</div>
          </div>
          <div className="invoice-history-item">
            <div className="invoice-history-label">Total Projects:</div>
            <div className="invoice-history-value">{history.projects}</div>
          </div>
          <div className="invoice-history-item">
            <div className="invoice-history-label">Total Transactions:</div>
            <div className="invoice-history-value">{history.transactions}</div>
          </div>
          <div className="invoice-history-item">
            <div className="invoice-history-label">Late Payments:</div>
            <div className="invoice-history-value">{history.latePayments}</div>
          </div>
          <div className="invoice-history-item">
            <div className="invoice-history-label">On-time Payments:</div>
            <div className="invoice-history-value">{history.onTimePercentage}%</div>
          </div>
          <div className="invoice-history-item">
            <div className="invoice-history-label">Average Payment Delay:</div>
            <div className="invoice-history-value">{history.averageDelay} days</div>
          </div>
        </>
      ) : (
        <div className="invoice-history-no-history">No past invoices available</div>
      )}
    </div>
  );
};

export default PastInvoices;
