import React, { useEffect, useState } from 'react';
import { generateClient } from 'aws-amplify/api';
import { useAuth } from "../../../../auth/auth";
import { useNavigate } from 'react-router-dom';
import * as queries from '../../../../graphQL/queries';
import * as mutations from '../../../../graphQL/mutations';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './ProfileInvoice.css';

const ProfileInvoice = () => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [methodToDelete, setMethodToDelete] = useState(null);
  const { userID } = useAuth();
  const [userData, setUserData] = useState({});
  const [profilePicture, setProfilePicture] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const client = generateClient();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await client.graphql({
          query: queries.getUser,
          variables: { id: userID },
        });

        if (data.getUser) {
          setUserData(data.getUser);
          setProfilePicture(
            data.getUser.profilepicturekey
              ? `https://media.spiggl.com/public/${data.getUser.profilepicturekey}`
              : createDefaultProfileImage(data.getUser.user)
          );
        } else {
          console.error('User not found with id:', userID);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const fetchPaymentMethods = async () => {
      try {
        const { data: cardData } = await client.graphql({
          query: queries.paymentMethodsByUserID,
          variables: { userID: userID }
        });

        const paymentMethods = cardData.paymentMethodsByUserID.items.map(card => {
          const expDate = `${card.expMonth}/${card.expYear}`;
          const expiryDate = new Date(`${card.expYear}-${card.expMonth}-01`);
          const isExpired = expiryDate < new Date();

          return {
            id: card.id,
            brand: card.brand,         
            last4: card.last4,          
            expDate,  
            isExpired,
            isActive: card.isActive
          };
        });

        setPaymentMethods(paymentMethods);
        const activeMethod = paymentMethods.find(method => method.isActive);
        setSelectedMethod(activeMethod);
      } catch (error) {
        console.error('Error fetching payment methods:', error);
      }
    };

    if (userID) {
      fetchUserData();
      fetchPaymentMethods();
      setLoading(false); 
    } else {
      console.error('No user ID provided.');
    }
  }, [userID, client]);

  const handleAddPaymentClick = () => {
    navigate(`/user/${userData.user}/invoice-dash/card`);
  };

  const handleSelectMethod = async (method) => {
    if (!method.isExpired) {
      try {
        await client.graphql({
          query: mutations.updatePaymentMethod,
          variables: { input: { id: method.id, isActive: true } }
        });

        if (selectedMethod && selectedMethod.id !== method.id) {
          await client.graphql({
            query: mutations.updatePaymentMethod,
            variables: { input: { id: selectedMethod.id, isActive: false } }
          });
        }

        setPaymentMethods((prevMethods) =>
          prevMethods.map((m) => ({
            ...m,
            isActive: m.id === method.id
          }))
        );
        setSelectedMethod(method);
      } catch (error) {
        console.error('Error updating payment method:', error);
      }
    }
  };

  const handleDeleteMethod = async () => {
    try {
      await client.graphql({
        query: mutations.deletePaymentMethod,
        variables: { input: { id: methodToDelete.id } }
      });
      setPaymentMethods((prev) => prev.filter((method) => method.id !== methodToDelete.id));
      if (selectedMethod && selectedMethod.id === methodToDelete.id) {
        setSelectedMethod(null);
      }
      setShowDeleteModal(false);
      setMethodToDelete(null);
    } catch (error) {
      console.error('Error deleting payment method:', error);
    }
  };

  const createDefaultProfileImage = (user) => {
    const firstChar = user ? user.charAt(0).toUpperCase() : '';
    return `data:image/svg+xml;base64,${btoa(`<svg xmlns="http://www.w3.org/2000/svg" width="150" height="150">
      <circle cx="75" cy="75" r="75" fill="#23313d"/>
      <text x="50%" y="50%" font-family="Arial, sans-serif" font-size="64" dy=".3em" fill="#FFF" text-anchor="middle">${firstChar}</text>
    </svg>`)}`;
  };

  return (
    <div className="profile-invoice">
      <div className="profile-invoice-header">
        <div className="user-invoice-profile">
          {loading ? (
            <Skeleton circle={true} height={150} width={150} />
          ) : (
            <img src={profilePicture} alt="Profile" />
          )}
          <div className="user-name">
            {loading ? (
              <Skeleton width={200} />
            ) : (
              userData.name || userData.user
            )}
          </div>
        </div>
        <div className="payment-methods">
          {loading ? (
            <Skeleton count={3} height={100} />
          ) : paymentMethods.length > 0 ? (
            paymentMethods.map((method, index) => (
              <div
                key={index}
                className={`payment-method ${method.isActive ? 'selected' : ''}`}
                onClick={() => handleSelectMethod(method)}
              >
                <div className="method-info">
                  {method.brand} ending in {method.last4}
                </div>
                <div className={`method-expiry ${method.isExpired ? 'expired' : ''}`}>
                  Exp: {method.expDate}
                </div>
                <div className="delete-button-wrapper">
                  <button
                    className="delete-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      setMethodToDelete(method);
                      setShowDeleteModal(true);
                    }}
                  >
                    ×
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className='profile-invoice-no-payment'>No payment methods available</div>
          )}
        </div>
        <div className="actions">
          <button className="add-payment-button" onClick={handleAddPaymentClick}>Add Card</button>
        </div>
      </div>
      {showDeleteModal && (
        <DeleteCardModal
          onClose={() => setShowDeleteModal(false)}
          onConfirm={handleDeleteMethod}
        />
      )}
    </div>
  );
};

const DeleteCardModal = ({ onClose, onConfirm }) => (
  <div className="delete-card-modal-overlay">
    <div className="delete-card-modal-content">
      <h2 className="delete-card-modal-sign-in-head">Delete Card?</h2>
      <div className="delete-card-modal-actions">
        <button className="delete-card-yes-button" onClick={onConfirm}>
          Confirm
        </button>
        <button className="delete-card-no-button" onClick={onClose}>
          Cancel
        </button>
      </div>
    </div>
  </div>
);

export default ProfileInvoice;
