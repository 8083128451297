import React, { useState, useEffect, useRef, useCallback } from "react";
import { generateClient } from "aws-amplify/api";
import { uploadData } from "aws-amplify/storage";
import { listMessages, getUser, getCompany, getMessages } from "../../../graphQL/queries";
import { createMessages, updateMessages, deleteMessages, updateCompany, updateUser } from "../../../graphQL/mutations";
import { onCreateMessages } from "../../../graphQL/subscriptions";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./MessagesModal.css";
import SearchFunction from "../SearchFunction/SearchFunction";
import ReportModal from "../ReportModal/ReportModal";
import { useNavigate } from 'react-router-dom';
import ImageModal from "../../Syn/ImageModal/ImageModal";

const generateConversationID = (userID1, userID2) => {
  return [userID1, userID2].sort().join('-');
};

const MessagesModal = ({ userID, receiverID, userType, conversationID, onClose }) => {
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(conversationID || null);
  const [currentMessage, setCurrentMessage] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [showImageModal, setShowImageModal] = useState(false);
  const [currentAttachments, setCurrentAttachments] = useState([]);
  const [selectedAttachmentIndex, setSelectedAttachmentIndex] = useState(0);
  const [messages, setMessages] = useState([]);
  const [otherUserDetails, setOtherUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hoveredConversation, setHoveredConversation] = useState(null);
  const [showDropdown, setShowDropdown] = useState(null);
  const [blocked, setBlocked] = useState(false);
  const [blockMessage, setBlockMessage] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [reportDetails, setReportDetails] = useState({ conversationID: "", reportedUserID: "" });
  const [currentUserDetails, setCurrentUserDetails] = useState(null);
  const client = generateClient();
  const messagesEndRef = useRef(null);
  const textareaRef = useRef(null);
  const subscriptionsRef = useRef({});
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);
  const [openedFromProfile, setOpenedFromProfile] = useState(false);
  const navigate = useNavigate();

  const fetchUserOrCompanyDetails = useCallback(async (id) => {
    try {
      const userResult = await client.graphql({ query: getUser, variables: { id: id } });
      if (userResult.data.getUser) {
        return userResult.data.getUser;
      }
  
      const companyResult = await client.graphql({
        query: getCompany,
        variables: { id: id },
      });
  
      if (companyResult.data.getCompany) {
        return {
          ...companyResult.data.getCompany,
          blocked: companyResult.data.getCompany.blocked || [],
        };
      }
  
      return null;
    } catch (error) {
      console.error("Error fetching user or company details:", error);
      return null;
    }
  }, [client]);  

  const fetchConversations = useCallback(async () => {
    try {
      const result = await client.graphql({
        query: listMessages,
        variables: {
          filter: {
            or: [
              { and: [{ senderID: { eq: userID } }, { senderDeleted: { ne: true } }] },
              { and: [{ receiverID: { eq: userID } }, { receiverDeleted: { ne: true } }] }
            ]
          },
        },
      });
      const conversationsData = result.data.listMessages.items;
      const uniqueConversations = [
        ...new Set(conversationsData.map((msg) => msg.conversationID)),
      ];
      const conversationsWithDetails = await Promise.all(
        uniqueConversations.map(async (conversationID) => {
          const conversationMessages = conversationsData.filter(
            (msg) => msg.conversationID === conversationID
          ).filter(msg => !(msg.senderID === userID && msg.senderDeleted) && !(msg.receiverID === userID && msg.receiverDeleted)); 
          const lastMessage = conversationMessages.reduce(
            (latest, message) =>
              new Date(message.timeStamp) > new Date(latest.timeStamp)
                ? message
                : latest,
            conversationMessages[0]
          );
          const otherUserID =
            lastMessage.senderID === userID
              ? lastMessage.receiverID
              : lastMessage.senderID;
          const otherUserDetails = await fetchUserOrCompanyDetails(otherUserID);

          const hasUnreadMessages = conversationMessages.some(
            (msg) => msg.receiverID === userID && !msg.readStatus
          );

          return {
            conversationID,
            lastMessage: lastMessage.messageContent.replace(/<br>/g, ' '),
            lastMessageTime: formatMessageTime(lastMessage.timeStamp),
            otherUser: otherUserDetails,
            updatedAt: lastMessage.timeStamp,
            unread: hasUnreadMessages,
            messages: conversationMessages,
          };
        })
      );

      setConversations(
        conversationsWithDetails.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        )
      );
    } catch (error) {
      console.error("Error fetching conversations:", error);
    }
    setLoading(false);
  }, [userID, client, fetchUserOrCompanyDetails]);

  useEffect(() => {
    if (shouldScrollToBottom) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, shouldScrollToBottom]);

  const formatMessageTime = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const timeDiff = Math.abs(now - date);
    const oneDay = 24 * 60 * 60 * 1000;

    if (timeDiff < oneDay) {
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      return `${formattedHours}:${formattedMinutes} ${ampm}`;
    } else if (timeDiff < 7 * oneDay) {
      const daysAgo = Math.floor(timeDiff / oneDay);
      return `${daysAgo} day${daysAgo > 1 ? "s" : ""} ago`;
    } else {
      return date.toLocaleDateString("en-US", { month: "numeric", day: "numeric" });
    }
  };

  const handleSelectConversation = async (conversationID) => {
    cleanupSubscriptions();
    subscribeToConversation(conversationID);

    try {
      const conversation = conversations.find((conv) => conv.conversationID === conversationID);
      if (conversation) {
        const isBlocked = currentUserDetails?.blocked?.includes(conversation.otherUser.id);
        const isBlockedBy = conversation.otherUser.blocked?.includes(userID);
        setBlocked(isBlocked || isBlockedBy);

        const result = await client.graphql({
          query: listMessages,
          variables: { filter: { conversationID: { eq: conversationID } } },
        });
        const messagesData = result.data.listMessages.items;

        await Promise.all(
          messagesData.map(async (msg) => {
            if (msg.receiverID === userID && !msg.readStatus) {
              await client.graphql({
                query: updateMessages,
                variables: { input: { id: msg.id, readStatus: true } },
              });
            }
          })
        );

        setMessages(
          messagesData.filter(msg => !(msg.senderID === userID && msg.senderDeleted) && !(msg.receiverID === userID && msg.receiverDeleted))
            .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
        );
        setSelectedConversation(conversationID);
        setOtherUserDetails(conversation.otherUser);

        setConversations((prevConversations) =>
          prevConversations.map((conv) =>
            conv.conversationID === conversationID
              ? { ...conv, unread: false }
              : conv
          )
        );
      }
    } catch (error) {
      console.error("Error selecting conversation:", error);
    }
  };

  useEffect(() => {
    setCurrentMessage("");
    setAttachments([]);
    setAttachmentFiles([]);
  }, [selectedConversation]);

  const handleAttachmentClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.multiple = true;
    input.onchange = (e) => {
      const files = Array.from(e.target.files).slice(0, 10); 
      const selectedFiles = files.map(file => URL.createObjectURL(file));
      setAttachments((prevAttachments) => [...prevAttachments, ...selectedFiles]); 
      setAttachmentFiles((prevFiles) => [...prevFiles, ...files]); 
    };
    input.click();
  };

  const handleRemoveAttachment = (index) => {
    setAttachments((prevAttachments) => prevAttachments.filter((_, i) => i !== index));
    setAttachmentFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const openImageModal = (index, messageAttachments) => {
    setSelectedAttachmentIndex(index);
    setCurrentAttachments(messageAttachments.map(key => `https://media.spiggl.com/public/${key}`));
    setShowImageModal(true);
  }; 

  const closeImageModal = () => {
    setShowImageModal(false);
  };
  
  const generateUniqueKey = (file) => {
    return `messages/${Date.now()}_${Math.random().toString(36).substr(2, 9)}_${file.name}`;
  };

  const uploadToS3 = async (file) => {
    const key = generateUniqueKey(file);
    try {
      const result = await uploadData({
        key,
        data: file,
        options: {
          contentType: file.type,
          level: 'public',
        },
      });
      console.log('File uploaded successfully:', key);
      return key;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  };

  const fetchNotificationSettings = async (id) => {
    try {
      const userResult = await client.graphql({ query: getUser, variables: { id } });
      if (userResult.data.getUser) {
        return userResult.data.getUser;
      }
  
      const companyResult = await client.graphql({ query: getCompany, variables: { id } });
      if (companyResult.data.getCompany) {
        return companyResult.data.getCompany;
      }
  
      return null;
    } catch (error) {
      console.error("Error fetching notification settings:", error);
      return null;
    }
  };  

  const invokeSendEmailLambda = async (recipientEmail, senderName, messageContent) => {
    const API_ENDPOINT = process.env.REACT_APP_EMAIL_MESSAGE_NOTIFICATION_API;

    try {
      const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ recipientEmail, senderName, messageContent }),
      });

    } catch (error) {
    }
  };

  const handleSendMessage = async () => {
    const formattedMessage = currentMessage.trim();
    const displayMessage = attachmentFiles.length > 0 && !formattedMessage ? "Attached image" : formattedMessage;
  
    if ((!formattedMessage && attachmentFiles.length === 0) || blocked) return;
  
    const conversationID = selectedConversation || generateConversationID(userID, otherUserDetails?.id);
  
    if (!otherUserDetails) {
      console.error("Other user details not found.");
      return;
    }
  
    const otherUserEmail = otherUserDetails?.email;
    const otherUserName = getDisplayName(currentUserDetails);
  
    if (!otherUserEmail) {
      console.error("Recipient email is not found.");
      return;
    }
  
    let attachmentKeys = [];
    if (attachmentFiles.length > 0) {
      try {
        attachmentKeys = await Promise.all(
          attachmentFiles.map(file => uploadToS3(file))
        );
      } catch (error) {
        console.error("Error uploading files:", error);
        return;
      }
    }
  
    const newMessage = {
      conversationID,
      senderID: userID,
      receiverID: otherUserDetails.id,
      messageContent: formattedMessage || "",
      readStatus: false,
      timeStamp: new Date().toISOString(),
      receiverDeleted: false,
      senderDeleted: false,
      attachmentkey: attachmentKeys,
    };
  
    try {
      const result = await client.graphql({
        query: createMessages,
        variables: { input: newMessage },
      });
  
      setMessages(prevMessages => [...prevMessages, result.data.createMessages]);
      updateConversationsList(result.data.createMessages);
  
      setCurrentMessage("");
      setAttachments([]);
      setAttachmentFiles([]);
      setShouldScrollToBottom(true);
  
      const notificationStatus = await fetchNotificationSettings(otherUserDetails.id);
  
      if (notificationStatus?.messageNotifications) {
        invokeSendEmailLambda(otherUserEmail, otherUserName, displayMessage);
      } else {
        console.log('User or company has disabled message notifications. No email will be sent.');
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const updateConversationsList = useCallback((message) => {
    setConversations((prevConversations) => {
      const updatedConversations = prevConversations.map((conv) =>
        conv.conversationID === message.conversationID
          ? {
              ...conv,
              lastMessage: message.messageContent.replace(/<br>/g, ' '),
              lastMessageTime: formatMessageTime(message.timeStamp),
              updatedAt: message.timeStamp,
              unread: message.receiverID === userID && !message.readStatus,
            }
          : conv
      );
      return updatedConversations.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    });
  }, [userID]);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleBackToList = () => {
    setSelectedConversation(null);
    setMessages([]);
    setOtherUserDetails(null);
    fetchConversations();
  };

  const subscribeToConversation = useCallback((conversationID) => {
    if (subscriptionsRef.current[conversationID]) return;
  
    const messageSubscription = client.graphql({
      query: onCreateMessages,
      variables: { filter: { conversationID: { eq: conversationID } } },
    }).subscribe({
      next: ({ data }) => {
        const newMessage = data.onCreateMessages;
        if (newMessage.senderID !== userID) {
          setMessages(prevMessages => [...prevMessages, newMessage]);
          setShouldScrollToBottom(true);
          updateConversationsList(newMessage);
        }
      },
      error: (error) => console.error("Message subscription error:", error),
      complete: () => console.log("Message subscription completed"),
    });
  
    subscriptionsRef.current[conversationID] = messageSubscription;
  
    return () => {
      messageSubscription.unsubscribe();
      delete subscriptionsRef.current[conversationID];
    };
  }, [userID, client, updateConversationsList]);

  const cleanupSubscriptions = useCallback(() => {
    Object.values(subscriptionsRef.current).forEach(subscription => {
      if (subscription && typeof subscription.unsubscribe === 'function') {
        subscription.unsubscribe();
      }
    });
    subscriptionsRef.current = {};
  }, []);

  const handleDeleteConversation = async (conversationID) => {
    try {
      const conversation = conversations.find(
        (conv) => conv.conversationID === conversationID
      );
      if (conversation) {
        const isCurrentUserSender = conversation.messages[0].senderID === userID;
        const updateField = isCurrentUserSender ? 'senderDeleted' : 'receiverDeleted';

        await Promise.all(
          conversation.messages.map(async (msg) => {
            const currentMessage = await client.graphql({
              query: getMessages,
              variables: { id: msg.id },
            });

            const updatedMessage = {
              id: msg.id,
              [updateField]: true,
            };

            if (currentMessage.data.getMessages[updateField === 'senderDeleted' ? 'receiverDeleted' : 'senderDeleted']) {
              await client.graphql({ query: deleteMessages, variables: { input: { id: msg.id } } });
            } else {
              await client.graphql({ query: updateMessages, variables: { input: updatedMessage } });
            }
          })
        );

        setConversations(prevConversations => 
          prevConversations.filter(conv => conv.conversationID !== conversationID)
        );
      }
    } catch (error) {
      console.error("Error deleting conversation:", error);
    }
  };

  const handleSpecificConversation = useCallback(async (receiverID) => {
    try {
      const userDetails = await fetchUserOrCompanyDetails(receiverID);
      if (userDetails?.blocked?.includes(userID)) {
        setBlocked(true);
        return;
      }
      
      const conversationID = generateConversationID(userID, receiverID);
      setSelectedConversation(conversationID);
      setOtherUserDetails(userDetails);

      const result = await client.graphql({
        query: listMessages,
        variables: {
          filter: { conversationID: { eq: conversationID } },
          sort: { field: "createdAt", direction: "ASC" },
          limit: 50 
        },
      });

      const messagesData = result.data.listMessages.items;
      setMessages(messagesData.filter(msg => !(msg.senderID === userID && msg.senderDeleted) && !(msg.receiverID === userID && msg.receiverDeleted)));

      const messageSubscription = subscribeToConversation(conversationID);

      return () => {
        messageSubscription.unsubscribe();
      };
    } catch (error) {
      console.error("Error fetching specific conversation:", error);
    }
  }, [userID, client, fetchUserOrCompanyDetails, subscribeToConversation]);

  useEffect(() => {
    const fetchCurrentUserDetails = async () => {
      try {
        const details = await fetchUserOrCompanyDetails(userID);
        setCurrentUserDetails(details);
      } catch (error) {
        console.error("Error fetching current user details:", error);
      }
    };

    fetchCurrentUserDetails();
    fetchConversations();
  }, [userID, client, fetchUserOrCompanyDetails, fetchConversations]);

  useEffect(() => {
    if (receiverID) {
      handleSpecificConversation(receiverID);
    }
  }, [receiverID]);

  useEffect(() => {
    adjustTextareaHeight();
  }, [currentMessage]);

  useEffect(() => {
    if (selectedConversation) {
      const unsubscribe = subscribeToConversation(selectedConversation);
      return () => {
        if (unsubscribe) unsubscribe();
      };
    }
  }, [selectedConversation, subscribeToConversation]);

  const handleMarkAsReadUnread = async (conversationID, isRead) => {
    try {
      const conversation = conversations.find(
        (conv) => conv.conversationID === conversationID
      );
      if (conversation) {
        await Promise.all(
          conversation.messages.map(async (msg) => {
            if (msg.receiverID === userID) {
              await client.graphql({
                query: updateMessages,
                variables: {
                  input: {
                    id: msg.id,
                    readStatus: isRead,
                  },
                },
              });
            }
          })
        );

        const updatedConversations = conversations
          .map((conv) =>
            conv.conversationID === conversationID ? { ...conv, unread: !isRead } : conv
          )
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

        setConversations(updatedConversations);

        fetchConversations();
      }
    } catch (error) {
      console.error("Error updating read status:", error);
    }
  };

  const handleShowDropdown = (conversationID) => {
    setShowDropdown(showDropdown === conversationID ? null : conversationID);
  };

  const handleBlockUser = async (blockedUserID) => {
    try {
      const result = await client.graphql({ query: getUser, variables: { id: userID } });
      const user = result.data.getUser;
  
      if (!user) {
        const companyResult = await client.graphql({ query: getCompany, variables: { id: userID } });
        const company = companyResult.data.getCompany;
  
        if (!company) {
          console.error("Company data is null");
          return;
        }
  
        const updatedCompany = {
          ...company,
          blocked: Array.isArray(company.blocked) ? [...company.blocked, blockedUserID] : [blockedUserID],
        };
  
        await client.graphql({
          query: updateCompany,
          variables: { input: { id: userID, blocked: updatedCompany.blocked } },
        });
  
        setCurrentUserDetails(updatedCompany);
      } else {
        const updatedUser = {
          ...user,
          blocked: Array.isArray(user.blocked) ? [...user.blocked, blockedUserID] : [blockedUserID],
        };
  
        await client.graphql({
          query: updateUser,
          variables: { input: { id: userID, blocked: updatedUser.blocked } },
        });
  
        setCurrentUserDetails(updatedUser);
      }
  
      setBlocked(true);
      setBlockMessage(true);
      setTimeout(() => setBlockMessage(false), 3000);
      fetchConversations();
    } catch (error) {
      console.error("Error blocking user:", error);
    }
  };

  const handleUnblockUser = async (unblockedUserID) => {
    try {
      const result = await client.graphql({ query: getUser, variables: { id: userID } });
      const user = result.data.getUser;
  
      if (!user) {
        const companyResult = await client.graphql({ query: getCompany, variables: { id: userID } });
        const company = companyResult.data.getCompany;
  
        if (!company) {
          console.error("Company data is null");
          return;
        }
  
        const updatedCompany = {
          ...company,
          blocked: Array.isArray(company.blocked) ? company.blocked.filter(id => id !== unblockedUserID) : [],
        };
  
        await client.graphql({
          query: updateCompany,
          variables: { input: { id: userID, blocked: updatedCompany.blocked } },
        });
  
        setCurrentUserDetails(updatedCompany);
      } else {
        const updatedUser = {
          ...user,
          blocked: Array.isArray(user.blocked) ? user.blocked.filter(id => id !== unblockedUserID) : [],
        };
  
        await client.graphql({
          query: updateUser,
          variables: { input: { id: userID, blocked: updatedUser.blocked } },
        });
  
        setCurrentUserDetails(updatedUser);
      }
  
      setBlocked(false);
      fetchConversations();
    } catch (error) {
      console.error("Error unblocking user:", error);
    }
  };

  const handleSelectResult = async (result) => {
    try {
      const existingConversation = conversations.find(
        (conv) => conv.otherUser.id === result.id
      );
      if (existingConversation) {
        setSelectedConversation(existingConversation.conversationID);
        setMessages(existingConversation.messages.filter(msg => !(msg.senderID === userID && msg.senderDeleted) && !(msg.receiverID === userID && msg.receiverDeleted)));
        setOtherUserDetails(result);
      } else {
        const newConversationID = generateConversationID(userID, result.id);
        setSelectedConversation(newConversationID);
        setOtherUserDetails(result);
        setMessages([]);
      }
    } catch (error) {
      console.error("Error selecting search result:", error);
    }
  };

  const shouldDisplayTime = (currentIndex) => {
    if (currentIndex === messages.length - 1) return true;
    const currentMessage = messages[currentIndex];
    const nextMessage = messages[currentIndex + 1];
    const currentTime = new Date(currentMessage.timeStamp).getTime();
    const nextTime = new Date(nextMessage.timeStamp).getTime();
    return (
      nextTime - currentTime > 60 * 60 * 1000 || currentMessage.senderID !== nextMessage.senderID
    );
  };

  const getDisplayName = (user) => {
    if (!user) return "";
    return user.name || user.user || user.companyName || user.companyUser;
  };

  const createDefaultProfileImage = (user) => {
    const firstChar = getDisplayName(user).charAt(0).toUpperCase();
    return `data:image/svg+xml;base64,${btoa(
      `<svg xmlns="http://www.w3.org/2000/svg" width="150" height="150">
        <circle cx="75" cy="75" r="75" fill="#23313d"/>
        <text x="50%" y="50%" font-family="Arial, sans-serif" font-size="64" dy=".3em" fill="#FFF" text-anchor="middle">${firstChar}</text>
      </svg>`
    )}`;
  };

  const handleReportConversation = (conversationID, otherUser) => {
    setReportDetails({
      conversationID: conversationID,
      reportedUserID: otherUser.id,
    });
    setShowReportModal(true);
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const isAtBottom = scrollHeight - scrollTop - clientHeight < 10; 
    setShouldScrollToBottom(isAtBottom);
  };

  const renderMessage = (msg, index, userID) => {
    return (
      <div key={msg.id} className={`message-container ${msg.senderID === userID ? "" : "received"}`}>
        <div
          className={`message-content ${msg.attachmentkey && msg.messageContent ? "column-layout" : ""}`}
        >
          {msg.attachmentkey && msg.attachmentkey.length > 0 && renderAttachmentMessage(msg.attachmentkey, msg.senderID, userID)}
          {msg.messageContent && (
            <div
              className={`message-modal ${msg.senderID === userID ? "message-sent" : "message-received"}`}
              dangerouslySetInnerHTML={{ __html: msg.messageContent }}
            />
          )}
        </div>
        {shouldDisplayTime(index) && (
          <div className={`message-time ${msg.senderID === userID ? "time-sent" : "time-received"}`}>
            {formatMessageTime(msg.timeStamp)}
          </div>
        )}
      </div>
    );
  };
  
  const renderAttachmentMessage = (attachmentkey, senderID, userID) => {
    return (
      <div className={`message-attachment-container ${senderID === userID ? "message-attachment-sent" : "message-attachment-received"}`}>
        {attachmentkey.map((key, index) => (
          <div key={index} className="message-attachment-wrapper" onClick={() => openImageModal(index, attachmentkey)}>
            <img
              src={`https://media.spiggl.com/public/${key}`}
              alt="Attachment"
              className="message-attachment-image-style"
            />
          </div>
        ))}
      </div>
    );
  };   

  const handleUserNameClick = () => {
    if (otherUserDetails?.companyUser) {
      navigate(`/contractor/${otherUserDetails.companyUser}`);
    } else if (otherUserDetails?.user) {
      navigate(`/user/${otherUserDetails.user}`);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          ×
        </button>
        {(selectedConversation && !openedFromProfile) || (selectedConversation && openedFromProfile && messages.length > 0) ? (
          <>
            <div className="modal-top-bar">
              <button onClick={handleBackToList} className="back-button">
                ❮
              </button>
              <div className="message-header-info" onClick={handleUserNameClick}>
                {otherUserDetails && (
                  <>
                    <img
                      src={
                        otherUserDetails?.profilepicturekey
                          ? `https://media.spiggl.com/public/${otherUserDetails.profilepicturekey}`
                          : createDefaultProfileImage(otherUserDetails)
                      }
                      alt="Profile"
                      className="conversation-profile-picture"
                    />
                    <span className="name">{getDisplayName(otherUserDetails)}</span>
                  </>
                )}
              </div>
            </div>
            <div className="messages-container" onScroll={handleScroll}>
              <div className="messages-list">
                {messages.map((msg, index) => renderMessage(msg, index, userID))}
                <div ref={messagesEndRef} />
              </div>
            </div>
            {attachments.length > 0 && (
              <div className="attachment-preview-container">
                {attachments.map((attachment, index) => (
                  <div key={index} className="attachment-preview">
                    <img src={attachment} alt="Attachment Preview" className="attachment-image" />
                    <button className="remove-attachment-button" onClick={() => handleRemoveAttachment(index)}>x</button>
                  </div>
                ))}
              </div>
            )}
            <div className="new-message-container">
              <button className="attach-button" onClick={handleAttachmentClick}>
                <img src="/icons/attach.png" className="attach-icon" alt="Attach Icon" />
              </button>
              <textarea
                ref={textareaRef}
                placeholder={
                  currentUserDetails?.blocked?.includes(otherUserDetails?.id)
                    ? `Unblock ${getDisplayName(otherUserDetails)} to message`
                    : otherUserDetails?.blocked?.includes(userID)
                    ? `${getDisplayName(otherUserDetails)} has blocked you`
                    : `Message ${getDisplayName(otherUserDetails)}`
                }
                value={currentMessage}
                onChange={(e) => {
                  setCurrentMessage(e.target.value);
                  adjustTextareaHeight();
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    setCurrentMessage(prevMessage => prevMessage + '\n');
                  }
                }}
                rows="1"
                style={{ resize: "none", overflowY: "auto", maxHeight: "120px" }}
                disabled={blocked}
              />
              <button
                className="message-send-button"
                onClick={handleSendMessage}
                disabled={blocked}
              >
                <img src="/icons/send.png" className="message-send-icon" alt="Send Icon" />
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="modal-top-bar">
              <h2>Messages</h2>
              <SearchFunction
                userType={userType}
                userId={userID}
                onSelectResult={handleSelectResult}
                className="search-function-bar"
              />
            </div>
            <div className="conversations-list">
              {loading ? (
                <Skeleton count={5} height={60} />
              ) : (
                conversations.map((conv) => (
                  <div
                    key={conv.conversationID}
                    className={`conversation-item ${conv.unread ? "unread" : ""}`}
                    onMouseEnter={() => setHoveredConversation(conv.conversationID)}
                    onMouseLeave={() => setHoveredConversation(null)}
                  >
                    <div
                      className={`conversation-profile-wrapper ${
                        hoveredConversation === conv.conversationID ? "blur" : ""
                      }`}
                    >
                      <img
                        src={
                          conv.otherUser?.profilepicturekey
                            ? `https://media.spiggl.com/public/${conv.otherUser?.profilepicturekey}`
                            : createDefaultProfileImage(conv.otherUser)
                        }
                        alt="Profile"
                        className="conversation-profile-picture"
                      />
                      {conv.unread && <div className="unread-indicator" />}
                      {hoveredConversation === conv.conversationID && (
                        <div
                          className="three-dots-horizontal"
                          onClick={() => handleShowDropdown(conv.conversationID)}
                        >
                          ⋯
                        </div>
                      )}
                    </div>
                    <div
                      className="conversation-details"
                      onClick={() => handleSelectConversation(conv.conversationID)}
                    >
                      <div className="conversation-name-date">
                        <span className="conversation-name">{getDisplayName(conv.otherUser)}</span>
                        <span className="conversation-date">{conv.lastMessageTime}</span>
                      </div>
                      <span className="conversation-last-message">{conv.lastMessage}</span>
                    </div>
                    {showDropdown === conv.conversationID && (
                      <div className="message-dropdown-menu" onMouseLeave={() => setShowDropdown(null)}>
                        {conv.unread ? (
                          <div
                            className="message-dropdown-item"
                            onClick={() => handleMarkAsReadUnread(conv.conversationID, true)}
                          >
                            Mark as read
                          </div>
                        ) : (
                          <div
                            className="message-dropdown-item"
                            onClick={() => handleMarkAsReadUnread(conv.conversationID, false)}
                          >
                            Mark as unread
                          </div>
                        )}
                        <div
                          className="message-dropdown-item"
                          onClick={() => handleDeleteConversation(conv.conversationID)}
                        >
                          Delete conversation
                        </div>
                        <div
                          className="message-dropdown-item"
                          onClick={() => handleReportConversation(conv.conversationID, conv.otherUser)}
                        >
                          Report conversation
                        </div>
                        <div
                          className="message-dropdown-item"
                          onClick={() =>
                            currentUserDetails?.blocked?.includes(conv.otherUser.id)
                              ? handleUnblockUser(conv.otherUser.id)
                              : handleBlockUser(conv.otherUser.id)
                          }
                        >
                          {currentUserDetails?.blocked?.includes(conv.otherUser.id)
                            ? "Unblock user"
                            : "Block user"}
                        </div>
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>
          </>
        )}
        {blockMessage && (
          <div className="block-overlay">
            <div className="block-message">
              <h2>Blocked ✓</h2>
              <p>You will not receive any more messages from this user.</p>
            </div>
          </div>
        )}
        {showReportModal && (
          <ReportModal
            conversationID={reportDetails.conversationID}
            userID={userID}
            reportedUserID={reportDetails.reportedUserID}
            onClose={() => setShowReportModal(false)}
            onSubmit={(reason) => {
              console.log(`Report submitted with reason: ${reason}`);
              setShowReportModal(false);
            }}
          />
        )}
        <ImageModal
          show={showImageModal}
          attachments={currentAttachments}
          selectedIndex={selectedAttachmentIndex}
          onClose={closeImageModal}
          onNext={() => setSelectedAttachmentIndex((prevIndex) => (prevIndex + 1) % currentAttachments.length)}
          onPrev={() => setSelectedAttachmentIndex((prevIndex) => (prevIndex - 1 + currentAttachments.length) % currentAttachments.length)}
        />
      </div>
    </div>
  );
};

export default MessagesModal;
