import React, { useState, useEffect } from 'react';
import './FilterModal.css';
import contractorSpecialties from '../../../Syn/ContractorSpecialties';
import { useParams } from 'react-router-dom';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const FilterModal = ({ selectedRadius, selectedSpecialties, selectedPriceRange, selectedRatings, handleRadiusChange, handleSpecialtiesChange, handlePriceRangeChange, handleRatingsChange, onClose, onApplyFilters, onResetFilters, highestPrice, initialRange }) => { // Added initialRange
  const [localRadius, setLocalRadius] = useState(selectedRadius);
  const [localSpecialties, setLocalSpecialties] = useState(selectedSpecialties);
  const [priceRange, setPriceRange] = useState(selectedPriceRange || initialRange);
  const [ratings, setRatings] = useState(selectedRatings || { overall: [0, 5], quality: [0, 5], cost: [0, 5], timeliness: [0, 5] });
  const { type } = useParams();
  const specialties = contractorSpecialties[type] || [];

  useEffect(() => {
    setLocalRadius(selectedRadius);
    setLocalSpecialties(selectedSpecialties);
    if (selectedPriceRange) {
      setPriceRange(selectedPriceRange);
    } else {
      setPriceRange(initialRange); 
    }
    if (selectedRatings) {
      setRatings(selectedRatings);
    }
  }, [selectedRadius, selectedSpecialties, selectedPriceRange, selectedRatings, initialRange]);

  const handleRadiusChangeSlider = (e) => {
    setLocalRadius({ label: `${e.target.value} miles`, value: parseInt(e.target.value, 10) });
  };

  const handleSpecialtyChange = (e) => {
    const value = e.target.value;
    setLocalSpecialties((prevSelected) => {
      if (prevSelected.includes(value)) {
        return prevSelected.filter((specialty) => specialty !== value);
      } else {
        return [...prevSelected, value];
      }
    });
  };

  const handleApplyFilters = () => {
    handleRadiusChange(localRadius);
    handleSpecialtiesChange(localSpecialties);
    handlePriceRangeChange(priceRange);
    handleRatingsChange(ratings);
    onApplyFilters(localSpecialties);
    onClose();
  };

  const handleReset = () => {
    setLocalRadius({ label: '10 miles', value: 10 });
    setLocalSpecialties([]);
    setPriceRange(initialRange); 
    setRatings({ overall: [0, 5], quality: [0, 5], cost: [0, 5], timeliness: [0, 5] });
    onResetFilters();
    onClose();
  };

  const handlePriceChange = (range) => {
    setPriceRange(range);
  };

  const handleRatingChange = (category, range) => {
    setRatings((prevRatings) => ({ ...prevRatings, [category]: range }));
  };

  const renderRatingSlider = (label, category) => (
    <div className="filter-group">
      <label>{label}</label>
      <Slider
        range
        min={0}
        max={5}
        step={1}
        value={ratings[category]}
        onChange={(range) => handleRatingChange(category, range)}
        marks={{ 0: '0', 1: '1', 2: '2', 3: '3', 4: '4', 5: '5' }}
        trackStyle={[{ backgroundColor: '#333' }]}
        handleStyle={[{ borderColor: '#333' }, { borderColor: '#333' }]}
        railStyle={{ backgroundColor: '#ddd' }}
      />
      <div className="rating-value">
        <span>{ratings[category][0]}</span> - <span>{ratings[category][1]}</span>
      </div>
    </div>
  );

  return (
    <div className="filter-modal">
      <div className="filter-content">
        <div className="filter-header">
          <h2>Filters</h2>
          <button className="close-button" onClick={onClose}>×</button>
        </div>
        <div className="filter-body">
          <div className="filter-group">
            <label>Distance</label>
            <input
              type="range"
              min="5"
              max="40"
              step="5"
              value={localRadius.value}
              onChange={handleRadiusChangeSlider}
              className="radius-slider"
            />
            <div className="radius-value">{localRadius.label}</div>
          </div>
          <div className="filter-group specialties-group">
            <label>Specialties</label>
            <div className="specialties-list">
              {specialties.map((specialty) => (
                <div key={specialty} className="specialty-item">
                  <input
                    type="checkbox"
                    id={specialty}
                    value={specialty}
                    checked={localSpecialties.includes(specialty)}
                    onChange={handleSpecialtyChange}
                  />
                  <label htmlFor={specialty}>{specialty}</label>
                </div>
              ))}
            </div>
          </div>
          <div className="price-filter-group">
            <label>Price Range</label>
            <Slider
              range
              min={initialRange[0]} 
              max={initialRange[1]} 
              defaultValue={priceRange}
              value={priceRange}
              onChange={handlePriceChange}
              trackStyle={[{ backgroundColor: '#333' }]}
              handleStyle={[{ borderColor: '#333' }, { borderColor: '#333' }]}
              railStyle={{ backgroundColor: '#ddd' }}
            />
            <div className="price-value">
              <span>${priceRange[0]}</span> - <span>${priceRange[1]}</span>
            </div>
          </div>
          <div className="ratings-filter-group">
            {renderRatingSlider('Overall Rating', 'overall')}
            {renderRatingSlider('Quality Rating', 'quality')}
            {renderRatingSlider('Cost Rating', 'cost')}
            {renderRatingSlider('Timeliness Rating', 'timeliness')}
          </div>
        </div>
        <div className="filter-footer">
          <button onClick={handleReset} className="reset-button">Reset Filters</button>
          <button onClick={handleApplyFilters} className="apply-button">Apply Filters</button>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
