import React, { useState, useEffect, useRef } from 'react';
import { generateClient } from "aws-amplify/api";
import { uploadData } from "aws-amplify/storage";
import { updateReview } from '../../../graphQL/mutations';
import { listCompanies } from '../../../graphQL/queries';
import "./EditReviewModal.css";
import { useNavigate } from 'react-router-dom';

const EditReviewModal = ({ review, show, onClose, onUpdate }) => {
  const client = generateClient();
  const suggestionsListRef = useRef(null);
  const navigate = useNavigate();

  const [companyUser, setCompanyUser] = useState('');
  const [suggestedUsernames, setSuggestedUsernames] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [newAttachments, setNewAttachments] = useState([]);
  const [qualityRating, setQualityRating] = useState(0);
  const [timelinessRating, setTimelinessRating] = useState(0);
  const [costRating, setCostRating] = useState(0);
  const [overallRating, setOverallRating] = useState(0);
  const [reviewTitle, setReviewTitle] = useState('');
  const [reviewDescription, setReviewDescription] = useState('');
  const [selectedCompany, setSelectedCompany] = useState(null);

  const [companyUserError, setCompanyUserError] = useState('');
  const [attachmentsError, setAttachmentsError] = useState('');
  const [qualityRatingError, setQualityRatingError] = useState('');
  const [timelinessRatingError, setTimelinessRatingError] = useState('');
  const [costRatingError, setCostRatingError] = useState('');
  const [overallRatingError, setOverallRatingError] = useState('');
  const [reviewTitleError, setReviewTitleError] = useState('');
  const [reviewDescriptionError, setReviewDescriptionError] = useState('');

  const [qualityHover, setQualityHover] = useState(0);
  const [costHover, setCostHover] = useState(0);
  const [timelinessHover, setTimelinessHover] = useState(0);
  const [overallHover, setOverallHover] = useState(0);

  useEffect(() => {
    if (review) {
      setCompanyUser(review.company.companyUser);
      setAttachments(review.attachmentkey.map(key => ({ name: key.split('/').pop(), key })));
      setQualityRating(review.quality);
      setTimelinessRating(review.timeliness);
      setCostRating(review.cost);
      setOverallRating(review.overall);
      setReviewTitle(review.title);
      setReviewDescription(review.description);
      setSelectedCompany(review.company);
    }
  }, [review]);

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleAttachmentsChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setNewAttachments((prev) => [...prev, ...newFiles]);
    setAttachmentsError('');
  };

  const handleRemoveAttachment = (index, isExisting) => {
    if (isExisting) {
      setAttachments((prev) => prev.filter((_, i) => i !== index));
    } else {
      setNewAttachments((prev) => prev.filter((_, i) => i !== index));
    }
  };

  const handleReviewTitleChange = (event) => {
    setReviewTitle(event.target.value);
    setReviewTitleError('');
  };

  const handleReviewDescriptionChange = (event) => {
    setReviewDescription(event.target.value);
    setReviewDescriptionError('');
  };

  const handleStarClick = (category, rating) => {
    switch (category) {
      case 'quality':
        setQualityRating(Math.round(rating));
        setQualityRatingError('');
        break;
      case 'timeliness':
        setTimelinessRating(Math.round(rating));
        setTimelinessRatingError('');
        break;
      case 'cost':
        setCostRating(Math.round(rating));
        setCostRatingError('');
        break;
      case 'overall':
        setOverallRating(Math.round(rating));
        setOverallRatingError('');
        break;
      default:
        break;
    }
  };

  const uploadToS3 = async (file) => {
    const key = `reviews/${Date.now()}_${file.name}`;
    try {
      await uploadData({
        key,
        data: file,
        options: {
          contentType: file.type,
          level: 'public',
        }
      });
      console.log('File uploaded successfully:', key);
      return key;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  };

  const fetchCompanyByCompanyUser = debounce(async (companyUser) => {
    try {
      const { data } = await client.graphql({
        query: listCompanies,
        variables: { filter: { companyUser: { contains: companyUser } } },
      });
      if (data.listCompanies.items.length > 0) {
        setSuggestedUsernames(data.listCompanies.items);
      } else {
        setSuggestedUsernames([]);
        console.error("No companies found.");
      }
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  }, 300);

  const handleCompanyUserChange = (event) => {
    const input = event.target.value;
    setCompanyUser(input);
    setCompanyUserError('');
    setSelectedCompany(null);

    if (input.length > 1) {
      fetchCompanyByCompanyUser(input);
    } else {
      setSuggestedUsernames([]);
    }
  };

  const selectCompany = (company) => {
    setSelectedCompany(company);
    setCompanyUser(company.companyUser);
    setSuggestedUsernames([]);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionsListRef.current && !suggestionsListRef.current.contains(event.target)) {
        setSuggestedUsernames([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    let hasErrors = false;

    if (!companyUser) {
      setCompanyUserError('Please enter a company username');
      hasErrors = true;
    }

    if (!reviewTitle) {
      setReviewTitleError('Please enter a review title');
      hasErrors = true;
    }

    let matchedCompany = null;
    if (suggestedUsernames.length > 0) {
      matchedCompany = suggestedUsernames.find(
        (company) => company.companyUser.toLowerCase() === companyUser.toLowerCase()
      );
    }

    if (!matchedCompany) {
      try {
        const { data } = await client.graphql({
          query: listCompanies,
          variables: { filter: { companyUser: { eq: companyUser } } },
        });
        if (data.listCompanies.items.length > 0) {
          matchedCompany = data.listCompanies.items[0];
        }
      } catch (error) {
        console.error('Error fetching company:', error);
      }
    }

    if (!matchedCompany) {
      setCompanyUserError('Company username not recognized');
      hasErrors = true;
    }

    if (qualityRating === 0) {
      setQualityRatingError('Please rate quality');
      hasErrors = true;
    }
    if (timelinessRating === 0) {
      setTimelinessRatingError('Please rate timeliness');
      hasErrors = true;
    }
    if (costRating === 0) {
      setCostRatingError('Please rate cost');
      hasErrors = true;
    }
    if (overallRating === 0) {
      setOverallRatingError('Please rate overall');
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    const newAttachmentKeys = await Promise.all(
      newAttachments.map(file => uploadToS3(file))
    );

    const updatedAttachmentKeys = [
      ...attachments.map(att => att.key),
      ...newAttachmentKeys
    ];

    const input = {
      id: review.id,
      companyID: matchedCompany.id,
      userID: review.userID,
      attachmentkey: updatedAttachmentKeys,
      quality: qualityRating,
      timeliness: timelinessRating,
      cost: costRating,
      overall: overallRating,
      title: reviewTitle,
      description: reviewDescription,
    };

    console.log('Review input:', input);

    try {
      const response = await client.graphql({
        query: updateReview,
        variables: { input },
      });

      console.log('Review updated successfully:', response.data.updateReview);
      onUpdate(response.data.updateReview);
      navigate(0);
      onClose();
    } catch (error) {
      console.error('Error updating review:', error);
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div className="edit-review-modal-backdrop" onClick={onClose}>
      <div className="edit-review-modal-content" onClick={(e) => e.stopPropagation()}>
        <form onSubmit={handleSubmit} className="edit-review-review-form">
          <div className="edit-review-form-header">Edit Review</div>
          <div className="edit-review-rating-container">
            <div className="edit-review-form-field">
              <label className="edit-review-rating-label">Quality:</label>
              <div className="edit-review-ratings">
                {[1, 2, 3, 4, 5].map((rating) => (
                  <span
                    key={rating}
                    className={`edit-review-star ${rating <= (qualityHover || qualityRating) ? 'active' : ''}`}
                    onMouseEnter={() => setQualityHover(rating)}
                    onMouseLeave={() => setQualityHover(0)}
                    onClick={() => handleStarClick('quality', rating)}
                  >
                    ★
                  </span>
                ))}
              </div>
              <div className="error-message">{qualityRatingError}</div>
            </div>
            <div className="edit-review-form-field">
              <label className="edit-review-rating-label">Cost:</label>
              <div className="edit-review-ratings">
                {[1, 2, 3, 4, 5].map((rating) => (
                  <span
                    key={rating}
                    className={`edit-review-star ${rating <= (costHover || costRating) ? 'active' : ''}`}
                    onMouseEnter={() => setCostHover(rating)}
                    onMouseLeave={() => setCostHover(0)}
                    onClick={() => handleStarClick('cost', rating)}
                  >
                    ★
                  </span>
                ))}
              </div>
              <div className="error-message">{costRatingError}</div>
            </div>
            <div className="edit-review-form-field">
              <label className="edit-review-rating-label">Timeliness:</label>
              <div className="edit-review-ratings">
                {[1, 2, 3, 4, 5].map((rating) => (
                  <span
                    key={rating}
                    className={`edit-review-star ${rating <= (timelinessHover || timelinessRating) ? 'active' : ''}`}
                    onMouseEnter={() => setTimelinessHover(rating)}
                    onMouseLeave={() => setTimelinessHover(0)}
                    onClick={() => handleStarClick('timeliness', rating)}
                  >
                    ★
                  </span>
                ))}
              </div>
              <div className="error-message">{timelinessRatingError}</div>
            </div>
            <div className="edit-review-form-field">
              <label className="edit-review-rating-label">Overall:</label>
              <div className="edit-review-ratings">
                {[1, 2, 3, 4, 5].map((rating) => (
                  <span
                    key={rating}
                    className={`edit-review-star ${rating <= (overallHover || overallRating) ? 'active' : ''}`}
                    onMouseEnter={() => setOverallHover(rating)}
                    onMouseLeave={() => setOverallHover(0)}
                    onClick={() => handleStarClick('overall', rating)}
                  >
                    ★
                  </span>
                ))}
              </div>
            </div>
            <div className="error-message">{overallRatingError}</div>
          </div>
          <div className="edit-review-form-field">
            <input
              type="text"
              placeholder="Company Username"
              value={companyUser}
              onChange={handleCompanyUserChange}
              className={`company-user-input ${companyUserError ? 'input-error' : ''}`}
            />
            <div className="error-message">{companyUserError}</div>
            <ul className="edit-review-suggestions-list" ref={suggestionsListRef}>
              {suggestedUsernames.map((company) => (
                <li key={company.id} onClick={() => selectCompany(company)}>
                  {company.companyUser}
                </li>
              ))}
            </ul>
          </div>
          <div className="edit-review-form-field">
            <input
              type="text"
              placeholder="Review Title"
              value={reviewTitle}
              onChange={handleReviewTitleChange}
              className={`${reviewTitleError ? 'input-error' : ''}`}
            />
            <div className="error-message">{reviewTitleError}</div>
          </div>
          <div className="edit-review-form-field">
            <textarea
              placeholder="Review Description"
              value={reviewDescription}
              onChange={handleReviewDescriptionChange}
            />
            <div className="error-message">{reviewDescriptionError}</div>
          </div>
          <div className="edit-review-form-field">
            <label htmlFor="fileInput" className="edit-review-file-input-label">
              <span className="button-text">Choose File(s)</span>
            </label>
            <input
              type="file"
              id="fileInput"
              accept="image/*"
              multiple
              onChange={handleAttachmentsChange}
              className="file-input-hidden"
            />
            {attachments.length > 0 && (
              <div className="edit-review-file-name-display">
                {attachments.map((file, index) => (
                  <div key={index} className="edit-review-file-item">
                    <span>{file.name}</span>
                    <button type="button" className="edit-review-remove-button" onClick={() => handleRemoveAttachment(index, true)}>x</button>
                  </div>
                ))}
              </div>
            )}
            {newAttachments.length > 0 && (
              <div className="edit-review-file-name-display">
                {newAttachments.map((file, index) => (
                  <div key={index} className="edit-review-file-item">
                    <span>{file.name}</span>
                    <button type="button" className="edit-review-remove-button" onClick={() => handleRemoveAttachment(index, false)}>x</button>
                  </div>
                ))}
              </div>
            )}
            <div className="error-message">{attachmentsError}</div>
          </div>
          <button className="post-button" type="submit">
            Update
          </button>
        </form>
        <button className="edit-review-close-button" onClick={onClose}>×</button>
      </div>
    </div>
  );
};

export default EditReviewModal;
