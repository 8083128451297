import React from 'react';
import InvoiceHeader from '../../../Headers/InvoiceHeader/InvoiceHeader';
import Footer from '../../../Footer/Footer';
import CompanyInvoices from '../CompanyInvoices/CompanyInvoices';
import ProfileInvoice from '../ProfileInvoice/ProfileInvoice';
import CurrentInvoice from '../CurrentInvoice/CurrentInvoice';
import PastInvoices from '../PastInvoices/PastInvoices';
import { useAuth } from '../../../../auth/auth';
import { Helmet } from 'react-helmet';
import './CompanyInvoicePage.css';

const CompanyInvoicePage = () => {
  const { userID } = useAuth();

  return (
    <div className="invoice-full-page">
      <InvoiceHeader />
      <Helmet>
        <title>Invoices</title>
      </Helmet>
      <div className="invoice-page">
        <div className="profile-section">
          <ProfileInvoice userID={userID} />
          <CurrentInvoice userID={userID} />
          <PastInvoices userID={userID} />
        </div>
        <div className="invoices-section">
          <CompanyInvoices userID={userID} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CompanyInvoicePage;
