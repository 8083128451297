import React, { useEffect, useState } from 'react';
import { generateClient } from 'aws-amplify/api';
import * as queries from '../../../../graphQL/queries';
import { useAuth } from "../../../../auth/auth";
import { useNavigate } from 'react-router-dom';
import './CurrentInvoice.css';

const CurrentInvoice = () => {
  const [invoices, setInvoices] = useState([]);
  const [companies, setCompanies] = useState({});
  const [userData, setUserData] = useState({});
  const client = generateClient();
  const { userID } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await client.graphql({
          query: queries.getUser,
          variables: { id: userID }
        });

        if (data.getUser) {
          setUserData(data.getUser);
        } else {
          console.error('User not found with id:', userID);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const fetchCurrentInvoices = async () => {
      try {
        const invoiceData = await client.graphql({
          query: queries.invoicesByUserID,
          variables: { userID, filter: { status: { eq: "Pending" } } }
        });

        if (invoiceData.data.invoicesByUserID && invoiceData.data.invoicesByUserID.items.length > 0) {
          const pendingInvoices = invoiceData.data.invoicesByUserID.items;

          const companyPromises = pendingInvoices.map(invoice => 
            client.graphql({
              query: queries.getCompany,
              variables: { id: invoice.companyID }
            })
          );

          const companyData = await Promise.all(companyPromises);
          const companyMap = {};
          companyData.forEach((response, index) => {
            if (response.data.getCompany) {
              companyMap[pendingInvoices[index].companyID] = response.data.getCompany;
            }
          });

          const sortedInvoices = pendingInvoices.sort((a, b) => {
            const nextPaymentA = getCurrentOrNextPaymentDetails(a);
            const nextPaymentB = getCurrentOrNextPaymentDetails(b);
            return new Date(nextPaymentA?.dueDate || Infinity) - new Date(nextPaymentB?.dueDate || Infinity);
          });

          setInvoices(sortedInvoices);
          setCompanies(companyMap);
        } else {
          console.error("No pending invoices found for userID:", userID);
        }
      } catch (error) {
        console.error("Error fetching current invoices:", error);
      }
    };

    fetchUserData();
    fetchCurrentInvoices();
    
  }, [userID, client]);

  const openInvoicePaymentPage = (invoiceID) => {
    navigate(`/user/${userData.user}/invoice-dash/invoice/${invoiceID}`);
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'Invalid date';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'Invalid date';
    const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };

  const formatAmount = (amount) => {
    return amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const getCurrentOrNextPaymentDetails = (invoice) => {
    const now = new Date();
    const today = new Date(now.setHours(0, 0, 0, 0)); 
    let currentOrNextPayment = null;
  
    for (let i = 0; i < invoice.payments.length; i++) {
      let dueDate = new Date(invoice.dueDates[i]);
      const dueDateWithoutTime = new Date(dueDate.setHours(0, 0, 0, 0));
  
      if (invoice.oneTimePayment) {
        dueDateWithoutTime.setDate(dueDateWithoutTime.getDate() + 1);
      }
  
      if (dueDateWithoutTime <= today) {
        currentOrNextPayment = {
          amount: invoice.payments[i],
          total: invoice.payments[i],
          dueDate: dueDateWithoutTime.toISOString(),
          isPastDue: dueDateWithoutTime < today,
          isToday: dueDateWithoutTime.getTime() === today.getTime()
        };
      }
    }
  
    if (!currentOrNextPayment) {
      const nextPaymentIndex = invoice.payments.findIndex((_, index) => 
        new Date(invoice.dueDates[index]).setHours(0, 0, 0, 0) > today
      );
  
      if (nextPaymentIndex !== -1) {
        let nextDueDate = new Date(invoice.dueDates[nextPaymentIndex]);
        
        if (invoice.oneTimePayment) {
          nextDueDate.setDate(nextDueDate.getDate() + 1);
        }
  
        currentOrNextPayment = {
          amount: invoice.payments[nextPaymentIndex],
          total: invoice.payments[nextPaymentIndex],
          dueDate: nextDueDate.toISOString(),
          isPastDue: false,
          isToday: false
        };
      }
    }
  
    if (currentOrNextPayment) {
      return {
        amount: formatAmount(currentOrNextPayment.amount),
        total: formatAmount(currentOrNextPayment.total),
        dueDate: formatDate(currentOrNextPayment.dueDate),
        isPastDue: currentOrNextPayment.isPastDue,
        isToday: currentOrNextPayment.isToday
      };
    }
  
    return null;
  };  

  return (
    <div className="current-invoice">
      {invoices.length > 0 ? (
        invoices.map((invoice) => {
          const paymentDetails = getCurrentOrNextPaymentDetails(invoice);
          const company = companies[invoice.companyID];
          return (
            <div key={invoice.id} className="current-invoice-details">
              <div className="current-invoice-header">
                <div className="current-invoice-company-name">
                  {company.companyName || company.companyUser ? (
                    <a 
                      href={`/contractor/${company.companyUser}`}
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="company-link"
                    >
                      {company.companyName || company.companyUser}
                    </a>
                  ) : (
                    company.companyName || company.companyUser
                  )}
                </div>
                <div className="current-invoice-amount">
                  Amount: ${paymentDetails ? paymentDetails.total : '0.00'}
                </div>
              </div>
              <div className="current-invoice-bottom">
                <div 
                  className={`current-invoice-due-date ${
                    paymentDetails?.isPastDue ? 'due-text-red' :
                    paymentDetails?.isToday ? 'due-text-yellow' :
                    'due-text-default'
                  }`}
                >
                  Due: {paymentDetails ? paymentDetails.dueDate : 'N/A'}
                </div>
                <button 
                  className="current-invoice-pay-button" 
                  onClick={() => openInvoicePaymentPage(invoice.id)}
                >
                  Pay
                </button>
              </div>
            </div>
          );
        })
      ) : (
        <div className="current-invoice-no-invoice">No current payments due</div>
      )}
    </div>
  );
};

export default CurrentInvoice;
