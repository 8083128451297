import React, { useState } from 'react';
import './Contact.css';
import { Helmet } from 'react-helmet';
import { generateClient } from 'aws-amplify/api';
import { createContact } from '../../../graphQL/mutations';
import FormHeader from '../../Headers/FormHeader/FormHeader';

function Contact() {
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const client = generateClient();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const input = { email, title: subject, message };
      const response = await client.graphql({
        query: createContact,
        variables: { input },
      });
      console.log('Contact form submitted:', response.data.createContact);

      await invokeContactEmailLambda(email, subject, message);

      setEmail('');
      setSubject('');
      setMessage('');
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
    } catch (error) {
      console.error('Error submitting contact form:', error);
    }
  };

  const invokeContactEmailLambda = async (email, subject, message) => {
    const API_ENDPOINT = process.env.REACT_APP_EMAIL_CONTACT_NOTIFICATION_API;

    try {
      const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          subject,
          message,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to send contact notification');
      }

      console.log('Contact notification sent successfully');
    } catch (error) {
      console.error('Error sending contact notification:', error);
    }
  };

  return (
    <div className="Contact">
      <FormHeader />
      <Helmet>
        <title>Contact Us</title>
      </Helmet>
      <div className="contact-page">
        <div className="contact-header">Get in Touch With Us</div>
        <form onSubmit={handleSubmit} className="contact-form">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="subject">Subject</label>
            <input
              id="subject"
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="submit-button">Send</button>
          {showSuccess && (
            <div className="success-message">
              <span>Message Sent!</span>
              <br/>
              <span>We'll get back to you soon!</span>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default Contact;
