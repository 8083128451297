import React, { useState, useEffect } from "react";
import './InternalCompanyProfile.css';
import { useNavigate } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import { useAuth } from '../../../auth/auth';
import { Helmet } from 'react-helmet';
import * as queries from "../../../graphQL/queries";
import ProfileHeader from '../../Headers/ProfileHeader/ProfileHeader';
import Footer from '../../Footer/Footer';
import CompanyReviews from "../CompanyReviews/CompanyReviews";
import InternalCompanyHighlights from "../Highlights/InternalCompanyHighlights/InternalCompanyHighlights";
import MessagesModal from "../../Syn/Messages/MessagesModal";
import SignInModal from "../../../auth/SignInModal/SignInModal";

const InternalCompanyProfile = () => {
  const { userID } = useAuth();
  const client = generateClient();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Reviews");
  const [companyData, setCompanyData] = useState({});
  const [background, setBackground] = useState("/icons/default-background.jpg");
  const [profilePicture, setProfilePicture] = useState("");
  const [specialties, setSpecialties] = useState([]);
  const [showMessagesModal, setShowMessagesModal] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [companyNotFound, setCompanyNotFound] = useState(false);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const toggleMessagesModal = () => {
    setShowMessagesModal(!showMessagesModal);
  };

  const toggleSignInModal = () => {
    setShowSignInModal(!showSignInModal);
  };

  const handleEditProfileClick = () => {
    navigate(`/contractor/${companyData.companyUser}/edit-profile`);
  };

  const handlePostHighlightClick = () => {
    navigate(`/contractor/${companyData.companyUser}/post-highlight`);
  };

  useEffect(() => {
    const fetchCompanyData = async () => {
      if (userID) {
        try {
          const { data } = await client.graphql({
            query: queries.getCompany,
            variables: { id: userID },
          });

          if (data.getCompany) {
            const company = data.getCompany;
            setCompanyData(company);

            if (company.backgroundpicturekey) {
              setBackground(`https://media.spiggl.com/public/${company.backgroundpicturekey}`);
            }

            if (company.profilepicturekey) {
              setProfilePicture(`https://media.spiggl.com/public/${company.profilepicturekey}`);
            } else {
              setProfilePicture(createDefaultProfileImage(company.companyUser));
            }
          } else {
            setCompanyNotFound(true);
          }
        } catch (error) {
          setCompanyNotFound(true);
        }
      } else {
        setCompanyNotFound(true);
      }
    };

    fetchCompanyData();
  }, [userID, client]);

  useEffect(() => {
    const fetchSpecialtiesData = async () => {
      if (companyData.id) {
        try {
          const { data } = await client.graphql({
            query: queries.specialtiesByCompanyID,
            variables: { companyID: companyData.id },
          });
          setSpecialties(data.specialtiesByCompanyID.items);
        } catch (error) {
          console.error("Error fetching specialties:", error);
        }
      }
    };

    fetchSpecialtiesData();
  }, [companyData.id, client]);

  function createDefaultProfileImage(username) {
    const firstChar = username ? username.charAt(0).toUpperCase() : '';
    return `data:image/svg+xml;base64,${btoa(
      `<svg xmlns="http://www.w3.org/2000/svg" width="150" height="150">
         <circle cx="75" cy="75" r="75" fill="#23313d"/>
         <text x="50%" y="50%" font-family="Arial, sans-serif" font-size="64" dy=".3em" fill="#FFF" text-anchor="middle">${firstChar}</text>
       </svg>`
    )}`;
  }

  const renderSpecialties = () => {
    if (specialties.length === 0) {
      return null;
    }

    const contractorTypes = [...new Set(specialties.map(s => s.type))];

    const columns = contractorTypes.map((type, index) => (
      <div className="specialty-column" key={type}>
        <div className="specialty-header">
          <img src={`/icons/contractor/${type.toLowerCase().replace(/\s+/g, '-')}.png`} alt={`${type} Icon`} />
          {type}
        </div>
        <ul>
          {specialties.filter(s => s.type === type).flatMap(s => s.specialties).map((spec, index) => (
            <li key={index}>{spec}</li>
          ))}
        </ul>
      </div>
    ));

    return (
      <div className="specialties-columns">
        {columns}
      </div>
    );
  };

  const renderPriceRange = () => {
    const { lowRange, highRange, lowPrivate, highPrivate } = companyData;

    if (lowPrivate && highPrivate) {
      return null;
    }

    if (!lowPrivate && highPrivate) {
      return (
        <div className="price-range-info">
          <div className="info-header">
            <img src="/icons/money.png" alt="Price Icon" /> Starting at
          </div>
          <div className="text-container">
            ${lowRange}
          </div>
        </div>
      );
    }

    return (
      <div className="price-range-info">
        <div className="info-header">
          <img src="/icons/money.png" alt="Price Icon" /> Price Range
        </div>
        <div className="text-container">
          ${lowRange} - ${highRange}
        </div>
      </div>
    );
  };

  const renderContactInfo = () => {
    const showEmail = !companyData.emailPrivate && companyData.email;
    const showPhone = !companyData.phonePrivate && companyData.phone;

    if (!showEmail && !showPhone) {
      return null;
    }

    return (
      <div className="contact-info">
        <div className="info-header">
          <img src="/icons/contact.png" alt="Contact Icon" /> Contact
        </div>
        {showEmail && (
          <div className={`text-container ${companyData.email?.length > 22 ? 'small' : ''}`}>
            {companyData.email}
          </div>
        )}
        {showPhone && (
          <div className={`text-container ${companyData.phone?.length > 22 ? 'small' : ''}`}>
            {companyData.phone}
          </div>
        )}
      </div>
    );
  };

  const renderLocationInfo = () => {
    return (
      <div className="location-info">
        <div className="info-header">
          <img src="/icons/location.png" alt="Location Icon" /> Located
        </div>
        {companyData.town && companyData.state && (
          <div className="text-container">
            {companyData.town}, {companyData.state}
          </div>
        )}
      </div>
    );
  };

  const renderWebsiteInfo = () => {
    if (!companyData.companyWebsite) {
      return null;
    }

    const formattedWebsite = companyData.companyWebsite.startsWith("http://") || companyData.companyWebsite.startsWith("https://")
      ? companyData.companyWebsite
      : `http://${companyData.companyWebsite}`;

    return (
      <div className="website-info">
        <div className="info-header">
          <img src="/icons/www.png" alt="Website Icon" /> Website
        </div>
        <div className="text-container">
          <a href={formattedWebsite} target="_blank" rel="noopener noreferrer" className="visit-website-link">
            Visit Website <img src="/icons/launch.png" alt="Launch Icon" className="launch-icon" />
          </a>
        </div>
      </div>
    );
  };

  return (
    <div className="company-profile">
      <ProfileHeader />
      <Helmet>
        <title>{companyData.companyUser}</title>
      </Helmet>
        <div className="ei-company">
          <div className="background-picture-company">
            <img src={background} alt="Company Background" />
            <div className="profile-picture-company">
              <img src={profilePicture || createDefaultProfileImage(companyData.companyUser)} alt="Company Profile" />
            </div>
          </div>
          <div className="company-white-background-container">
            <div className="company-menu">
              <div className="company-name-container">
                <div className={`company-profile-username ${companyData.companyUser?.length > 25 ? 'small' : ''}`}>{companyData.companyUser}</div>
                <div className={`company-profile_name ${companyData.companyName?.length > 25 ? 'small' : ''}`}>{companyData.companyName || <span>&nbsp;</span>}</div>
              </div>
              <div className="internal-company-buttons">
                <div onClick={handleEditProfileClick} className="company-edit-profile">
                  <img src="/icons/edit.png" alt="Edit Profile" />
                </div>
                <div onClick={handlePostHighlightClick} className="company-create-post-button">
                  <img src="/icons/plus.png" alt="Create Highlight" />
                  highlight
                </div>
              </div>
            </div>
            <div className="company-info">
              <div className="company-info-container">
                <div className="specialties-container">
                  {renderSpecialties()}
                </div>
                <div className="contact-column">
                  {renderPriceRange()}
                  {renderContactInfo()}
                  {renderLocationInfo()}
                  {renderWebsiteInfo()}
                </div>
              </div>
              <div className="company-bio">{companyData.bio || ''}</div>
            </div>
          </div>
          <div className="company-review-tabs">
            <button className={activeTab === "Reviews" ? "active" : ""} onClick={() => handleTabClick("Reviews")}> Reviews </button>
            <button className={activeTab === "Highlights" ? "active" : ""} onClick={() => handleTabClick("Highlights")}> Highlights </button>
          </div>
        </div>
        <div className="reviews-highlights">
          {activeTab === "Reviews" && !companyNotFound && <CompanyReviews companyID={companyData.id} />}
          {activeTab === "Highlights" && !companyNotFound && <InternalCompanyHighlights companyID={companyData.id} />}
        </div>
        {showMessagesModal && <MessagesModal userID={userID} receiverID={companyData.id} onClose={toggleMessagesModal} />}
        {showSignInModal && <SignInModal onClose={toggleSignInModal} />}
      <Footer />
    </div>
  );
};

export default InternalCompanyProfile;
