import React, { useState, useEffect } from 'react';
import './InternalSchedule.css';
import { generateClient } from "aws-amplify/api";
import * as queries from "../../../../graphQL/queries";
import * as mutations from "../../../../graphQL/mutations";
import { useAuth } from '../../../../auth/auth';
import { Helmet } from 'react-helmet';
import FormHeader from '../../../Headers/FormHeader/FormHeader';
import Footer from '../../../Footer/Footer';

const InternalSchedule = () => {
  const { userID } = useAuth();
  const client = generateClient();
  const [schedule, setSchedule] = useState([]);
  const [requests, setRequests] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showModal, setShowModal] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [showMonthPicker, setShowMonthPicker] = useState(false);
  const [modalData, setModalData] = useState({ day: '', allDay: false, from: '', to: '', address: '', description: '' });
  const [companyData, setCompanyData] = useState({});
  const [profilePicture, setProfilePicture] = useState("");
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedRequests, setSelectedRequests] = useState([]);
  const [showDailySchedule, setShowDailySchedule] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [view, setView] = useState('month'); 

  const fetchScheduleData = async () => {
    try {
      const scheduleData = await client.graphql({
        query: queries.schedulesByCompanyID,
        variables: { companyID: userID }
      });
      
      const scheduleWithUsers = await Promise.all(
        scheduleData.data.schedulesByCompanyID.items.map(async (entry) => {
          try {
            const userData = await client.graphql({
              query: queries.getUser,
              variables: { id: entry.userID },
            });
            
            return {
              ...entry,
              user: userData.data.getUser
            };
          } catch (error) {
            return entry;
          }
        })
      );
      
      setSchedule(scheduleWithUsers.filter(entry => entry !== null));
    } catch (error) {
    }
  };

  const fetchRequestData = async () => {
    try {
      const requestData = await client.graphql({
        query: queries.requestsByCompanyID,
        variables: { companyID: userID }
      });

      const requestsWithUsers = await Promise.all(
        requestData.data.requestsByCompanyID.items.map(async (request) => {
          try {
            const userData = await client.graphql({
              query: queries.getUser,
              variables: { id: request.userID },
            });
            
            return {
              ...request,
              user: userData.data.getUser
            };
          } catch (error) {
            console.error('Error fetching user data for request:', error);
            return request;
          }
        })
      );

      const validRequests = requestsWithUsers.filter(request => request !== null);
      setRequests(validRequests);

      const pendingRequests = validRequests.filter(request => 
        !request.putOff && 
        request.isPending && 
        new Date(request.requestDate) >= new Date().setHours(0, 0, 0, 0)
      );
      
      if (pendingRequests.length > 0) {
        setSelectedRequests(pendingRequests);
        setShowRequestModal(true);
      }
    } catch (error) {
    }
  };

  const fetchCompanyData = async () => {
    try {
      const companyResponse = await client.graphql({
        query: queries.getCompany,
        variables: { id: userID }
      });

      if (companyResponse.data.getCompany) {
        setCompanyData(companyResponse.data.getCompany);
        if (companyResponse.data.getCompany.profilepicturekey) {
          setProfilePicture(`https://media.spiggl.com/public/${companyResponse.data.getCompany.profilepicturekey}`);
        } else {
          setProfilePicture(createDefaultProfileImage(companyResponse.data.getCompany.companyUser));
        }
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    const loadAllData = async () => {
      setIsLoading(true);
      if (userID) {
        await Promise.all([
          fetchScheduleData(),
          fetchRequestData(),
          fetchCompanyData()
        ]);
      }
      setIsLoading(false);
    };

    loadAllData();
  }, [userID]);

  const invokeRequestStatusEmailLambda = async (recipientEmail, contractorName, requestDetails, requestStatus) => {
    const API_ENDPOINT = process.env.REACT_APP_EMAIL_REQUEST_STATUS_NOTIFICATION_API;
  
    try {
      const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          recipientEmail,
          contractorName,
          requestDetails,
          requestStatus,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to send request status notification');
      }
  
      console.log('Request status notification sent successfully');
    } catch (error) {
      console.error('Error sending request status notification:', error);
    }
  };
  
  const handleRequestAction = async (request, isConfirmed) => {
    try {
      const { data } = await client.graphql({
        query: queries.getUser,
        variables: { id: request.userID }
      });
  
      const user = data.getUser;
  
      if (!user.requestSatusNotifications) {
        console.log(`Request status notifications are disabled for user ${user.email}`);
      } else {
        const requestDetails = {
          requestDate: request.requestDate,
          startTime: convertToStandardTime(request.startTime),
          endTime: convertToStandardTime(addHours(request.startTime, request.duration)),
          description: request.description,
          address: request.address,
        };
  
        const requestStatus = isConfirmed ? "confirmed" : "denied";
        const contractorName = companyData.companyName || companyData.companyUser;
  
        if (user.email) {
          invokeRequestStatusEmailLambda(user.email, contractorName, requestDetails, requestStatus);
        } else {
          console.error('User email is missing.');
        }
      }
  
      if (isConfirmed) {
        if (!isTimeSlotAvailable(request.startTime, request.duration, request.requestDate)) {
          setErrorMessage('Time slot already taken.');
          return;
        }
  
        const scheduleInput = {
          companyID: userID,
          userID: request.userID,
          day: request.requestDate,
          allDay: false,
          from: request.startTime,
          to: addHours(request.startTime, request.duration),
          address: request.address,
          description: request.description,
        };
        await client.graphql({ query: mutations.createSchedule, variables: { input: scheduleInput } });
        setSchedule(prevSchedule => [...prevSchedule, scheduleInput]);
      }
  
      await client.graphql({ query: mutations.deleteRequest, variables: { input: { id: request.id } } });
  
      setRequests(prevRequests => {
        const updatedRequests = prevRequests.filter(r => r.id !== request.id);
        return updatedRequests;
      });
  
      setSelectedRequests(prevSelected => {
        const updatedSelected = prevSelected.filter(r => r.id !== request.id);
        
        if (updatedSelected.length === 0) {
          setShowRequestModal(false);
          setErrorMessage('');
        }
        
        return updatedSelected;
      });
  
    } catch (error) {
      console.error('Error handling request:', error);
      setErrorMessage('An error occurred while processing the request.');
    }
  };      

  const handleCloseRequestModal = async () => {
    try {
      const updatedRequests = [];
      for (const request of selectedRequests) {
        await client.graphql({ 
          query: mutations.updateRequest, 
          variables: { 
            input: { 
              id: request.id, 
              putOff: true 
            } 
          } 
        });
        updatedRequests.push({ ...request, putOff: true });
      }
      setRequests(prevRequests => prevRequests.map(req => {
        const updatedRequest = updatedRequests.find(r => r.id === req.id);
        return updatedRequest ? updatedRequest : req;
      }));
      setSelectedRequests([]);
      setShowRequestModal(false);
    } catch (error) {
      console.error('Error putting off requests:', error);
    }
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
    const dayRequests = requests.filter(request => new Date(request.requestDate).toISOString().split('T')[0] === date.toISOString().split('T')[0]);
    if (dayRequests.length > 0) {
      setSelectedRequests(dayRequests);
      setShowRequestModal(true);
    } else {
      setModalData({ day: date.toISOString().split('T')[0], allDay: false, from: '', to: '', address: '', description: '' });
      setShowModal(true);
    }
  };

  const handleMonthClick = (month) => {
    setSelectedDate(new Date(selectedDate.getFullYear(), month, 1));
    setShowMonthPicker(false);
  };

  const handleModalChange = (e) => {
    const { name, value, type, checked } = e.target;
    setModalData(prevData => ({ ...prevData, [name]: type === 'checkbox' ? checked : value }));

    if (name === 'from' || name === 'to') {
      e.target.classList.remove('sched-input-error');
    }
  };

  const handleModalSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    if (!modalData.allDay && (!modalData.from || !modalData.to)) {
      if (!modalData.from) {
        document.getElementsByName('from')[0].classList.add('sched-input-error');
      }
      if (!modalData.to) {
        document.getElementsByName('to')[0].classList.add('sched-input-error');
      }
      return;
    }

    if (!modalData.allDay && new Date(`1970-01-01T${modalData.from}Z`) >= new Date(`1970-01-01T${modalData.to}Z`)) {
      setErrorMessage('Invalid time frame.');
      return;
    }

    try {
      const input = {
        companyID: userID,
        day: new Date(modalData.day).toISOString().split('T')[0],
        allDay: modalData.allDay,
        from: modalData.allDay ? null : modalData.from,
        to: modalData.allDay ? null : modalData.to,
        address: modalData.address,
        description: modalData.description
      };
      await client.graphql({ query: mutations.createSchedule, variables: { input } });
      setSchedule([...schedule, input]);
      setShowModal(false);
    } catch (error) {
      console.error('Error creating schedule entry:', error);
    }
  };

  const deleteEntry = async (entryId) => {
    try {
      await client.graphql({ query: mutations.deleteSchedule, variables: { input: { id: entryId } } });
      setSchedule(schedule.filter(entry => entry.id !== entryId));
    } catch (error) {
      console.error('Error deleting schedule entry:', error);
    }
  };

  const isTimeSlotAvailable = (startTime, duration, requestDate) => {
    const start = new Date(`${requestDate}T${startTime}`);
    const end = new Date(start.getTime() + duration * 60 * 60 * 1000);

    for (const entry of schedule) {
      const entryStart = new Date(`${new Date(entry.day).toISOString().split('T')[0]}T${entry.from}`);
      const entryEnd = new Date(`${new Date(entry.day).toISOString().split('T')[0]}T${entry.to}`);

      if ((start >= entryStart && start < entryEnd) || (end > entryStart && end <= entryEnd) || (start <= entryStart && end >= entryEnd)) {
        return false;
      }
    }
    return true;
  };

  const addHours = (time, hours) => {
    const [hoursPart, mins] = time.split(':').map(Number);
    const date = new Date(0, 0, 0, hoursPart, mins);
    date.setHours(date.getHours() + Number(hours));
    return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  };

  const renderCalendarDays = () => {
    const daysInMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0).getDate();
    const firstDayOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1).getDay();
    const calendarDays = [];
    for (let i = 0; i < firstDayOfMonth; i++) {
      calendarDays.push(<div key={`empty-${i}`} className="calendar-day empty"></div>);
    }
    for (let i = 1; i <= daysInMonth; i++) {
      const date = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), i));
      const daySchedule = schedule.filter(entry => new Date(entry.day).toISOString().split('T')[0] === date.toISOString().split('T')[0])
                                  .sort((a, b) => new Date(`1970-01-01T${a.from}Z`) - new Date(`1970-01-01T${b.from}Z`));
      const isUnavailable = daySchedule.some(entry => entry.allDay);
      const partialUnavailable = daySchedule.some(entry => !entry.allDay);
      const dayRequests = requests.filter(request => new Date(request.requestDate).toISOString().split('T')[0] === date.toISOString().split('T')[0]);
  
      calendarDays.push(
        <div key={i} className={`calendar-day ${isUnavailable ? 'unavailable' : partialUnavailable ? 'partial-unavailable' : ''}`} onClick={() => handleDateClick(date)}>
          <span className="day-number">{i}</span>
          {dayRequests.length > 0 && (
            <img src="/icons/reminder.png" alt="Reminder" className="reminder-icon" />
          )}
          {isUnavailable ? (
            <div className="unavailable-label">Unavailable</div>
          ) : partialUnavailable && (
            <div className="availability-details">
              {daySchedule.filter(entry => !entry.allDay).slice(0, 3).map((entry, idx) => (
                <div key={idx} className="availability-time">
                  {convertToStandardTime(entry.from)} - {convertToStandardTime(entry.to)}
                </div>
              ))}
            </div>
          )}
          <div className="add-entry-btn">+</div>
        </div>
      );
    }
    return calendarDays;
  };

  const convertToStandardTime = (time) => {
    if (!time) return 'N/A';
    let [hours, minutes] = time.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    return `${hours}:${minutes.toString().padStart(2, '0')} ${period}`;
  };

  const createDefaultProfileImage = (user) => {
    const firstChar = user ? user.charAt(0).toUpperCase() : '';
    return `data:image/svg+xml;base64,${btoa(`
      <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150">
        <circle cx="75" cy="75" r="75" fill="#23313d"/>
        <text x="50%" y="50%" font-family="Arial, sans-serif" font-size="64" dy=".3em" fill="#FFF" text-anchor="middle">${firstChar}</text>
      </svg>`
    )}`;
  };

  const handleViewChange = (newView) => {
    setView(newView);
  };

  const handlePrevClick = () => {
    if (view === 'month') {
      setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth() - 1, 1));
    } else if (view === 'week') {
      setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() - 7));
    } else {
      setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() - 1));
    }
  };

  const handleNextClick = () => {
    if (view === 'month') {
      setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 1));
    } else if (view === 'week') {
      setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + 7));
    } else {
      setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + 1));
    }
  };

  const renderWeekView = () => {
    const startOfWeek = selectedDate.getDate() - selectedDate.getDay();
    const weekDays = Array.from({ length: 7 }, (_, index) => new Date(selectedDate.getFullYear(), selectedDate.getMonth(), startOfWeek + index));
  
    return (
      <div className="week-view">
        {weekDays.map((day, index) => (
          <div key={index} className="week-day">
            <div className="week-day-header">
              {day.toLocaleDateString('en-US', { weekday: 'short' })}, {day.getMonth() + 1}/{day.getDate()}
            </div>
            <div className="week-timeline">
              {Array.from({ length: 18 }, (_, hourIndex) => {
                const hour = hourIndex + 6;
                const time = `${hour % 12 || 12} ${hour < 12 ? 'AM' : 'PM'}`;
                const daySchedule = schedule.filter(entry => new Date(entry.day).toISOString().split('T')[0] === day.toISOString().split('T')[0]);
  
                return (
                  <div key={hour} className="week-timeline-slot">
                    <div className="week-timeline-time">{time}</div>
                    <div className="week-timeline-entries">
                      {daySchedule.map((entry, idx) => {
                        if (entry.allDay) {
                          if (hour === 6) {
                            return (
                              <div
                                key={idx}
                                className="week-timeline-entry all-day-entry"
                                style={{
                                  top: '0%',
                                  height: '1750%'
                                }}
                              >
                                <div className="week-entry-time">All Day</div>
                              </div>
                            );
                          }
                        } else {
                          const startHour = parseInt(entry.from.split(':')[0]);
                          const endHour = parseInt(entry.to.split(':')[0]);
  
                          if (startHour === hour) {
                            const entryHeight = ((endHour - startHour) * 60 + (parseInt(entry.to.split(':')[1]) - parseInt(entry.from.split(':')[1]))) / 60 * 100;
  
                            return (
                              <div
                                key={idx}
                                className="week-timeline-entry"
                                style={{
                                  top: `${(parseInt(entry.from.split(':')[1]) / 60) * 100}%`,
                                  height: `${entryHeight}%`
                                }}
                              >
                                <div className="week-entry-time">{convertToStandardTime(entry.from)} - {convertToStandardTime(entry.to)}</div>
                              </div>
                            );
                          }
                        }
  
                        return null;
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  const renderDayView = (date) => {
    const daySchedule = schedule.filter(entry => new Date(entry.day).toISOString().split('T')[0] === date.toISOString().split('T')[0]);
  
    return (
      <div className="day-view">
        <div className="day-view-header">
          {date.toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' })}
        </div>
        <div className="timeline">
          {Array.from({ length: 18 }, (_, index) => {
            const hour = index + 6;
            const time = `${hour % 12 || 12} ${hour < 12 ? 'AM' : 'PM'}`;
  
            return (
              <div key={hour} className="timeline-slot">
                <div className="timeline-time">{time}</div>
                <div className="timeline-entries">
                  {daySchedule.map((entry, idx) => {
                    if (entry.allDay) {
                      if (hour === 6) {
                        return (
                          <div
                            key={idx}
                            className="timeline-entry all-day-entry"
                            style={{ top: '0%', height: '1750%' }}
                          >
                            <div className="entry-time">All Day</div>
                            <div className="entry-details">{entry.address}</div>
                            <div className="entry-description">{entry.description}</div>
                          </div>
                        );
                      }
                    } else {
                      const startHour = parseInt(entry.from.split(':')[0]);
                      const endHour = parseInt(entry.to.split(':')[0]);
  
                      if (startHour === hour) {
                        const entryHeight = ((endHour - startHour) * 60 + (parseInt(entry.to.split(':')[1]) - parseInt(entry.from.split(':')[1]))) / 60 * 100;
                        const user = entry.user;
  
                        return (
                          <div
                            key={idx}
                            className="timeline-entry"
                            style={{ top: `${(parseInt(entry.from.split(':')[1]) / 60) * 100}%`, height: `${entryHeight}%` }}
                          >
                            <div className="entry-time">{convertToStandardTime(entry.from)} - {convertToStandardTime(entry.to)}</div>
                            {user && (
                              <div className="entry-user-button">
                                <a href={`/user/${user.user}`} target="_blank" rel="noopener noreferrer">{user.name || user.user}</a>
                              </div>
                            )}
                            <div className="entry-details">{entry.address}</div>
                            <div className="entry-description">{entry.description}</div>
                          </div>
                        );
                      }
                    }
                    return null;
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderModalDayView = (date) => {
    const adjustedDate = new Date(date);
    adjustedDate.setDate(date.getDate() + 1);

    const daySchedule = schedule.filter(entry => new Date(entry.day).toISOString().split('T')[0] === date.toISOString().split('T')[0]);

    return (
      <div className="day-view">
        <div className="day-view-header">
          {adjustedDate.toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' })}
        </div>
        <div className="timeline">
          {Array.from({ length: 18 }, (_, index) => {
            const hour = index + 6;
            const time = `${hour % 12 || 12} ${hour < 12 ? 'AM' : 'PM'}`;

            return (
              <div key={hour} className="timeline-slot">
                <div className="timeline-time">{time}</div>
                <div className="timeline-entries">
                  {daySchedule.map((entry, idx) => {
                    if (entry.allDay) {
                      if (hour === 6) {
                        return (
                          <div
                            key={idx}
                            className="timeline-entry all-day-entry"
                            style={{
                              top: '0%',
                              height: '1750%'
                            }}
                          >
                            <div className="entry-time">All Day</div>
                            <div className="entry-details">{entry.address}</div>
                            <div className="entry-description">{entry.description}</div>
                          </div>
                        );
                      }
                    } else {
                      const startHour = parseInt(entry.from.split(':')[0]);
                      const endHour = parseInt(entry.to.split(':')[0]);

                      if (startHour === hour) {
                        const entryHeight = ((endHour - startHour) * 60 + (parseInt(entry.to.split(':')[1]) - parseInt(entry.from.split(':')[1]))) / 60 * 100;
                        const user = entry.user;

                        return (
                          <div
                            key={idx}
                            className="timeline-entry"
                            style={{
                              top: `${(parseInt(entry.from.split(':')[1]) / 60) * 100}%`,
                              height: `${entryHeight}%`
                            }}
                          >
                            <div className="entry-time">{convertToStandardTime(entry.from)} - {convertToStandardTime(entry.to)}</div>
                            {user && (
                              <div className="entry-user-button">
                                <a href={`/user/${user.user}`} target="_blank" rel="noopener noreferrer">{user.name || user.user}</a>
                              </div>
                            )}
                            <div className="entry-details">{entry.address}</div>
                            <div className="entry-description">{entry.description}</div>
                          </div>
                        );
                      }
                    }

                    return null;
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="calendar-container">
      <FormHeader />
      <Helmet>
        <title>
          {companyData.companyName ? `${companyData.companyName}'s Calendar` : companyData.companyUser ? `${companyData.companyUser}'s Calendar` : 'Calendar'}
        </title>
      </Helmet>
      <div className="schedule">
        <div className="profile-header">
          {profilePicture && <img src={profilePicture} alt="Profile" />}
          <div className="company-name">
            {companyData.companyName || companyData.companyUser}
          </div>
        </div>
        <div className="calendar">
          <div className="calendar-header">
            <button onClick={handlePrevClick}> ❮ </button>
            {view === 'month' && <span onClick={() => setShowMonthPicker(true)}>{selectedDate.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span>}
            <button onClick={handleNextClick}> ❯ </button>
          </div>
          <div className="view-buttons">
            {['Month', 'Week', 'Day'].map(viewType => (
              <button 
                key={viewType} 
                className={`view-button ${view.toLowerCase() === viewType.toLowerCase() ? 'active' : ''}`} 
                onClick={() => handleViewChange(viewType.toLowerCase())}
              >
                <p>{viewType}</p>
              </button>
            ))}
          </div>
          {view === 'month' && (
            <>
              <div className="calendar-day-names">
                {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
                  <div key={day} className="calendar-day-name">{day}</div>
                ))}
              </div>
              <div className="calendar-grid"> {renderCalendarDays()} </div>
            </>
          )}
          {view === 'week' && renderWeekView()}
          {view === 'day' && renderDayView(selectedDate)}
        </div>
        {showModal && (
          <div className="calendar-modal-overlay">
            <div className="calendar-modal-content">
              <button className="calendar-close-modal-button" onClick={() => setShowModal(false)}>×</button>
              <form onSubmit={handleModalSubmit}>
                <div className="calendar-input-container">
                  {schedule.filter(entry => new Date(entry.day).toISOString().split('T')[0] === new Date(modalData.day).toISOString().split('T')[0]).map((entry, idx) => (
                    <div key={idx}>
                      {entry.allDay ? 'All Day' : `${convertToStandardTime(entry.from)} - ${convertToStandardTime(entry.to)}`}
                      <button type="button" className="calendar-delete-button" onClick={() => deleteEntry(entry.id)}>×</button>
                    </div>
                  ))}
                </div>
                <div className="calendar-input-container" style={{ display: 'flex', alignItems: 'center' }}>
                  <label>All Day:</label>
                  <input type="checkbox" name="allDay" checked={modalData.allDay} onChange={handleModalChange} className="ui-checkbox" />
                </div>
                {!modalData.allDay && (
                  <>
                    <div className="calendar-input-container">
                      <label>From:</label>
                      <input type="time" name="from" value={modalData.from} onChange={handleModalChange} />
                    </div>
                    <div className="calendar-input-container">
                      <label>To:</label>
                      <input type="time" name="to" value={modalData.to} onChange={handleModalChange} />
                    </div>
                    <div className="calendar-input-container">
                      <label>Address:</label>
                      <input type="text" name="address" value={modalData.address} onChange={handleModalChange} />
                    </div>
                    <div className="calendar-input-container">
                      <label>Description:</label>
                      <textarea type="text" name="description" value={modalData.description} onChange={handleModalChange} />
                    </div>
                  </>
                )}
                {errorMessage && <div className="sched-save-error">{errorMessage}</div>}
                <button type="submit" className="calendar-save-button">Save</button>
              </form>
              <div className="daily-schedule">
                {renderModalDayView(new Date(modalData.day))}
              </div>
            </div>
          </div>
        )}
        {showRequestModal && selectedRequests.length > 0 && (
          <div className="request-modal-overlay">
            <div className="request-modal-content">
              <button className="calendar-close-modal-button" onClick={handleCloseRequestModal}>×</button>
              <h2>Incoming Service Requests</h2>
              {selectedRequests.map(request => (
                <div className="request-item" key={request.id}>
                  <p>User: <a href={`/user/${request.user.user}`} target="_blank" rel="noopener noreferrer">{request.user.name || request.user.user}</a></p>
                  <p>Date: <strong>{new Date(new Date(request.requestDate).setDate(new Date(request.requestDate).getDate() + 1)).toLocaleDateString('en-US')}</strong></p>
                  <p>Time: <strong>{convertToStandardTime(request.startTime)}</strong></p>
                  <p>Estimate Duration: <strong>{request.duration} hours</strong></p>
                  <p>Address: <strong>{request.address}</strong></p>
                  <p>Description: {request.description}</p>
                  <button className="view-schedule-button" onClick={() => {
                    setSelectedDate(new Date(request.requestDate));
                    setShowDailySchedule(true);
                  }}>View Schedule</button>
                  <button className="confirm-button" onClick={() => handleRequestAction(request, true)}>Confirm</button>
                  <button className="decline-button" onClick={() => handleRequestAction(request, false)}>Decline</button>
                </div>
              ))}
              {errorMessage && <div className="error-message">{errorMessage}</div>}
            </div>
          </div>
        )}
        {showDailySchedule && (
          <div className="daily-schedule-overlay">
            <div className="daily-schedule-content">
              <button className="calendar-close-modal-button" onClick={() => setShowDailySchedule(false)}>×</button>
              <br></br>
              {renderModalDayView(selectedDate)}
            </div>
          </div>
        )}
        {showMonthPicker && (
          <div className="month-picker" onClick={() => setShowMonthPicker(false)}>
            <div className="month-picker-content" onClick={(e) => e.stopPropagation()}>
              <div className="month-picker-header">
                <button onClick={() => setSelectedDate(new Date(selectedDate.getFullYear() - 1, selectedDate.getMonth(), 1))}> ❮ </button>
                <span>{selectedDate.getFullYear()}</span>
                <button onClick={() => setSelectedDate(new Date(selectedDate.getFullYear() + 1, selectedDate.getMonth(), 1))}> ❯ </button>
              </div>
              <div className="month-picker-grid">
                {Array.from({ length: 12 }).map((_, index) => (
                  <div key={index} className="month-picker-item" onClick={() => handleMonthClick(index)}>
                    {new Date(0, index).toLocaleString('en-US', { month: 'long' })}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default InternalSchedule;
