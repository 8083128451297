import React, { useState } from "react";
import "./CreateProfile.css";
import { Helmet } from 'react-helmet';
import FormHeader from "../../Headers/FormHeader/FormHeader";
import CreateProfileUser from "../CreateProfileUser/CreateProfileUser";
import CreateProfileCompany from "../CreateProfileCompany/CreateProfileCompany";

const CreateProfile = () => {
  const [activeTab, setActiveTab] = useState("");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="create-profile" tabIndex="0">
      <FormHeader />
      <Helmet>
        <title>Create Profile</title>
      </Helmet>
        <div className="create-profile-tabs">
          <button
            className={activeTab === "user" ? "active" : ""}
            onClick={() => handleTabClick("user")}
          >
            User
          </button>
          <button
            className={activeTab === "company" ? "active" : ""}
            onClick={() => handleTabClick("company")}
          >
            Contractor
          </button>
        </div>
        <div className="create-profile-content">
          {activeTab === "user" && <CreateProfileUser />}
          {activeTab === "company" && <CreateProfileCompany />}
        </div>
    </div>
  );
};

export default CreateProfile;
