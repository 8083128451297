import React from 'react';
import InternalCompanyProfile from './InternalCompanyProfile/InternalCompanyProfile';
import ExternalCompanyProfile from './ExternalCompanyProfile/ExternalCompanyProfile';
import { useAuth } from '../../auth/auth';
import { useParams } from 'react-router-dom';

function CompanyProfile() {
  const { user, isOwnProfile } = useAuth();
  const { companyUser } = useParams();

  const isOwn = user && isOwnProfile(companyUser);

  return isOwn ? (
    <InternalCompanyProfile companyName={companyUser} />
  ) : (
    <ExternalCompanyProfile companyName={companyUser} />
  );
}

export default CompanyProfile;
