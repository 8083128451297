import React, { createContext, useContext, useState, useEffect } from 'react';
import { getCurrentUser, signOut, signIn } from 'aws-amplify/auth';
import { getUser, getCompany } from '../graphQL/queries';
import { generateClient } from 'aws-amplify/api';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState('');
  const [userID, setUserID] = useState('');
  const [userType, setUserType] = useState('');
  const client = generateClient();

  const getUserType = async (username) => {
    try {
      const userResponse = await client.graphql({
        query: getUser,
        variables: { id: username },
      });

      if (userResponse.data.getUser) {
        return 'User';
      }

      const companyResponse = await client.graphql({
        query: getCompany,
        variables: { id: username },
      });

      if (companyResponse.data.getCompany) {
        return 'Company';
      }

      return null;
    } catch (error) {
      console.error(`Error fetching user type for username ${username}:`, error);
      return null;
    }
  };

  const fetchUserData = async (authUser) => {
    try {
      const username = authUser.username;
      const type = await getUserType(username);
      let profileData;

      if (type === 'User') {
        const userResponse = await client.graphql({
          query: getUser,
          variables: { id: username },
        });
        profileData = userResponse.data.getUser;
      } else if (type === 'Company') {
        const companyResponse = await client.graphql({
          query: getCompany,
          variables: { id: username },
        });
        profileData = companyResponse.data.getCompany;
      } else {
        console.error(`Unknown user type for username ${username}`);
        return;
      }

      if (profileData) {
        setUser({
          ...authUser,
          ...profileData,
          type: type,
        });
        setUserID(profileData.id);
        setUserType(type);
      } else {
        console.error(`Failed to fetch profile data for username ${username}`);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    const checkAuthState = async () => {
      try {
        const authUser = await getCurrentUser();
        if (authUser) {
          await fetchUserData(authUser);
        } else {
          console.log('User is not signed in.');
        }
      } catch (error) {
      }
    };

    checkAuthState();
  }, []);

  const SignOut = async () => {
    try {
      await signOut();
      setUser('');
      setUserID('');
      setUserType('');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const login = async (email, password) => {
    try {
      const signInResponse = await signIn({ username: email, password });
      const authUser = await getCurrentUser();
      await fetchUserData(authUser);
    } catch (error) {
      console.error('Error signing in:', error);
      throw error;
    }
  };

  const isOwnProfile = (profileUser) => {
    if (userType === 'Company') {
      return user && user.companyUser === profileUser;
    } else {
      return user && user.user === profileUser;
    }
  };  

  return (
    <AuthContext.Provider value={{ user, login, SignOut, userID, userType, isOwnProfile }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
