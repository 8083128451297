import { useState, useEffect } from 'react';

const useGoogleApiKey = () => {
  const [apiKey, setApiKey] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchApiKey = async (retries = 3) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_GATEWAY_URL);
      if (!response.ok) throw new Error('Failed to fetch API key');
      const data = await response.json();
      setApiKey(data.apiKey);
    } catch (err) {
      if (retries > 0) {
        setTimeout(() => fetchApiKey(retries - 1), 1000); // Retry after 1 second
      } else {
        console.error('Error fetching API key:', err);
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApiKey();
  }, []);

  return { apiKey, loading, error };
};

export default useGoogleApiKey;
