import React, { useEffect, useState } from 'react';
import { generateClient } from 'aws-amplify/api';
import FormHeader from '../../../Headers/FormHeader/FormHeader';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from "../../../../auth/auth";
import { Helmet } from 'react-helmet';
import * as queries from '../../../../graphQL/queries';
import * as mutations from '../../../../graphQL/mutations';
import Modal from 'react-modal';
import './CompanyInvoiceZoom.css';

Modal.setAppElement('#root');

const CompanyInvoiceZoom = () => {
  const { invoiceID } = useParams();
  const [invoice, setInvoice] = useState({});
  const [user, setUser] = useState({});
  const { userID } = useAuth();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false);
  const [platformFee, setPlatformFee] = useState(0);
  const [totalReceived, setTotalReceived] = useState(0);
  const client = generateClient();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInvoiceData = async () => {
      try {
        const { data } = await client.graphql({
          query: queries.getInvoices,
          variables: { id: invoiceID }
        });

        if (data.getInvoices) {
          setInvoice({
            ...data.getInvoices,
            status: data.getInvoices.totalAmount === 0 ? 'Complete' : data.getInvoices.status,
          });

          const userData = await client.graphql({
            query: queries.getUser,
            variables: { id: data.getInvoices.userID }
          });

          if (userData.data.getUser) {
            setUser(userData.data.getUser);
          }

          const currentPayment = getCurrentPayment(data.getInvoices);
          if (currentPayment) {
            await calculateCompanyFees(currentPayment.amount);
          }
        }
      } catch (error) {
        console.error('Error fetching invoice data:', error);
      }
    };

    fetchInvoiceData();
  }, [invoiceID, userID, client]);

  const calculateCompanyFees = async (paymentAmountNum) => {
    try {
      const response = await fetch(process.env.REACT_APP_PAYMENT_API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          invoiceAmount: paymentAmountNum,
          type: 'company'  
        }),        
      });

      const result = await response.json();

      if (response.ok) {
        setPlatformFee(result.platformFee);
        setTotalReceived(result.companyReceive);
      } else {
        console.error('Error fetching fee details:', result.error);
      }
    } catch (error) {
      console.error('Error calculating fees:', error);
    }
  };

  const handleCancelInvoice = async () => {
    try {
      await client.graphql({
        query: mutations.deleteInvoices,
        variables: { input: { id: invoice.id } }
      });

      navigate(-1);
    } catch (error) {
      console.error('Error canceling invoice:', error);
      alert('Failed to cancel invoice');
    }
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: '2-digit',
      month: 'numeric',
      day: 'numeric',
    });
  };

  const formatAmount = (amount) => {
    return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  const formatAmountWithCommas = (amount) => {
    if (isNaN(amount)) return '0.00';
    return Number(amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const getCurrentPayment = (invoiceData = invoice) => {
    if (invoiceData.oneTimePayment) {
      const dueDate = new Date(invoiceData.dueDates[0]);
      dueDate.setDate(dueDate.getDate() + 1);
  
      return {
        amount: invoiceData.totalAmount,
        dueDate: formatDate(dueDate)
      };
    }
  
    if (invoiceData.payments && Array.isArray(invoiceData.payments)) {
      const nextPaymentIndex = invoiceData.payments.findIndex((payment, index) => new Date(invoiceData.dueDates[index]) > new Date());
      if (nextPaymentIndex !== -1) {
        return {
          amount: invoiceData.payments[nextPaymentIndex],
          dueDate: formatDate(invoiceData.dueDates[nextPaymentIndex])
        };
      }
    }
  
    return null;
  };  

  const getNextPayments = () => {
    if (invoice.oneTimePayment || !invoice.payments || !Array.isArray(invoice.payments)) {
      return [];
    }

    const nextPaymentIndex = invoice.payments.findIndex((payment, index) => new Date(invoice.dueDates[index]) > new Date());
    if (nextPaymentIndex !== -1) {
      return invoice.payments.slice(nextPaymentIndex + 1).map((payment, index) => ({
        amount: payment,
        dueDate: formatDate(invoice.dueDates[nextPaymentIndex + 1 + index]),
      }));
    }

    return [];
  };

  const getCompletedPayments = () => {
    if (!invoice.paymentDate || !invoice.resendPayments || !Array.isArray(invoice.paymentDate)) {
      return [];
    }

    return invoice.paymentDate.map((paymentDate, index) => {
      const paymentAmount = invoice.resendPayments[index]
        ? invoice.resendPayments[index] - (invoice.resendPayments[index] * 0.03)
        : 0;

      const dueDate = invoice.dueDateLog && invoice.dueDateLog[index] ? invoice.dueDateLog[index] : 'N/A';
      const formattedPaymentDate = new Date(paymentDate).toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
      });

      const isLate = new Date(paymentDate) > new Date(dueDate).setHours(23, 59, 59, 999);
      const statusIcon = isLate ? '/icons/xmark.png' : '/icons/check.png';

      return (
        <tr key={index}>
          <td>${formatAmountWithCommas(paymentAmount)}</td>
          <td>{formattedPaymentDate}</td>
          <td>{dueDate !== 'N/A' ? formatDate(dueDate) : 'N/A'}</td>
          <td>
            <img src={statusIcon} alt={isLate ? 'Late' : 'On Time'} width={20} height={20} />
          </td>
        </tr>
      );
    });
  };

  const currentPayment = getCurrentPayment();
  const nextPayments = getNextPayments();
  const completedPayments = getCompletedPayments();
  const paymentAmountNum = currentPayment ? currentPayment.amount : 0;

  return (
    <div className="invoice-zoom-full-page">
      <FormHeader />
      <Helmet>
        <title>{`Invoice to ${user.name || user.user}`}</title>
      </Helmet>
      <div className="invoice-zoom-container">
        <div className="invoice-zoom-header">
          <div className="invoice-zoom-company-details">
            <div className="invoice-zoom-company-profile">
              {user.profilepicturekey && (
                <img src={`https://media.spiggl.com/public/${user.profilepicturekey}`} alt={user.name} />
              )}
              <div className="invoice-zoom-company-name">
                {user.name || user.user ? (
                  <a
                    href={`/user/${user.user}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="company-link"
                  >
                    {user.name || user.user}
                  </a>
                ) : (
                  user.name || user.user
                )}
              </div>
            </div>
          </div>
          <div className="invoice-zoom-total-amount-wrapper">
            <div className="invoice-zoom-total-amount">
              Total: $
              {invoice.amount ? formatAmountWithCommas(invoice.amount) : '0.00'}
            </div>
            <div className="invoice-zoom-total-amount">
              Remaining: $
              {invoice.totalAmount ? formatAmountWithCommas(invoice.totalAmount) : '0.00'}
            </div>
          </div>
        </div>
        <div className="invoice-zoom-sections">
          <div className="invoice-zoom-left">
            <div className="invoice-zoom-content">
              <div className="invoice-zoom-content-header">Current Payment</div>
              {paymentAmountNum === 0 ? (
                <div className="no-more-payments">No more payments due</div>
              ) : (
                <>
                  {currentPayment?.dueDate && <div className="invoice-zoom-due-date">Due: {currentPayment.dueDate}</div>}
                  <div className="payment-details">
                    <div><span>Payment Amount:</span><span>{formatAmount(paymentAmountNum)}</span></div>
                    <div><span>Service:</span><span>{formatAmount(platformFee)}</span></div>
                    <div><span>Total Received:</span><span>{formatAmount(totalReceived)}</span></div>
                  </div>
                </>
              )}
            </div>
            <div className="invoice-zoom-payment-schedule">
              <div className="invoice-zoom-payment-schedule-title">Payment Schedule</div>
              {nextPayments.length > 0 ? (
                <table className="invoice-zoom-payment-schedule-grid">
                  <thead>
                    <tr>
                      <th>Amount</th>
                      <th>Due</th>
                    </tr>
                  </thead>
                  <tbody>
                    {nextPayments.map((payment, index) => (
                      <tr key={index}>
                        <td>{formatAmount(payment.amount)}</td>
                        <td>{payment.dueDate}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="no-schedule">
                  <p>No Schedule Available</p>
                  <img src="/icons/schedule.png" alt="No Schedule" />
                </div>
              )}
            </div>
            {completedPayments.length > 0 && (
              <div className="completed-payments-section">
                <div className="completed-payments-title">Completed Payments</div>
                <table className="invoice-zoom-payment-schedule-grid">
                  <thead>
                    <tr>
                      <th>Amount</th>
                      <th>Payment Date</th>
                      <th>Due Date</th>
                      <th>On Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {completedPayments}
                  </tbody>
                </table>
              </div>
            )}
            {invoice.status !== 'Complete' && (
              <button className="zoom-current-invoice-cancel-button" onClick={() => setCancelModalIsOpen(true)}>Cancel Invoice</button>
            )}
          </div>
          <div className="invoice-zoom-right">
            <div className="invoice-zoom-invoice-file">
              {invoice.invoiceAttachmentKey ? (
                <embed src={`https://media.spiggl.com/public/${invoice.invoiceAttachmentKey}`} type="application/pdf" width="100%" height="400px" />
              ) : (
                <div className="no-invoice">
                  <p>No Paper Invoice Available</p>
                </div>
              )}
              {invoice.invoiceAttachmentKey && (
                <div className="invoice-zoom-buttons">
                  <button className="expand-button" onClick={() => setModalIsOpen(true)}>Expand</button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="invoice-modal"
        overlayClassName="invoice-modal-overlay"
      >
        <button className="close-modal-button" onClick={() => setModalIsOpen(false)}>Close</button>
        <div className="invoice-modal-content">
          {invoice.invoiceAttachmentKey ? (
            <embed src={`https://media.spiggl.com/public/${invoice.invoiceAttachmentKey}`} type="application/pdf" width="100%" height="600px" />
          ) : (
            <p>No Invoice Available</p>
          )}
        </div>
      </Modal>
      <Modal
        isOpen={cancelModalIsOpen}
        onRequestClose={() => setCancelModalIsOpen(false)}
        className="confirm-payment-modal-content"
        overlayClassName="confirm-payment-modal-overlay"
      >
        <div className="confirm-payment-modal-content">
          <h2>Cancel Entire Invoice?</h2>
          <div className="confirm-payment-modal-actions">
            <button className="confirm-payment-confirm-button" onClick={handleCancelInvoice}>Confirm</button>
            <button className="confirm-payment-cancel-button" onClick={() => setCancelModalIsOpen(false)}>Cancel</button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CompanyInvoiceZoom;
