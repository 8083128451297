import React from 'react';
import InternalSchedule from './InternalSchedule/InternalSchedule';
import ExternalSchedule from './ExternalSchedule/ExternalSchedule';
import { useAuth } from '../../../auth/auth';
import { useParams } from 'react-router-dom';

function Schedule() {
  const { user, isOwnProfile } = useAuth();
  const { companyUser } = useParams();

  const isOwn = user && isOwnProfile(companyUser);

  return isOwn ? (
    <InternalSchedule companyName={companyUser} />
  ) : (
    <ExternalSchedule companyName={companyUser} />
  );
}

export default Schedule;
