import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import Skeleton from 'react-loading-skeleton';
import FormHeader from '../../../Headers/FormHeader/FormHeader';
import { useAuth } from '../../../../auth/auth';
import { generateClient } from 'aws-amplify/api';
import { Helmet } from 'react-helmet';
import * as mutations from '../../../../graphQL/mutations';
import * as queries from '../../../../graphQL/queries';
import './PaymentMethod.css';

const PaymentMethod = ({ loading }) => {
  const { userID } = useAuth();
  const stripe = useStripe();
  const elements = useElements();
  const [nameOnCard, setNameOnCard] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const client = generateClient();

  const handleAddPaymentMethod = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
  
    const cardElement = elements.getElement(CardElement);
  
    try {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: nameOnCard,
        },
      });
  
      if (error) {
        setError(error.message);
        return;
      }
  
      const { data: cardData } = await client.graphql({
        query: queries.paymentMethodsByUserID,
        variables: { userID: userID }
      });
  
      const paymentMethods = cardData.paymentMethodsByUserID.items;
  
      const activeMethod = paymentMethods.find(method => method.isActive);
  
      if (activeMethod) {
        await client.graphql({
          query: mutations.updatePaymentMethod,
          variables: { input: { id: activeMethod.id, isActive: false } }
        });
      }
  
      const input = {
        stripeCardToken: paymentMethod.id,
        nameOnCard,
        userID,
        brand: paymentMethod.card.brand,
        isActive: true,
        last4: paymentMethod.card.last4,
        expMonth: paymentMethod.card.exp_month.toString(),
        expYear: paymentMethod.card.exp_year.toString(),
      };
  
      await client.graphql({
        query: mutations.createPaymentMethod,
        variables: { input },
      });
  
      setError('');
      navigate(-1);
    } catch (err) {
      console.error('Error adding payment method:', err);
      setError('There was an error processing your card, please try again.');
    }
  };

  return (
    <div className="payment">
      <FormHeader />
      <Helmet>
        <title>Add card</title>
      </Helmet>
      {loading ? (
        <div className="create-payment-method">
          <Skeleton height={40} width={'80%'} style={{ marginBottom: '15px' }} />
          <Skeleton height={40} width={'80%'} style={{ marginBottom: '15px' }} />
          <Skeleton height={40} width={'80%'} style={{ marginBottom: '15px' }} />
          <Skeleton height={40} width={'60%'} />
        </div>
      ) : (
        <form className="create-payment-method" onSubmit={handleAddPaymentMethod}>
          <h2>Add Card</h2>
          <input
            type="text"
            placeholder="Name on Card"
            value={nameOnCard}
            onChange={(e) => setNameOnCard(e.target.value)}
            required
          />
          <CardElement className="card-element" />
          {error && <div className="error">{error}</div>}
          <button className="create-payment-method-btn" disabled={!stripe}>
            Add Payment Method
          </button>
        </form>
      )}
    </div>
  );
};

export default PaymentMethod;
