/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://za43ztvjcre7rdjy5fzxwzr7fq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-nfxyjorrrretzjei4cuqmq7lv4",
    "aws_cognito_identity_pool_id": "us-east-1:bb8fcb8d-21a3-4b4e-b990-701ea4dc4ac1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_WOKNcxqxl",
    "aws_user_pools_web_client_id": "3t5jgs01pri5u3o902oid38sru",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "7",
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "bluebucketa4254-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
