import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import useStripeKey from '../useStripeKey';
import LinkBank from './LinkBank/LinkBank';

function Bank() {
  const { apiKey } = useStripeKey();
  const appearance = {
    theme: 'stripe',
  };
  return (
    <div className="App">
      <Elements stripe={loadStripe(apiKey)} options={{ appearance }}>
        <LinkBank />
      </Elements>
    </div>
  );
}
export default Bank;