import React, { useEffect, useState } from 'react';
import { generateClient } from 'aws-amplify/api';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../auth/auth';
import * as queries from '../../../../graphQL/queries';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './ProfileInvoice.css';

const ProfileInvoice = () => {
  const { userID } = useAuth();
  const [companyData, setCompanyData] = useState({});
  const [bankMethod, setBankMethod] = useState(null);
  const [profilePicture, setProfilePicture] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const client = generateClient();

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const { data } = await client.graphql({
          query: queries.getCompany,
          variables: { id: userID },
        });

        if (data.getCompany) {
          setCompanyData(data.getCompany);
          if (data.getCompany.profilepicturekey) {
            setProfilePicture(
              `https://media.spiggl.com/public/${data.getCompany.profilepicturekey}`
            );
          } else {
            setProfilePicture(createDefaultProfileImage(data.getCompany.companyUser));
          }

          const bankMethodData = await client.graphql({
            query: queries.bankMethodsByCompanyID,
            variables: { companyID: userID },
          });
          setBankMethod(bankMethodData.data.bankMethodsByCompanyID.items[0]);
        } else {
          console.error('Company not found with id:', userID);
        }
      } catch (error) {
        console.error('Error fetching company data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (userID) {
      fetchCompanyData();
    } else {
      console.error('No user ID provided.');
    }
  }, [userID, client]);

  const handleSendInvoiceClick = () => {
    navigate(`/contractor/${companyData.companyUser}/invoice-dash/new-invoice`);
  };

  const handleAddBankClick = () => {
    navigate(`/contractor/${companyData.companyUser}/invoice-dash/link-bank`);
  };

  const handleChangeBankClick = () => {
    navigate(`/contractor/${companyData.companyUser}/invoice-dash/update-bank`);
  };

  const createDefaultProfileImage = (user) => {
    const firstChar = user ? user.charAt(0).toUpperCase() : '';
    return `data:image/svg+xml;base64,${btoa(`<svg xmlns="http://www.w3.org/2000/svg" width="150" height="150">
      <circle cx="75" cy="75" r="75" fill="#23313d"/>
      <text x="50%" y="50%" font-family="Arial, sans-serif" font-size="64" dy=".3em" fill="#FFF" text-anchor="middle">${firstChar}</text>
    </svg>`)}`;
  };

  return (
    <div className="profile-invoice">
      <div className="profile-invoice-header">
        <div className="user-invoice-profile">
          {loading ? (
            <Skeleton circle={true} height={150} width={150} />
          ) : (
            <img src={profilePicture} alt="Profile" />
          )}
          <div className="user-name">
            {loading ? <Skeleton width={200} /> : `${companyData.companyName || companyData.companyUser}`}
          </div>
        </div>
        <button className="send-invoice-button" onClick={handleSendInvoiceClick}>Send Invoice</button>
        <div className="bank-info">
          {!bankMethod ? (
            <>
              <span className="link-bank-statement">Link Bank Account</span>
              <button className="link-bank-button" onClick={handleAddBankClick}>Link Account</button>
            </>
          ) : (
            <>
              <div className="bank-method">{`Account ending in ${bankMethod.last4}`}</div>
              <button className="change-bank-button" onClick={handleChangeBankClick}>Change Bank Info</button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileInvoice;
