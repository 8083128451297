import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../auth/auth';
import SignInModal from '../../../auth/SignInModal/SignInModal';
import { useNavigate } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import * as queries from '../../../graphQL/queries';
import MessagesModal from "../../Syn/Messages/MessagesModal";
import './FormHeader.css';

const FormHeader = () => {
  const { user, userType, SignOut, userID } = useAuth();
  const navigate = useNavigate();
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [profilePictureKey, setProfilePictureKey] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [showMessagesModal, setShowMessagesModal] = useState(false);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [hasUnreadInvoices, setHasUnreadInvoices] = useState(false);
  const [hasPendingRequests, setHasPendingRequests] = useState(false);
  const client = generateClient();

  useEffect(() => {
    if (user) {
      fetchProfilePicture();
      fetchUnreadMessages();
      fetchUnreadInvoices();
      fetchPendingRequests();
      setShowSignInModal(false);
    }
  }, [user]);

  const fetchProfilePicture = async () => {
    try {
      let profileKey = '';
      if (userType === 'Company') {
        const { data } = await client.graphql({
          query: queries.getCompany,
          variables: { id: userID },
        });
        profileKey = data.getCompany.profilepicturekey || '';
      } else {
        const { data } = await client.graphql({
          query: queries.getUser,
          variables: { id: userID },
        });
        profileKey = data.getUser.profilepicturekey || '';
      }
      setProfilePictureKey(profileKey);
    } catch (error) {
      console.error('Error fetching profile picture:', error);
    }
  };

  const fetchUnreadMessages = async () => {
    try {
      const result = await client.graphql({
        query: queries.listMessages,
        variables: { filter: { receiverID: { eq: userID }, readStatus: { eq: false } } },
      });
      const unreadMessages = result.data.listMessages.items;
      setHasUnreadMessages(unreadMessages.length > 0);
    } catch (error) {
      console.error('Error fetching unread messages:', error);
    }
  };

  const fetchUnreadInvoices = async () => {
    try {
      const result = await client.graphql({
        query: queries.invoicesByUserID,
        variables: { userID: userID },
      });
      const unreadInvoices = result.data.invoicesByUserID.items.some(invoice => !invoice.readStatus);
      setHasUnreadInvoices(unreadInvoices);
    } catch (error) {
      console.error('Error fetching unread invoices:', error);
    }
  };

  const fetchPendingRequests = async () => {
    try {
      const result = await client.graphql({
        query: queries.requestsByCompanyID,
        variables: { companyID: userID, filter: { isPending: { eq: true } } },
      });
      const pendingRequests = result.data.requestsByCompanyID.items;
      setHasPendingRequests(pendingRequests.length > 0);
    } catch (error) {
      console.error('Error fetching pending requests:', error);
    }
  };

  const createDefaultProfileImage = (username) => {
    const firstChar = username ? username.charAt(0).toUpperCase() : '';
    return `data:image/svg+xml;base64,${btoa(`<svg xmlns="http://www.w3.org/2000/svg" width="150" height="150">
      <circle cx="75" cy="75" r="75" fill="#23313d"/>
      <text x="50%" y="50%" font-family="Arial, sans-serif" font-size="64" dy=".3em" fill="#FFF" text-anchor="middle">${firstChar}</text>
    </svg>`)}`;
  };

  const toggleSignInModal = () => {
    setShowSignInModal(!showSignInModal);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const goToHome = () => {
    navigate('/');
  };

  const handleLogout = async () => {
    await SignOut();
    navigate('/');
    setShowDropdown(false);
  };

  const handleGoToProfile = () => {
    const profilePath = userType === 'Company'
      ? `/contractor/${user.companyUser}`
      : `/user/${user.user}`;
    navigate(profilePath);
    setShowDropdown(false);
  };

  const handleGoToInvoices = () => {
    const invoicePath = userType === 'Company'
      ? `/contractor/${user.companyUser}/invoice-dash`
      : `/user/${user.user}/invoice-dash`;
    navigate(invoicePath);
    setShowDropdown(false);
  };

  const handleGoToMessages = () => {
    setShowMessagesModal(true);
    setShowDropdown(false);
  };

  const handleCalendarClick = () => {
    navigate(`/contractor/${user.companyUser}/calendar`);
    setShowDropdown(false);
  };

  const toggleMessagesModal = () => {
    setShowMessagesModal(!showMessagesModal);
  };

  const profileImageSrc = profilePictureKey
    ? `https://media.spiggl.com/public/${profilePictureKey}`
    : userType === 'Company'
      ? createDefaultProfileImage(user.companyUser)
      : createDefaultProfileImage(user.user);

  return (
    <header className="Header">
      <div className='form-header-width'>
        <div className="brand" onClick={goToHome}>
          <img src='/favicon.png' alt="Company Logo" className="header-logo" />
          <span className="brand-name">Spiggl</span>
        </div>
        <nav className="user-nav">
          {user ? (
            <div className="profile-button" onClick={toggleDropdown}>
              <img
                src={profileImageSrc}
                alt="Profile"
                className="profile-picture"
              />
              {(hasUnreadMessages || hasUnreadInvoices || hasPendingRequests) && <div className="header-unread-indicator" />}
              {showDropdown && (
                <div className="header-dropdown-menu">
                  <div className="dropdown-profile" onClick={handleGoToProfile}>
                    <img
                      src={profileImageSrc}
                      alt="Profile"
                      className="dropdown-profile-picture"
                    />
                    <div className="dropdown-profile-text">
                      <span>View Profile</span>
                      <span className="dropdown-username">{userType === 'Company' ? user.companyUser : user.user}</span>
                    </div>
                  </div>
                  <ul>
                    <li onClick={handleGoToInvoices}>
                      <img src="/icons/invoice.png" alt="Invoices" />
                      Invoices
                      {hasUnreadInvoices && <div className="invoices-unread-indicator" />}
                    </li>
                    <li onClick={handleGoToMessages}>
                      <img src="/icons/message.png" alt="Messages" />
                      Messages
                      {hasUnreadMessages && <div className="messages-unread-indicator" />}
                    </li>
                    {userType === 'Company' && (
                      <li onClick={handleCalendarClick}>
                        <img src="/icons/schedule.png" alt="Schedule" />
                        Schedule
                        {hasPendingRequests && <div className="schedule-unread-indicator" />}
                      </li>
                    )}
                    <li onClick={handleLogout}>
                      <img src="/icons/logout.png" alt="Logout" />
                      Log Out
                    </li>
                  </ul>
                </div>
              )}
            </div>
          ) : (
            <button className="header-nav-button sign-in-button" onClick={toggleSignInModal}>
              Log In
            </button>
          )}
        </nav>
        {showSignInModal && <SignInModal onClose={toggleSignInModal} />}
        {showMessagesModal && <MessagesModal userID={userID} onClose={toggleMessagesModal} />}
      </div>
    </header>
  );
};

export default FormHeader;
