import React from 'react';
import './UserNotifications.css';
import { generateClient } from "aws-amplify/api";
import { updateUser } from '../../../graphQL/mutations';

const UserNotifications = ({ 
  messageNotifications, 
  setMessageNotifications, 
  paymentNotifications, 
  setPaymentNotifications, 
  requestSatusNotifications, 
  setRequestSatusNotifications, 
  invoiceNotifications, 
  setInvoiceNotifications,
  pushMessageNotifications, 
  setPushMessageNotifications, 
  pushPaymentNotifications, 
  setPushPaymentNotifications, 
  pushRequestSatusNotifications, 
  setPushRequestSatusNotifications, 
  pushInvoiceNotifications, 
  setPushInvoiceNotifications,
  userId 
  }) => {
  const client = generateClient();

  const handleNotificationChange = async (type, value) => {
    switch (type) {
      case 'messageNotifications':
        setMessageNotifications(value);
        break;
      case 'paymentNotifications':
        setPaymentNotifications(value);
        break;
      case 'requestSatusNotifications':
        setRequestSatusNotifications(value);
        break;
      case 'invoiceNotifications':
        setInvoiceNotifications(value);
        break;
      case 'pushMessageNotifications':
        setPushMessageNotifications(value);
        break;
      case 'pushPaymentNotifications':
        setPushPaymentNotifications(value);
        break;
      case 'pushRequestSatusNotifications':
        setPushRequestSatusNotifications(value);
        break;
      case 'pushInvoiceNotifications':
        setPushInvoiceNotifications(value);
        break;
      default:
        break;
    }

    try {
      await client.graphql({
        query: updateUser,
        variables: {
          input: {
            id: userId,
            [type]: value
          }
        }
      });
    } catch (error) {
      console.error(`Error updating ${type}:`, error);
    }
  };

  return (
    <div className="notifications-container">
      <h3>Email Notifications</h3>
      <div className="notifications-option">
        <h4>Message Notifications</h4>
        <div className="radio-group">
          <label>
            <input 
              type="radio" 
              name="messageNotifications" 
              checked={messageNotifications} 
              onChange={() => handleNotificationChange('messageNotifications', true)} 
            />
            On
          </label>
          <label>
            <input 
              type="radio" 
              name="messageNotifications" 
              checked={!messageNotifications} 
              onChange={() => handleNotificationChange('messageNotifications', false)} 
            />
            Off
          </label>
        </div>
        <p>Receive notifications about new messages.</p>
      </div>
      
      <hr />

      <div className="notifications-option">
        <h4>Payment Notifications</h4>
        <div className="radio-group">
          <label>
            <input 
              type="radio" 
              name="paymentNotifications" 
              checked={paymentNotifications} 
              onChange={() => handleNotificationChange('paymentNotifications', true)} 
            />
            On
          </label>
          <label>
            <input 
              type="radio" 
              name="paymentNotifications" 
              checked={!paymentNotifications} 
              onChange={() => handleNotificationChange('paymentNotifications', false)} 
            />
            Off
          </label>
        </div>
        <p>Receive notifications about payments made.</p>
      </div>
      
      <hr />

      <div className="notifications-option">
        <h4>Service Request Status Notifications</h4>
        <div className="radio-group">
          <label>
            <input 
              type="radio" 
              name="requestSatusNotifications" 
              checked={requestSatusNotifications} 
              onChange={() => handleNotificationChange('requestSatusNotifications', true)} 
            />
            On
          </label>
          <label>
            <input 
              type="radio" 
              name="requestSatusNotifications" 
              checked={!requestSatusNotifications} 
              onChange={() => handleNotificationChange('requestSatusNotifications', false)} 
            />
            Off
          </label>
        </div>
        <p>Receive updates on the status of your service requests.</p>
      </div>
      
      <hr />

      <div className="notifications-option">
        <h4>Invoice Notifications</h4>
        <div className="radio-group">
          <label>
            <input 
              type="radio" 
              name="invoiceNotifications" 
              checked={invoiceNotifications} 
              onChange={() => handleNotificationChange('invoiceNotifications', true)} 
            />
            On
          </label>
          <label>
            <input 
              type="radio" 
              name="invoiceNotifications" 
              checked={!invoiceNotifications} 
              onChange={() => handleNotificationChange('invoiceNotifications', false)} 
            />
            Off
          </label>
        </div>
        <p>Get updates when a new invoice is available.</p>
      </div>

      <h3>Push Notifications</h3>
      <div className="notifications-option">
        <h4>Message Notifications</h4>
        <div className="radio-group">
          <label>
            <input 
              type="radio" 
              name="pushMessageNotifications" 
              checked={pushMessageNotifications} 
              onChange={() => handleNotificationChange('pushMessageNotifications', true)} 
            />
            On
          </label>
          <label>
            <input 
              type="radio" 
              name="pushMessageNotifications" 
              checked={!pushMessageNotifications} 
              onChange={() => handleNotificationChange('pushMessageNotifications', false)} 
            />
            Off
          </label>
        </div>
        <p>Receive push notifications about new messages.</p>
      </div>
      
      <hr />

      <div className="notifications-option">
        <h4>Payment Notifications</h4>
        <div className="radio-group">
          <label>
            <input 
              type="radio" 
              name="pushPaymentNotifications" 
              checked={pushPaymentNotifications} 
              onChange={() => handleNotificationChange('pushPaymentNotifications', true)} 
            />
            On
          </label>
          <label>
            <input 
              type="radio" 
              name="pushPaymentNotifications" 
              checked={!pushPaymentNotifications} 
              onChange={() => handleNotificationChange('pushPaymentNotifications', false)} 
            />
            Off
          </label>
        </div>
        <p>Receive push notifications about payments made.</p>
      </div>
      
      <hr />

      <div className="notifications-option">
        <h4>Service Request Status Notifications</h4>
        <div className="radio-group">
          <label>
            <input 
              type="radio" 
              name="pushRequestSatusNotifications" 
              checked={pushRequestSatusNotifications} 
              onChange={() => handleNotificationChange('pushRequestSatusNotifications', true)} 
            />
            On
          </label>
          <label>
            <input 
              type="radio" 
              name="pushRequestSatusNotifications" 
              checked={!pushRequestSatusNotifications} 
              onChange={() => handleNotificationChange('pushRequestSatusNotifications', false)} 
            />
            Off
          </label>
        </div>
        <p>Receive updates on the status of your service requests via push notifications.</p>
      </div>
      
      <hr />

      <div className="notifications-option">
        <h4>Invoice Notifications</h4>
        <div className="radio-group">
          <label>
            <input 
              type="radio" 
              name="pushInvoiceNotifications" 
              checked={pushInvoiceNotifications} 
              onChange={() => handleNotificationChange('pushInvoiceNotifications', true)} 
            />
            On
          </label>
          <label>
            <input 
              type="radio" 
              name="pushInvoiceNotifications" 
              checked={!pushInvoiceNotifications} 
              onChange={() => handleNotificationChange('pushInvoiceNotifications', false)} 
            />
            Off
          </label>
        </div>
        <p>Get updates when a new invoice is available via push notifications.</p>
      </div>
    </div>
  );
};

export default UserNotifications;
