import React from 'react';
import './CompanyNotifications.css';
import { generateClient } from "aws-amplify/api";
import { updateCompany } from '../../../graphQL/mutations';

const CompanyNotifications = ({ 
  messageNotifications, 
  setMessageNotifications, 
  paymentNotifications, 
  setPaymentNotifications, 
  requestNotifications, 
  setRequestNotifications, 
  pushMessageNotifications, 
  setPushMessageNotifications, 
  pushPaymentNotifications, 
  setPushPaymentNotifications, 
  pushRequestNotifications, 
  setPushRequestNotifications,
  companyId 
  }) => {
  const client = generateClient();

  const handleNotificationChange = async (type, value) => {
    switch (type) {
      case 'messageNotifications':
        setMessageNotifications(value);
        break;
      case 'paymentNotifications':
        setPaymentNotifications(value);
        break;
      case 'requestNotifications':
        setRequestNotifications(value);
        break;
      case 'pushMessageNotifications':
        setPushMessageNotifications(value);
        break;
      case 'pushPaymentNotifications':
        setPushPaymentNotifications(value);
        break;
      case 'pushRequestNotifications':
        setPushRequestNotifications(value);
        break;
      default:
        break;
    }

    try {
      await client.graphql({
        query: updateCompany,
        variables: {
          input: {
            id: companyId,
            [type]: value
          }
        }
      });
    } catch (error) {
      console.error(`Error updating ${type}:`, error);
    }
  };

  return (
    <div className="notifications-container">
      <h3>Email Notifications</h3>
      <div className="notifications-option">
        <h4>Message Notifications</h4>
        <div className="radio-group">
          <label>
            <input 
              type="radio" 
              name="messageNotifications" 
              checked={messageNotifications} 
              onChange={() => handleNotificationChange('messageNotifications', true)} 
            />
            On
          </label>
          <label>
            <input 
              type="radio" 
              name="messageNotifications" 
              checked={!messageNotifications} 
              onChange={() => handleNotificationChange('messageNotifications', false)} 
            />
            Off
          </label>
        </div>
        <p>Receive notifications about new messages.</p>
      </div>
      
      <hr />

      <div className="notifications-option">
        <h4>Payment Notifications</h4>
        <div className="radio-group">
          <label>
            <input 
              type="radio" 
              name="paymentNotifications" 
              checked={paymentNotifications} 
              onChange={() => handleNotificationChange('paymentNotifications', true)} 
            />
            On
          </label>
          <label>
            <input 
              type="radio" 
              name="paymentNotifications" 
              checked={!paymentNotifications} 
              onChange={() => handleNotificationChange('paymentNotifications', false)} 
            />
            Off
          </label>
        </div>
        <p>Receive notifications about payments received.</p>
      </div>
      
      <hr />

      <div className="notifications-option">
        <h4>Service Request Notifications</h4>
        <div className="radio-group">
          <label>
            <input 
              type="radio" 
              name="requestNotifications" 
              checked={requestNotifications} 
              onChange={() => handleNotificationChange('requestNotifications', true)} 
            />
            On
          </label>
          <label>
            <input 
              type="radio" 
              name="requestNotifications" 
              checked={!requestNotifications} 
              onChange={() => handleNotificationChange('requestNotifications', false)} 
            />
            Off
          </label>
        </div>
        <p>Receive notifications for new service requests.</p>
      </div>

      <h3>Push Notifications</h3>
      <div className="notifications-option">
        <h4>Message Notifications</h4>
        <div className="radio-group">
          <label>
            <input 
              type="radio" 
              name="pushMessageNotifications" 
              checked={pushMessageNotifications} 
              onChange={() => handleNotificationChange('pushMessageNotifications', true)} 
            />
            On
          </label>
          <label>
            <input 
              type="radio" 
              name="pushMessageNotifications" 
              checked={!pushMessageNotifications} 
              onChange={() => handleNotificationChange('pushMessageNotifications', false)} 
            />
            Off
          </label>
        </div>
        <p>Receive push notifications about new messages.</p>
      </div>
      
      <hr />

      <div className="notifications-option">
        <h4>Payment Notifications</h4>
        <div className="radio-group">
          <label>
            <input 
              type="radio" 
              name="pushPaymentNotifications" 
              checked={pushPaymentNotifications} 
              onChange={() => handleNotificationChange('pushPaymentNotifications', true)} 
            />
            On
          </label>
          <label>
            <input 
              type="radio" 
              name="pushPaymentNotifications" 
              checked={!pushPaymentNotifications} 
              onChange={() => handleNotificationChange('pushPaymentNotifications', false)} 
            />
            Off
          </label>
        </div>
        <p>Receive push notifications about payments received.</p>
      </div>
      
      <hr />

      <div className="notifications-option">
        <h4>Service Request Notifications</h4>
        <div className="radio-group">
          <label>
            <input 
              type="radio" 
              name="pushRequestNotifications" 
              checked={pushRequestNotifications} 
              onChange={() => handleNotificationChange('pushRequestNotifications', true)} 
            />
            On
          </label>
          <label>
            <input 
              type="radio" 
              name="pushRequestNotifications" 
              checked={!pushRequestNotifications} 
              onChange={() => handleNotificationChange('pushRequestNotifications', false)} 
            />
            Off
          </label>
        </div>
        <p>Receive updates on the status of your service requests via push notifications.</p>
      </div>
    </div>
  );
};

export default CompanyNotifications;
