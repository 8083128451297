const contractorTypes = [
  { label: "Electrician", value: "Electrician" },
  { label: "Plumbing", value: "Plumbing" },
  { label: "HVAC", value: "HVAC" },
  { label: "Landscaping", value: "Landscaping" },
  { label: "Handyman", value: "Handyman" },
  { label: "Remodeling", value: "Remodeling" },
  { label: "Roofing", value: "Roofing" },
  { label: "Painting", value: "Painting" },
  { label: "Flooring", value: "Flooring" },
  { label: "Carpentry", value: "Carpentry" },
  { label: "Drywall", value: "Drywall" },
  { label: "Home Cleaning", value: "Home Cleaning" },
  { label: "Window and Door", value: "Window and Door" },
  { label: "Home Inspection", value: "Home Inspection" },
  { label: "Concrete", value: "Concrete" },
  { label: "Fencing", value: "Fencing" },
  { label: "General", value: "General" },
  { label: "Garage Doors", value: "Garage Doors" },
  { label: "Home Security", value: "Home Security" },
  { label: "Appliance Repair", value: "Appliance Repair" },
  { label: "Moving Services", value: "Moving Services" },
  { label: "Gutter", value: "Gutter" },
  { label: "Masonry", value: "Masonry" },
  { label: "Pest Control", value: "Pest Control" },
  { label: "Chimney", value: "Chimney" },
  { label: "Home Building", value: "Home Building" },
  { label: "Home Automation", value: "Home Automation" },
  { label: "Interior Design", value: "Interior Design" },
  { label: "Architect", value: "Architect" },
  { label: "Septic System", value: "Septic System" },
  { label: "Solar", value: "Solar" },
  { label: "Water Treatment", value: "Water Treatment" },
  { label: "Well Drilling and Pump", value: "Well Drilling and Pump" },
  { label: "Pool and Spa", value: "Pool and Spa" },
  { label: "Fire Protection", value: "Fire Protection" },
  { label: "Home Staging", value: "Home Staging" },
  { label: "Audio/Visual Installation", value: "Audio/Visual Installation" },
  { label: "Carpet Cleaning", value: "Carpet Cleaning" },
  { label: "Demolition", value: "Demolition" },
  { label: "Insulation", value: "Insulation" },
  { label: "Window Treatment", value: "Window Treatment" },
  { label: "Other", value: "Other" }
];

export default contractorTypes;