import React, { useState, useEffect, useRef } from "react";
import "./InternalCompanyHighlights.css";
import { generateClient } from "aws-amplify/api";
import { highlightsByCompanyID } from "../../../../graphQL/queries";
import { deleteHighlight } from '../../../../graphQL/mutations';
import ImageModal from "../../../Syn/ImageModal/ImageModal";
import EditHighlightModal from '../EditHighlightModal/EditHighlightModal';
import DeleteConfirmationModal from "../../../Syn/DeleteConfirmationModal/DeleteConfirmationModal";
import { format } from 'date-fns';

const InternalCompanyHighlights = ({ companyID }) => {
  const [highlights, setHighlights] = useState([]);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [currentAttachments, setCurrentAttachments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showMenu, setShowMenu] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [highlightToDelete, setHighlightToDelete] = useState(null);
  const [editHighlight, setEditHighlight] = useState(null);
  const highlightsPerPage = 10;
  const client = generateClient();
  const menuRefs = useRef({});

  const openModal = (index, attachments) => {
    setSelectedImageIndex(index);
    setCurrentAttachments(attachments);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const fetchHighlights = async () => {
    if (companyID) {
      try {
        const highlightData = await client.graphql({
          query: highlightsByCompanyID,
          variables: { companyID: companyID }
        });

        const highlightsWithImageUrls = highlightData.data.highlightsByCompanyID.items.map((highlight) => ({
          ...highlight,
          attachmentUrls: highlight.attachmentkey ? highlight.attachmentkey.map(key => `https://media.spiggl.com/public/${key}`) : []
        }));

        setHighlights(highlightsWithImageUrls);
      } catch (error) {
        console.error("Error fetching company highlights:", error);
      }
    } else {
      console.error('No company ID provided in URL parameters.');
    }
  };

  useEffect(() => {
    fetchHighlights();
  }, [companyID, client]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      Object.keys(menuRefs.current).forEach(key => {
        if (menuRefs.current[key] && !menuRefs.current[key].contains(event.target)) {
          setShowMenu(prev => ({ ...prev, [key]: false }));
        }
      });
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const renderAttachments = (attachments) => {
    const additionalCount = attachments.length > 6 ? attachments.length - 6 : 0;

    return (
      <div className="highlight-attachments">
        {attachments.slice(0, 6).map((attachment, index) => (
          <div key={index} className="highlight-attachment-wrapper" onClick={() => openModal(index, attachments)}>
            <img
              src={attachment}
              alt={`Attachment ${index + 1}`}
              className="highlight-attachments__icon"
            />
            {index === 5 && additionalCount > 0 && (
              <div className="overlay">
                +{additionalCount}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const toggleDescriptionExpansion = (highlightId) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [highlightId]: !prev[highlightId]
    }));
  };

  const renderDescription = (description, highlightId) => {
    const limit = 450;
    const isExpanded = expandedDescriptions[highlightId];

    return (
      <div className="highlight-description">
        {isExpanded ? description : `${description.slice(0, limit)}`}
        {description.length > limit && (
          <button className="more-button" onClick={() => toggleDescriptionExpansion(highlightId)}>
            {isExpanded ? 'Show less' : 'More'}
          </button>
        )}
      </div>
    );
  };

  const handleMenuToggle = (highlightId) => {
    setShowMenu((prev) => ({
      ...prev,
      [highlightId]: !prev[highlightId]
    }));
  };

  const handleEditHighlight = (highlight) => {
    setEditHighlight(highlight);
  };

  const handleDeleteHighlight = (highlightId) => {
    setHighlightToDelete(highlightId);
    setShowDeleteModal(true);
  };

  const confirmDeleteHighlight = async () => {
    try {
      await client.graphql({
        query: deleteHighlight,
        variables: { input: { id: highlightToDelete } },
      });
      setHighlights((prev) => prev.filter((highlight) => highlight.id !== highlightToDelete));
      setShowDeleteModal(false);
      setHighlightToDelete(null);
    } catch (error) {
      console.error("Error deleting highlight:", error);
    }
  };

  const handleUpdateHighlight = (updatedHighlight) => {
    setHighlights((prevHighlights) => prevHighlights.map((highlight) =>
      highlight.id === updatedHighlight.id ? updatedHighlight : highlight
    ));
  };

  const indexOfLastHighlight = currentPage * highlightsPerPage;
  const indexOfFirstHighlight = indexOfLastHighlight - highlightsPerPage;
  const currentHighlights = highlights.slice(indexOfFirstHighlight, indexOfLastHighlight);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(highlights.length / highlightsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  return (
    <div className="company-highlights-container">
      <ImageModal 
        show={showModal} 
        attachments={currentAttachments} 
        selectedIndex={selectedImageIndex} 
        onClose={closeModal}
        onNext={() => setSelectedImageIndex((prevIndex) => (prevIndex + 1) % currentAttachments.length)}
        onPrev={() => setSelectedImageIndex((prevIndex) => (prevIndex - 1 + currentAttachments.length) % currentAttachments.length)}
      />
      {showDeleteModal && (
        <DeleteConfirmationModal
          onClose={() => setShowDeleteModal(false)}
          onConfirm={confirmDeleteHighlight}
        />
      )}
      {editHighlight && (
        <EditHighlightModal
          highlight={editHighlight}
          show={!!editHighlight}
          onClose={() => setEditHighlight(null)}
          onUpdate={handleUpdateHighlight}
        />
      )}
      <div className="highlights-container">
        {currentHighlights.length === 0 ? (
          <div className="no-highlights-container">
            <p>No Highlights</p>
            <img src="/icons/write.png" alt="No highlights" className="no-highlights-icon" />
          </div>
        ) : (
          currentHighlights.map((highlight) => (
            <div className="company-highlight" key={highlight.id}>
              <div className="highlight-header">
                <div className="highlight-title">{highlight.title}</div>
                <div className="highlight-menu" ref={el => menuRefs.current[highlight.id] = el}>
                  <div className="highlight-date">{format(new Date(highlight.createdAt), 'M/d/yy')}</div>
                  <button className="highlight-menu-button" onClick={() => handleMenuToggle(highlight.id)}>...</button>
                  {showMenu[highlight.id] && (
                    <div className="highlight-dropdown-menu">
                      <button onClick={() => handleEditHighlight(highlight)}>Edit Highlight</button>
                      <button onClick={() => handleDeleteHighlight(highlight.id)}>Delete Highlight</button>
                    </div>
                  )}
                </div>
              </div>
              <div className="highlight-attachments">
                {renderAttachments(highlight.attachmentUrls || [])}
              </div>
              <div className="highlight-description">
                {renderDescription(highlight.description, highlight.id)}
              </div>
            </div>
          ))
        )}
      </div>  
      <div className="pagination">
        {pageNumbers.map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => handlePageClick(pageNumber)}
            className={`page-number ${currentPage === pageNumber ? 'active' : ''}`}
          >
            {pageNumber}
          </button>
        ))}
      </div>
    </div>
  );
};

export default InternalCompanyHighlights;
