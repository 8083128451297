import "./PostHighlight.css";
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import FormHeader from '../../Headers/FormHeader/FormHeader';
import { Helmet } from 'react-helmet';
import { uploadData } from "aws-amplify/storage";
import { generateClient } from "aws-amplify/api";
import { createHighlight } from '../../../graphQL/mutations';
import { useAuth } from '../../../auth/auth';

export const PostHighlight = ({ className }) => {
  const navigate = useNavigate(); 
  const client = generateClient();
  const { userID } = useAuth(); 

  const [jobTitle, setJobTitle] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [attachments, setAttachments] = useState([]);

  const [jobTitleError, setJobTitleError] = useState('');
  const [jobDescriptionError, setJobDescriptionError] = useState('');
  const [attachmentsError, setAttachmentsError] = useState('');

  const countWords = (str) => {
    return str.trim().split(/\s+/).length;
  };

  const handleJobTitleChange = (event) => {
    const value = event.target.value;
    const wordCount = countWords(value);
    if (value.length <= 160 && wordCount <= 20) {
      setJobTitle(value);
      setJobTitleError('');
    } else {
      setJobTitleError('Title is too long');
    }
  };

  const handleJobDescriptionChange = (event) => {
    setJobDescription(event.target.value);
    setJobDescriptionError('');
  };

  const handleAttachmentsChange = (event) => {
    const newAttachments = Array.from(event.target.files);
    setAttachments((prevAttachments) => {
      const uniqueAttachments = newAttachments.filter(
        (newFile) => !prevAttachments.some((file) => file.name === newFile.name)
      );
      return [...prevAttachments, ...uniqueAttachments];
    });
    setAttachmentsError('');
  };

  const handleRemoveAttachment = (index) => {
    setAttachments((prevAttachments) => prevAttachments.filter((_, i) => i !== index));
  };

  const generateUniqueKey = (file) => {
    return `highlights/${Date.now()}_${Math.random().toString(36).substr(2, 9)}_${file.name}`;
  };

  const uploadToS3 = async (file) => {
    const key = generateUniqueKey(file);
    try {
      const result = await uploadData({
        key,
        data: file,
        options: {
          contentType: file.type,
          level: 'public',
        },
      });
      console.log('File uploaded successfully:', key);
      return key;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let hasErrors = false;

    if (!jobTitle) {
      setJobTitleError('Please enter a job title');
      hasErrors = true;
      return; 
    } 
    if (!jobDescription) {
      setJobDescriptionError('Please enter a job description');
      hasErrors = true;
      return; 
    } 
    if (!attachments.length) {
      setAttachmentsError('Please choose at least one file');
      hasErrors = true;
      return; 
    } 

    if (hasErrors) {
      return; 
    }

    const attachmentKeys = await Promise.all(
      attachments.map(file => uploadToS3(file))
    );

    const input = {
      companyID: userID, 
      title: jobTitle,
      description: jobDescription,
      attachmentkey: attachmentKeys.map(key => key),
    };

    console.log('Highlight input:', input);

    try {
      const response = await client.graphql({
        query: createHighlight,
        variables: { input },
      });
  
      console.log('Highlight posted successfully:', response.data.createHighlight);
      navigate(-1); 
    } catch (error) {
      console.error('Error posting highlight:', error);
    }
  };

  return (
    <div className="post-highlights">
      <FormHeader />
      <Helmet>
        <title>Post Highlight</title>
      </Helmet>
      <div className="post-highlights-container">
        <form onSubmit={handleSubmit} className="highlights-form">
          <div className="form-header">Post Highlight</div>
          <div className="form-field">
            <input
              type="text"
              placeholder="Job Title"
              value={jobTitle}
              onChange={handleJobTitleChange}
              className={jobTitleError ? 'input-error' : ''}
            />
            <div className="error-message">{jobTitleError}</div>
          </div>
          <div className="form-field">
            <textarea
              placeholder="Job Description"
              value={jobDescription}
              onChange={handleJobDescriptionChange}
              className={jobDescriptionError ? 'input-error' : ''}
            />
            <div className="error-message">{jobDescriptionError}</div>
          </div>
          <div className="form-field file-upload-field">
            <label htmlFor="fileInput" className="file-input-label">
              <span className="button-text">Choose File(s)</span>
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                multiple
                onChange={handleAttachmentsChange}
                className="file-input"
              />
            </label>
            {attachments && attachments.length > 0 && ( 
              <div className="file-name-display">
                {attachments.map((file, index) => (
                  <div key={index} className="file-item">
                    <span>{file.name}</span>
                    <button type="button" className="remove-button" onClick={() => handleRemoveAttachment(index)}>x</button>
                  </div>
                ))}
              </div>
            )}
            <div className="error-message">{attachmentsError}</div>
          </div>
          <button className="post-button" type="submit">
            Post
          </button>
        </form>
      </div>
    </div>
  );
};

export default PostHighlight;
