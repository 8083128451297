import React, { useState } from 'react';
import { updatePassword } from 'aws-amplify/auth';
import './ChangePassword.css';

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,}$/;
    return passwordRegex.test(password);
  };

  const handleNewPasswordChange = (e) => {
    const newPass = e.target.value;
    setNewPassword(newPass);
    setPasswordValid(validatePassword(newPass));
    setPasswordsMatch(newPass === confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const confirmPass = e.target.value;
    setConfirmPassword(confirmPass);
    setPasswordsMatch(confirmPass === newPassword);
  };

  const getPasswordInputClass = () => {
    if (newPassword.length === 0) return '';
    return passwordValid ? 'valid' : 'invalid';
  };

  const getConfirmPasswordInputClass = () => {
    if (confirmPassword.length === 0) return '';
    return passwordsMatch ? 'valid' : 'invalid';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!passwordValid) {
      setError('New password does not meet the requirements');
      return;
    }
    if (!passwordsMatch) {
      setError('New password and confirm password do not match');
      return;
    }
    try {
      await updatePassword({
        oldPassword,
        newPassword,
      });
      setSuccess('Password updated successfully');
      setError('');
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (err) {
      setError('Error updating password');
      console.log(err);
    }
  };

  return (
    <div className="change-password-container">
      <div className="change-password-header">Change Password</div>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          placeholder="Old Password"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="New Password"
          value={newPassword}
          onChange={handleNewPasswordChange}
          className={getPasswordInputClass()}
          required
        />
        <input
          type="password"
          placeholder="Confirm New Password"
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          className={getConfirmPasswordInputClass()}
          required
        />
        <div className="change-password-buttons-container">
          <button type="submit" className="change-password-update-profile-button">
            Update Password
          </button>
        </div>
        {error && <div className="password-change-error-message">{error}</div>}
        {success && <div className="password-change-success-message">{success}</div>}
      </form>
    </div>
  );
};

export default ChangePassword;
