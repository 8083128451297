import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import useStripeKey from '../useStripeKey'; 
import PaymentMethod from './PaymentMethod/PaymentMethod';

function Payment() {
  const { apiKey } = useStripeKey();
  const appearance = {
    theme: 'stripe',
  };
  return (
    <div className="App">
      <Elements stripe={loadStripe(apiKey)} options={{ appearance }}>
        <PaymentMethod />
      </Elements>
    </div>
  );
}
export default Payment;