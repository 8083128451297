import React from "react";
import "./ImageModal.css";

const ImageModal = ({ show, attachments, selectedIndex, onClose, onNext, onPrev }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="image-modal-backdrop" onClick={onClose}>
      <div className="image-modal-content" onClick={(e) => e.stopPropagation()}>
        <img src={attachments[selectedIndex]} alt="Enlarged" className="image-modal-enlarged" />
        <button className="image-modal-navigation-button image-modal-prev-button" onClick={onPrev}>❮</button>
        <button className="image-modal-navigation-button image-modal-next-button" onClick={onNext}>❯</button>
        <button className="image-modal-close-button" onClick={onClose}>×</button>
      </div>
    </div>
  );
};

export default ImageModal;
