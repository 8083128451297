import React, { useState } from 'react';
import { resetPassword, confirmResetPassword, signIn } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import { getUser, getCompany } from '../../graphQL/queries';
import './ForgotPassword.css';
import FormHeader from '../../components/Headers/FormHeader/FormHeader';
import CodeConfirmationModal from '../ConfirmationModal/CodeConfirmationModal';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isCodeVerified, setIsCodeVerified] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [verifiedCode, setVerifiedCode] = useState('');
  const [passwordValid, setPasswordValid] = useState(false);
  const navigate = useNavigate();
  const client = generateClient();

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,}$/;
    return passwordRegex.test(password);
  };

  const handlePasswordChange = (e) => {
    const newPass = e.target.value;
    setNewPassword(newPass);
    setPasswordValid(validatePassword(newPass));
  };

  const getPasswordInputClass = () => {
    if (newPassword.length === 0) return '';
    return passwordValid ? 'valid' : 'invalid';
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    
    try {
      const output = await resetPassword({ username: email });
      if (output.nextStep.resetPasswordStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE') {
        setIsModalOpen(true);
        setMessage('Verification code sent to your email.');
      }
    } catch (err) {
      setError(err.message || 'Failed to send verification code. Please try again.');
    }
  };

  const handleCodeVerification = async (code) => {
    try {
      if (!/^\d{6}$/.test(code)) {
        throw new Error('Invalid code format');
      }
      
      setVerifiedCode(code);
      setIsCodeVerified(true);
      setIsModalOpen(false);
      setMessage('Code verified. Please set a new password.');
      return { success: true };
    } catch (err) {
      setError(err.message || 'Invalid verification code. Please try again.');
      return { success: false };
    }
  };
  
  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    if (!passwordValid) {
      setError('Password does not meet the required criteria.');
      return;
    }

    if (!isCodeVerified || !verifiedCode) {
      setError('Please verify your email first');
      setIsModalOpen(true);
      return;
    }
  
    try {
      await confirmResetPassword({
        username: email,
        confirmationCode: verifiedCode,
        newPassword
      });
      
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      const signInResponse = await signIn({
        username: email,
        password: newPassword
      });

      setMessage('Successfully signed in. Redirecting...');

      try {
        navigate(0);
        const userData = await client.graphql({
          query: getUser,
          variables: { id: signInResponse.username }
        });
  
        if (userData.data.getUser) {
          navigate(`/user/${userData.data.getUser.user}`);
          return;
        }
  
        const companyData = await client.graphql({
          query: getCompany,
          variables: { id: signInResponse.username }
        });
  
        if (companyData.data.getCompany) {
          navigate(`/contractor/${companyData.data.getCompany.companyUser}`);
          return;
        }
  
        throw new Error('Profile not found');
      } catch (err) {
        console.error('Profile fetch error:', err);
        setError('Error accessing your profile. Please try signing in normally.');
      }
    } catch (err) {
      console.error('Password/SignIn error:', err);
      if (err.message.includes('Invalid verification code')) {
        setIsCodeVerified(false);
        setVerifiedCode('');
        setIsModalOpen(true);
        setError('Verification code expired. Please request a new code.');
      } else if (err.message.includes('incorrect username or password')) {
        setError('Unable to sign in with the new password. Please try again.');
      } else {
        setError(err.message || 'Failed to set new password. Please try again.');
      }
    }
  };  

  const resendCode = async () => {
    setError('');
    setIsCodeVerified(false);
    setVerifiedCode('');
    try {
      const output = await resetPassword({ username: email });
      if (output.nextStep.resetPasswordStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE') {
        setMessage('A new code has been sent to your email.');
      }
    } catch (err) {
      setError(err.message || 'Failed to resend code. Please try again.');
    }
  };

  return (
    <div className="reset-password">
      <FormHeader />
      <div className="forgot-password">
        <div className="forgot-password-container">
          {!isCodeVerified ? (
            <form onSubmit={handleEmailSubmit}>
              <h2>Reset Password</h2>
              {error && <p className="error">{error}</p>}
              {message && <p className="message">{message}</p>}
              <div>
                <label>Email</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <button type="submit">Reset Password</button>
            </form>
          ) : (
            <form onSubmit={handlePasswordSubmit}>
              <h2>Enter New Password</h2>
              {error && <p className="error">{error}</p>}
              {message && <p className="message">{message}</p>}
              <div>
                <label>New Password</label>
                <input
                  type="password"
                  value={newPassword}
                  onChange={handlePasswordChange}
                  required
                  minLength={8}
                  className={getPasswordInputClass()}
                />
              </div>
              <button type="submit">Set New Password</button>
            </form>
          )}
        </div>
      </div>

      <CodeConfirmationModal
        isOpen={isModalOpen}
        onConfirm={handleCodeVerification}
        username={email}
        resendCode={resendCode}
        errorMessage={error}
      />
    </div>
  );
};

export default ForgotPassword;
