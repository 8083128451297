import React, { useEffect, useState } from 'react';
import { generateClient } from 'aws-amplify/api';
import { useNavigate } from 'react-router-dom';
import * as queries from '../../../../graphQL/queries';
import * as mutations from '../../../../graphQL/mutations';
import { useAuth } from "../../../../auth/auth";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './Invoices.css';

const Invoices = ({ setHasUnreadInvoices }) => { 
  const [userData, setUserData] = useState({});
  const [invoices, setInvoices] = useState([]);
  const [companyNames, setCompanyNames] = useState({});
  const [loading, setLoading] = useState(true);
  const client = generateClient();
  const navigate = useNavigate();
  const { userID } = useAuth();
  const pendingIcon = '/icons/pending.png';
  const completeIcon = '/icons/checkmark.png';

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await client.graphql({
          query: queries.getUser,
          variables: { id: userID }
        });

        if (data.getUser) {
          setUserData(data.getUser);
        } else {
          console.error('User not found with id:', userID);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const fetchInvoices = async () => {
      try {
        const invoiceData = await client.graphql({
          query: queries.invoicesByUserID,
          variables: { userID }
        });
        const invoices = invoiceData.data.invoicesByUserID.items.map(invoice => ({
          ...invoice,
          status: invoice.totalAmount === 0 ? 'Complete' : invoice.status,
        }));

        invoices.sort((a, b) => {
          if (a.status === b.status) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          }
          return a.status === 'Pending' ? -1 : 1;
        });

        setInvoices(invoices);

        const companyIDs = invoices.map(invoice => invoice.companyID);
        const uniqueCompanyIDs = [...new Set(companyIDs)];

        const companyNames = await Promise.all(uniqueCompanyIDs.map(async (companyID) => {
          try {
            const { data } = await client.graphql({
              query: queries.getCompany,
              variables: { id: companyID }
            });
            return { [companyID]: data.getCompany };
          } catch (error) {
            console.error('Error fetching company data:', error);
            return { [companyID]: {} }; 
          }
        }));

        const companyNamesMap = companyNames.reduce((acc, curr) => ({ ...acc, ...curr }), {});
        setCompanyNames(companyNamesMap);
        setLoading(false); 
        checkUnreadInvoices(invoices);
      } catch (error) {
        console.error('Error fetching invoices:', error);
      }
    };

    const checkUnreadInvoices = (invoices) => {
      const unreadInvoices = invoices.some(invoice => !invoice.readStatus);
      setHasUnreadInvoices(unreadInvoices); 
    };

    fetchUserData();
    fetchInvoices();
  }, [userID, client, setHasUnreadInvoices]);

  const handleInvoiceClick = async (invoiceID) => {
    try {
      await client.graphql({
        query: mutations.updateInvoices,
        variables: { input: { id: invoiceID, readStatus: true } }
      });
      navigate(`/user/${userData.user}/invoice-dash/invoice/${invoiceID}`);
    } catch (error) {
      console.error('Error updating invoice read status:', error);
    }
  };

  const formatAmountWithCommas = (amount) => {
    return amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  return (
    <div className="invoices">
      <div className="invoices-header">
        <div className="invoices-title">Invoices</div>
        <div className="total-invoices">Total: {loading ? <Skeleton width={50} /> : invoices.length}</div>
      </div>
      <div className="invoices-list">
        {loading ? (
          <Skeleton count={5} height={50} />
        ) : (
          invoices.map((invoice) => {
            const company = companyNames[invoice.companyID];

            return (
              <div 
                className={`invoice-item ${!invoice.readStatus ? "unread" : ""}`} 
                key={invoice.id} 
                onClick={() => handleInvoiceClick(invoice.id)}
              >
                {!invoice.readStatus && <div className="unread-dot" />}
                <div className="invoice-company-name">{company?.companyName || company?.companyUser}</div>
                {invoice.status === 'Complete' ? (
                  <div className="paid-amount">${formatAmountWithCommas(parseFloat(invoice.amount))}</div>
                ) : (
                  invoice.totalAmount !== 0 && <div className="amount-due">${formatAmountWithCommas(invoice.totalAmount)}</div>
                )}
                <div className={`status-icon ${invoice.status.toLowerCase()}`}>
                  <img src={invoice.status === 'Pending' ? pendingIcon : completeIcon} alt={invoice.status} />
                </div>
                <div className="arrow-icon">❯</div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default Invoices;
