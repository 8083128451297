import React, { useState } from 'react';
import DefaultBackgroundModal from '../../Syn/DefaultBackgroundModal/DefaultBackgroundModal';
import ConfirmationModal from "../../../auth/ConfirmationModal/ConfirmationModal";
import ImageCropModal from '../../Syn/ImageCrop/ImageCropModal';
import CheckUsername from '../CheckUsername';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { generateClient } from "aws-amplify/api";
import { signUp, confirmSignUp, resendSignUpCode } from "aws-amplify/auth";
import { useAuth } from "../../../auth/auth";
import { uploadData } from 'aws-amplify/storage';
import { createCompany, createSpecialty } from '../../../graphQL/mutations';
import ContractorTypes from '../../Syn/ContractorTypes';
import ContractorSpecialties from '../../Syn/ContractorSpecialties';
import './CreateProfileCompany.css';
import backgrounds from '../../Syn/backgrounds';

const CreateProfileCompany = () => {
  const navigate = useNavigate();
  const client = generateClient();
  const { login } = useAuth();

  const [profilePicture, setProfilePicture] = useState({ file: null, previewUrl: '' });
  const [backgroundSelection, setBackgroundSelection] = useState({ file: null, name: '', previewUrl: '' });
  const [profilePictureKey, setProfilePictureKey] = useState(null);
  const [backgroundPictureKey, setBackgroundPictureKey] = useState(null);

  const [isDefaultBackgroundModalOpen, setIsDefaultBackgroundModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [imageCropModalOpen, setImageCropModalOpen] = useState(false);
  const [imageToCrop, setImageToCrop] = useState(null);
  const [croppingFor, setCroppingFor] = useState(null);
  const [confirmationCode, setConfirmationCode] = useState('');
  const [resendCodeLoading, setResendCodeLoading] = useState(false);

  const [companyUser, setCompanyUser] = useState('');
  const [userId, setUserId] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyType, setCompanyType] = useState([]);
  const [zipcode, setZipcode] = useState('');
  const [state, setState] = useState('');
  const [town, setTown] = useState('');

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [usernameValid, setUsernameValid] = useState(false);
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [bio, setBio] = useState('');
  const [password, setPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [lowRange, setLowRange] = useState('');
  const [highRange, setHighRange] = useState('');

  const [companyUserError, setCompanyUserError] = useState('');
  const [companyNameError, setCompanyNameError] = useState('');
  const [companyTypeError, setCompanyTypeError] = useState('');
  const [zipcodeError, setZipcodeError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [retypePasswordError, setRetypePasswordError] = useState('');
  const [confirmationError, setConfirmationError] = useState('');
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const requiredFields = ['companyUser', 'email', 'password', 'retypePassword', 'zipcode', 'lowRange', 'highRange'];

  const [specialtyFields, setSpecialtyFields] = useState({});

  const isFieldValid = (fieldName) => {
    if (requiredFields.includes(fieldName)) {
      return Boolean((fieldName));
    }
    return true;
  };

  const handleTermsClick = () => {
    navigate("/terms");
  };

  const handleUsernameCheck = (isAvailable, isValidFormat) => {
    if (!isValidFormat) {
      setCompanyUserError('Invalid character');
      setUsernameValid(false);
    } else if (isAvailable === null) {
      setCompanyUserError('');
      setUsernameValid(false);
    } else if (!isAvailable) {
      setCompanyUserError('Username already exists');
      setUsernameValid(false);
    } else {
      setCompanyUserError('');
      setUsernameValid(true);
    }
  };

  const handlePhoneChange = (e) => {
    const digits = e.target.value.replace(/\D/g, '');

    let formattedValue = '';

    const parts = [
      digits.slice(0, 3),
      digits.slice(3, 6),
      digits.slice(6, 10)
    ];

    if (parts[0].length) {
      formattedValue += parts[0];
      if (parts[0].length === 3) {
        formattedValue += '-';
      }
    }
    if (parts[1].length) {
      formattedValue += parts[1];
      if (parts[1].length === 3) {
        formattedValue += '-';
      }
    }
    formattedValue += parts[2];

    setPhone(formattedValue);

    if (formattedValue.length > 0 && formattedValue.length < 12) {
      setPhoneError('Invalid phone number');
    } else {
      setPhoneError('');
    }
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,}$/;
    return passwordRegex.test(password);
  };

  const handlePasswordChange = (e) => {
    const newPass = e.target.value;
    setPassword(newPass);
    setPasswordValid(validatePassword(newPass));
    setPasswordsMatch(newPass === retypePassword);
  };

  const handleRetypePasswordChange = (e) => {
    const newRetypePass = e.target.value;
    setRetypePassword(newRetypePass);
    setPasswordsMatch(newRetypePass === password);
  };

  const getPasswordInputClass = () => {
    if (password.length === 0) return '';
    return passwordValid ? 'valid' : 'invalid';
  };

  const getRetypePasswordInputClass = () => {
    if (retypePassword.length === 0) return '';
    return passwordsMatch ? 'valid' : 'invalid';
  };

  const handleDefaultBackgroundModal = () => {
    setIsDefaultBackgroundModalOpen(!isDefaultBackgroundModalOpen);
  };

  const handleSelectBackground = async (backgroundName) => {
    const selectedBackground = backgrounds.find(bg => bg.name === backgroundName);
    const response = await fetch(selectedBackground.src);
    if (response.ok) {
      const blob = await response.blob();
      setBackgroundSelection({ file: blob, name: selectedBackground.name, previewUrl: URL.createObjectURL(blob) });
      handleDefaultBackgroundModal();
    } else {
      console.error('Failed to fetch background image:', response.statusText);
    }
  };

  const handleProfilePictureChange = (event) => {
    if (event.target.files[0]) {
      setImageToCrop(URL.createObjectURL(event.target.files[0]));
      setCroppingFor('profilePicture');
      setImageCropModalOpen(true);
    }
  };

  const handleBackgroundPictureChange = (event) => {
    if (event.target.files[0]) {
      setImageToCrop(URL.createObjectURL(event.target.files[0]));
      setCroppingFor('backgroundPicture');
      setImageCropModalOpen(true);
    }
  };

  const handleCropComplete = (croppedBlob) => {
    if (croppingFor === 'profilePicture') {
      setProfilePicture({
        file: croppedBlob,
        previewUrl: URL.createObjectURL(croppedBlob),
      });
    } else if (croppingFor === 'backgroundPicture') {
      setBackgroundSelection({
        file: croppedBlob,
        previewUrl: URL.createObjectURL(croppedBlob),
      });
    }
  };

  const handleSignUpConfirmation = async (code) => {
    try {
      const result = await confirmSignUp({
        username: email,
        confirmationCode: code,
      });
  
      if (result.isSignUpComplete) {
        console.log('Sign-up confirmation successful');
        setConfirmationModalOpen(false);
  
        await login(email, password);
        console.log('User logged in successfully');
  
        const input = {
          id: userId,
          companyUser,
          companyName,
          companyType: companyType.map(type => type.value),
          zipcode,
          email,
          phone: phone.match(/^\d{3}-\d{3}-\d{4}$/) ? phone : null,
          companyWebsite,
          bio,
          profilepicturekey: profilePictureKey,
          backgroundpicturekey: backgroundPictureKey,
          town,
          state,
          emailPrivate: false,
          phonePrivate: false,
          messageNotifications: true,
          paymentNotifications: true,
          requestNotifications: true,
          lowRange: parseInt(lowRange, 10),
          highRange: parseInt(highRange, 10),
          lowPrivate: false,
          highPrivate: false,
          blocked: []
        };
  
        const createUserResponse = await client.graphql({
          query: createCompany,
          variables: { input },
        });
        console.log('User created successfully!', createUserResponse);
  
        for (const type of companyType) {
          const specialties = specialtyFields[type.value] || [];
          const specialtyInput = {
            type: type.value,
            specialties,
            companyID: createUserResponse.data.createCompany.id,
          };
  
          await client.graphql({
            query: createSpecialty,
            variables: { input: specialtyInput },
          });
        }
  
        navigate(`/contractor/${companyUser}`);
      } else {
        console.log('Additional steps required:', result.nextStep);
      }
    } catch (error) {
      console.error('Error confirming sign up:', error);
      setConfirmationError('An error occurred while confirming sign up. Please try again.');
    }
  };

  const handleResendCode = async () => {
    setResendCodeLoading(true);
    try {
      const { destination, deliveryMedium, attributeName } = await resendSignUpCode({ username: email });
      console.log(`Code resent to ${destination} via ${deliveryMedium} (${attributeName})`);
      setResendCodeLoading(false);
    } catch (error) {
      console.error('Error resending code:', error);
      setResendCodeLoading(false);
    }
  };

  const generateUniqueKey = (folder, companyUser) => {
    return `${folder}/${Date.now()}_${Math.random().toString(36).substr(2, 9)}_${companyUser}`;
  };

  const uploadToS3 = async (file, folder, companyUser) => {
    const key = generateUniqueKey(folder, companyUser);
    try {
      const result = await uploadData({
        key: key,
        data: file,
        options: {
          contentType: file.type,
          level: 'public',
        }
      });
  
      console.log('File uploaded successfully:', result);
      return key;
    } catch (error) {
      console.error('Error uploading:', error);
      throw error;
    }
  };

  const handleCompanyTypeChange = (selectedOptions) => {
    if (selectedOptions.length > 2) {
      return; 
    }
    setCompanyType(selectedOptions);
    const newSpecialtyFields = { ...specialtyFields };
    selectedOptions.forEach(option => {
      if (!newSpecialtyFields[option.value]) {
        newSpecialtyFields[option.value] = [];
      }
    });
    setSpecialtyFields(newSpecialtyFields);
  };

  const handleSpecialtyChange = (type, selectedOptions) => {
    const newSpecialtyFields = { ...specialtyFields, [type]: selectedOptions.map(option => option.value) };
    setSpecialtyFields(newSpecialtyFields);
  };

  const fetchTownAndStateFromZipcode = async (zipcode) => {
    if (!zipcode) return;

    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${zipcode}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === 'OK') {
        const result = data.results[0];
        const addressComponents = result.address_components;

        const townComponent = addressComponents.find(component =>
          component.types.includes('locality') || component.types.includes('sublocality')
        );
        const stateComponent = addressComponents.find(component =>
          component.types.includes('administrative_area_level_1')
        );

        const town = townComponent ? townComponent.long_name : '';
        const state = stateComponent ? stateComponent.short_name : '';

        setTown(town);
        setState(state);
      } else {
        console.error('Error fetching town and state:', data.status);
      }
    } catch (error) {
      console.error('Error fetching town and state:', error);
    }
  };

  const handleZipcodeChange = (e) => {
    const zipcode = e.target.value;
    setZipcode(zipcode);
    setState('');
    setTown('');

    if (zipcode.length === 5) {
      fetchTownAndStateFromZipcode(zipcode);
    }
  };

  const handleBioChange = (e) => {
    const words = e.target.value.split(/\s+/);
    if (words.length <= 50) {
      setBio(e.target.value);
    }
  };

  const handlePriceRangeChange = (e) => {
    const { name, value } = e.target;
    const cleanedValue = value.replace(/\D/g, ''); 

    if (name === 'lowRange') {
      setLowRange(cleanedValue);
    } else if (name === 'highRange') {
      setHighRange(cleanedValue);
    }
  };

  const handleWebsiteChange = (e) => {
    const value = e.target.value.replace(/^https?:\/\//, '');
    setCompanyWebsite(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    let hasErrors = false;
  
    setCompanyUserError('');
    setCompanyNameError('');
    setCompanyTypeError('');
    setZipcodeError('');
    setEmailError('');
    setPasswordError('');
    setRetypePasswordError('');
  
    requiredFields.forEach((fieldName) => {
      if (!isFieldValid(fieldName)) {
        if (fieldName === 'companyUser') {
          setCompanyUserError('Username is required');
        } else if (fieldName === 'companyName') {
          setCompanyNameError('Company Name is required');
        } else if (fieldName === 'companyType') {
          setCompanyTypeError('Company type is required');
        } else if (fieldName === 'zipcode') {
          setZipcodeError('Zipcode is required');
        } else if (fieldName === 'email') {
          setEmailError('Email is required');
        } else if (fieldName === 'password') {
          setPasswordError('Password is required');
        } else if (fieldName === 'retypePassword') {
          setRetypePasswordError('Retype Password is required');
        }
        hasErrors = true;
      }
    });
  
    if (hasErrors || !usernameValid || !passwordValid || !passwordsMatch) {
      return;
    }
  
    try {
      const uploadedProfilePictureKey = profilePicture.file 
          ? await uploadToS3(profilePicture.file, 'profile', companyUser)
          : null;
  
      setProfilePictureKey(uploadedProfilePictureKey);
  
      let uploadedBackgroundPictureKey = null;
  
      if (backgroundSelection.file instanceof Blob || backgroundSelection.file instanceof File) {
          uploadedBackgroundPictureKey = await uploadToS3(backgroundSelection.file, 'background', companyUser);
      } else {
          uploadedBackgroundPictureKey = backgroundSelection.name;
      }
  
      setBackgroundPictureKey(uploadedBackgroundPictureKey);

      await handleSignUp({
        email,
        companyUser,
        companyName,
        companyType,
        zipcode,
        state,
        town,
        bio,
        companyWebsite,
        phone,
        profilepicturekey: uploadedProfilePictureKey,
        backgroundpicturekey: uploadedBackgroundPictureKey,
        lowRange,
        highRange,
        emailPrivate: false,
        phonePrivate: false,
        lowPrivate: false,
        highPrivate: false
      });
    } catch (error) {
      console.error('Error during signup:', error);
      if (error.message) {
        setEmailError(error.message);
      } else {
        setEmailError('An unexpected error occurred during sign-up.');
      }
    }
  };

  const handleSignUp = async () => {
    if (!usernameValid || !passwordValid || !passwordsMatch) {
      console.error('Validation failed.');
      return;
    }

    try {
      const signUpResult = await signUp({
        username: email,
        password,
        attributes: {
          'custom:username': companyUser,
          ...(phone ? { phone_number: phone } : {}),
        }
      });

      console.log('Signup success:', signUpResult);
      setUserId(signUpResult.userId);
      setConfirmationModalOpen(true);
    } catch (error) {
      console.error('Signup failed:', error);
      setEmailError(error.message || 'Failed to sign up.');
    }
  };

  return (
    <div className="uc-create-profile">
      <div className="create-profile-container">
        <div className={`label ${companyUserError ? 'error' : ''}`}>
          <div className="create-profile-header">Contractor</div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="attach-profile-picture">
            <label htmlFor="profilePictureInput" className="file-input-profile-picture">
              <span className="button-text">Choose Profile Picture</span>
              <input
                type="file"
                id="profilePictureInput"
                accept="image/*"
                onChange={handleProfilePictureChange}
                className="file-input"
              />
            </label>
            {profilePicture.previewUrl && (
              <div>
                <img src={profilePicture.previewUrl} alt="Profile Preview" className="profile-picture-preview" />
                <div className="file-name-display">{profilePicture.file.name}</div>
              </div>
            )}
          </div>
          <div className="background-picture-options">
            <button type="button" className="default-background-button" onClick={handleDefaultBackgroundModal}>
              Choose Default Background
            </button>
            <span className="or-divider">or</span>
            <label htmlFor="backgroundPictureInput" className="file-input-background">
              <span className="background-button">Choose Background Picture</span>
              <input
                type="file"
                id="backgroundPictureInput"
                accept="image/*"
                onChange={handleBackgroundPictureChange}
                className="file-input"
              />
            </label>
            {isDefaultBackgroundModalOpen && (
              <DefaultBackgroundModal
                isOpen={isDefaultBackgroundModalOpen}
                onClose={handleDefaultBackgroundModal}
                onSelectBackground={handleSelectBackground}
              />
            )}
          </div>
          {backgroundSelection.previewUrl && (
            <div>
              <img src={backgroundSelection.previewUrl} alt="Background Preview" className="background-picture-preview" />
              <div className="file-name-display">{backgroundSelection.name}</div>
            </div>
          )}
          <div className="username">
            <CheckUsername
                userType="User"
                onUsernameCheck={handleUsernameCheck}
                onChange={(e) => setCompanyUser(e.target.value)}
            />
            {companyUserError && <div className="error-message">{companyUserError}</div>}
          </div>
          <div className="company-name">
            <input
              type="text"
              placeholder="*Company Name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              maxLength="25"
            />
            {companyNameError && <div className="error-message">{companyNameError}</div>}
          </div>
          <div className="company-type">
            <Select
              isMulti
              classNamePrefix="react-select"
              className='react-select'
              options={ContractorTypes}
              value={companyType}
              onChange={handleCompanyTypeChange}
              placeholder="*Contractor Type (2 max)"
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  bottom: '7px',
                  padding: '7px 0px',
                  fontFamily: 'Poppins, sans-serif',
                }),
                placeholder: (provided) => ({
                  ...provided,
                  textAlign: 'left',
                }),
              }}
            />
            {companyTypeError && <div className="error-message">{companyTypeError}</div>}
          </div>
          {companyType.map(type => (
            <div key={type.value} className="specialty-dropdown">
              <label className="specialty-label"></label>
              <Select
                isMulti
                className='react-select'
                options={ContractorSpecialties[type.value].map(spec => ({ label: spec, value: spec }))}
                value={specialtyFields[type.value] ? specialtyFields[type.value].map(spec => ({ label: spec, value: spec })) : []}
                onChange={(selectedOptions) => handleSpecialtyChange(type.value, selectedOptions)}
                placeholder={`Select specialties for ${type.label}`}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                    bottom: '7px',
                    padding: '7px 0px',
                    fontFamily: 'Poppins, sans-serif',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    textAlign: 'left',
                  }),
                }}
              />
            </div>
          ))}
          <div className="create-price-range-container">
            <div className="create-price-range-label">*Price Range</div>
            <div className="create-price-range-inputs">
              <input
                type="text"
                name="lowRange"
                placeholder="Lower Range"
                value={lowRange ? `$${lowRange}` : ''}
                onFocus={(e) => e.target.value === '' ? e.target.value = '$' : e.target.value}
                onBlur={(e) => e.target.value === '$' ? e.target.value = '' : e.target.value}
                onChange={handlePriceRangeChange}
                maxLength="6"
                className="price-input"
              />
              <span className="create-price-range-separator">-</span>
              <input
                type="text"
                name="highRange"
                placeholder="Upper Range"
                value={highRange ? `$${highRange}` : ''}
                onFocus={(e) => e.target.value === '' ? e.target.value = '$' : e.target.value}
                onBlur={(e) => e.target.value === '$' ? e.target.value = '' : e.target.value}
                onChange={handlePriceRangeChange}
                maxLength="7"
                className="price-input"
              />
            </div>
          </div>
          <div className="zipcode">
            <input
              type="text"
              placeholder="*Zipcode"
              value={zipcode}
              onChange={handleZipcodeChange}
            />
            {zipcodeError && <div className="error-message">{zipcodeError}</div>}
          </div>
          <div className="town">
            <input
              type="text"
              placeholder="Town"
              value={town}
              readOnly
            />
          </div>
          <div className="state">
            <input
              type="text"
              placeholder="State"
              value={state}
              readOnly
            />
          </div>
          <div className="email">
            <input
              type="email"
              placeholder="*Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailError && <div className="error-message">{emailError}</div>}
          </div>
          <div className="phone-number">
            <input
              type="text"
              placeholder="Phone Number"
              value={phone}
              onChange={handlePhoneChange}
              maxLength="12"
            />
            {phoneError && <div className="error-message">{phoneError}</div>}
          </div>
          <div className="website-url">
            <input
              type="text"
              placeholder="Website URL"
              value={companyWebsite ? `https://${companyWebsite}` : ''}
              onFocus={(e) => e.target.value === '' ? e.target.value = 'https://' : e.target.value}
              onBlur={(e) => e.target.value === 'https://' ? e.target.value = '' : e.target.value}
              onChange={handleWebsiteChange}
            />
          </div>
          <div className="create-bio">
            <textarea
              placeholder="Anything to add?"
              value={bio}
              onChange={handleBioChange}
              rows="4"
              style={{
                width: '100%',
                padding: '7px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                fontFamily: 'Poppins, sans-serif',
                fontSize: '16px',
                resize: 'none'
              }}
            />
            <div style={{
              textAlign: 'right',
              fontFamily: 'Poppins, sans-serif',
              fontSize: '12px',
              color: '#666'
            }}>
              {bio.split(/\s+/).filter(word => word.length > 0).length}/50
            </div>
          </div>
          <div className="password">
            <input
              type="password"
              placeholder="*Password"
              value={password}
              onChange={handlePasswordChange}
              className={getPasswordInputClass()}
            />
            <div className="error-message">{passwordError}</div>
          </div>
          <div className="retype-password">
            <input
              type="password"
              placeholder="*Retype Password"
              value={retypePassword}
              onChange={handleRetypePasswordChange}
              className={getRetypePasswordInputClass()}
            />
            <div className="error-message">{retypePasswordError}</div>
          </div>
          <div className="create-terms-container">
            By creating a profile you agree to the
            <span className="terms-click" onClick={handleTermsClick}>
              Terms of Service
            </span>
          </div>
          <button type="submit" className="create-profile-button">
            Create Company Profile
          </button>
        </form>
        <ConfirmationModal
          isOpen={confirmationModalOpen}
          onClose={() => setConfirmationModalOpen(false)}
          onConfirm={handleSignUpConfirmation}
          code={confirmationCode}
          setCode={setConfirmationCode}
          resendCode={handleResendCode}
          username={email}
          errorMessage={confirmationError}
        />
        <ImageCropModal
          isOpen={imageCropModalOpen}
          imageSrc={imageToCrop}
          onClose={() => setImageCropModalOpen(false)}
          onCropComplete={handleCropComplete}
          aspectRatio={croppingFor === 'profilePicture' ? 1 : 5 / 1}
        />
      </div>
    </div>
  );
};

export default CreateProfileCompany;
