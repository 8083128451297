const contractorSpecialties = {
    "Appliance Repair": [
      "Refrigerator repair",
      "Washer/dryer repair",
      "Dishwasher repair",
      "Oven/stove repair",
      "Microwave repair",
      "Freezer repair",
      "Garbage disposal repair",
      "Ice maker repair",
      "Range hood repair",
      "Wine cooler repair"
    ],
    "Architect": [
      "Residential design",
      "Commercial design",
      "Landscape architecture",
      "Urban planning",
      "Interior architecture",
      "Renovation planning",
      "Sustainable design",
      "Historic preservation",
      "Feasibility studies",
      "Site analysis"
    ],
    "Carpentry": [
      "Framing",
      "Trim and molding installation",
      "Cabinet making",
      "Deck construction",
      "Custom woodwork",
      "Staircase construction",
      "Shelving installation",
      "Wooden flooring",
      "Window and door framing",
      "Partition wall construction"
    ],
    "Carpet Cleaning": [
      "Steam cleaning",
      "Dry cleaning",
      "Stain removal",
      "Odor removal",
      "Carpet protection",
      "Deep carpet cleaning",
      "Pet stain removal",
      "Allergen treatment",
      "Area rug cleaning",
      "Commercial carpet cleaning"
    ],
    "Chimney": [
      "Chimney cleaning",
      "Chimney inspection",
      "Chimney repair",
      "Fireplace cleaning",
      "Flue cleaning",
      "Chimney relining",
      "Cap installation",
      "Masonry repair",
      "Smoke chamber repair",
      "Draft problem resolution"
    ],
    "Concrete": [
      "Foundation pouring",
      "Driveway installation",
      "Sidewalk installation",
      "Concrete repair",
      "Decorative concrete",
      "Retaining wall construction",
      "Concrete resurfacing",
      "Stamped concrete",
      "Concrete staining",
      "Concrete sealing"
    ],
    "Drywall": [
      "Drywall installation",
      "Drywall repair",
      "Taping and mudding",
      "Texturing",
      "Ceiling installation",
      "Drywall finishing",
      "Plaster repair",
      "Drywall patching",
      "Soundproof drywall installation",
      "Moisture-resistant drywall installation"
    ],
    "Electrician": [
      "Electrical wiring",
      "Lighting installation",
      "Circuit breaker repair",
      "Electrical panel upgrades",
      "Generator installation",
      "Outlet and switch installation",
      "Ceiling fan installation",
      "Security system installation",
      "Surge protection",
      "Electrical inspections"
    ],
    "Fencing": [
      "Wood fence installation",
      "Vinyl fence installation",
      "Metal fence installation",
      "Fence repair",
      "Gate installation",
      "Chain link fence installation",
      "Privacy fence installation",
      "Pool fence installation",
      "Electric fence installation",
      "Fence painting and staining"
    ],
    "Flooring": [
      "Hardwood floor installation",
      "Tile installation",
      "Carpet installation",
      "Vinyl flooring",
      "Floor repair",
      "Laminate flooring",
      "Bamboo flooring",
      "Cork flooring",
      "Floor refinishing",
      "Subfloor repair"
    ],
    "Garage Doors": [
      "Garage door installation",
      "Garage door repair",
      "Opener installation",
      "Spring replacement",
      "Panel replacement",
      "Remote control setup",
      "Garage door insulation",
      "Track repair",
      "Safety sensor installation",
      "Custom garage doors"
    ],
    "General": [
      "Project management",
      "Coordination of subcontractors",
      "Permitting and inspections",
      "Site preparation",
      "Scheduling and timeline management",
      "Budget management",
      "Quality control",
      "Client communication",
      "Safety compliance",
      "Risk management"
    ],
    "Home Automation": [
      "Smart home integration",
      "Lighting control systems",
      "Home theater installation",
      "Security system integration",
      "Voice control systems",
      "Climate control systems",
      "Smart appliance installation",
      "Network setup",
      "Automated shading",
      "Energy management systems"
    ],
    "Home Building": [
      "Custom home construction",
      "Spec home construction",
      "Modular home construction",
      "Tiny home construction",
      "Sustainable building",
      "Log home construction",
      "Prefabricated home construction",
      "Multi-family home construction",
      "Home additions",
      "Project management"
    ],
    "Home Cleaning": [
      "Regular cleaning",
      "Deep cleaning",
      "Move-in/move-out cleaning",
      "Post-construction cleaning",
      "Specialized cleaning (e.g., windows, carpets)",
      "Spring cleaning",
      "Green cleaning",
      "Upholstery cleaning",
      "Pressure washing",
      "Office cleaning"
    ],
    "Home Inspection": [
      "Pre-purchase inspection",
      "Pre-listing inspection",
      "New construction inspection",
      "Environmental inspection",
      "Thermal imaging",
      "Pest inspection",
      "Roof inspection",
      "Plumbing inspection",
      "Electrical inspection",
      "Structural inspection"
    ],
    "Home Security": [
      "Security camera installation",
      "Alarm system installation",
      "Access control systems",
      "Intercom system installation",
      "Security system maintenance",
      "Motion sensor installation",
      "Smart lock installation",
      "Fire alarm installation",
      "Security lighting",
      "Monitoring services"
    ],
    "Home Staging": [
      "Furniture rental",
      "Decorating",
      "Decluttering",
      "Space planning",
      "Consultation",
      "Color coordination",
      "Art and accessory placement",
      "Lighting enhancement",
      "Virtual staging",
      "Rearrangement of existing furniture"
    ],
    "HVAC": [
      "Heating system installation",
      "Air conditioning installation",
      "Ventilation system installation",
      "Duct cleaning",
      "Thermostat installation",
      "Furnace repair",
      "Heat pump installation",
      "Boiler repair",
      "Air quality testing",
      "Energy efficiency assessments"
    ],
    "Insulation": [
      "Fiberglass insulation",
      "Foam insulation",
      "Cellulose insulation",
      "Attic insulation",
      "Soundproofing",
      "Spray foam insulation",
      "Wall insulation",
      "Basement insulation",
      "Crawl space insulation",
      "Radiant barrier installation"
    ],
    "Interior Design": [
      "Space planning",
      "Color consultation",
      "Furniture selection",
      "Lighting design",
      "Decorating",
      "Window treatment design",
      "Kitchen and bath design",
      "Accessory selection",
      "Flooring selection",
      "Custom cabinetry design"
    ],
    "Landscaping": [
      "Lawn care",
      "Planting",
      "Mulching",
      "Trimming and pruning",
      "Hardscaping",
      "Irrigation system installation",
      "Landscape lighting",
      "Sod installation",
      "Landscape design",
      "Water feature installation"
    ],
    "Masonry": [
      "Bricklaying",
      "Stonework",
      "Concrete work",
      "Chimney repair",
      "Fireplace installation",
      "Retaining wall construction",
      "Paver installation",
      "Masonry restoration",
      "Stucco work",
      "Foundation repair"
    ],
    "Moving Services": [
      "Local moving",
      "Long-distance moving",
      "Packing services",
      "Storage services",
      "Specialty item moving (e.g., pianos, antiques)",
      "Furniture disassembly and reassembly",
      "Commercial moving",
      "Loading and unloading",
      "Temporary storage",
      "Unpacking services"
    ],
    "Other": [
      "Unique projects",
      "Custom requests",
      "Event setup and teardown",
      "Pet services",
      "Personal shopping",
      "Concierge services",
      "Holiday decoration setup",
      "Home organization",
      "Senior move management"
    ],
    "Painting": [
      "Interior painting",
      "Exterior painting",
      "Wallpaper installation",
      "Surface preparation",
      "Specialty finishes",
      "Deck staining and sealing",
      "Cabinet painting",
      "Fence painting",
      "Murals",
      "Epoxy floor coating"
    ],
    "Pest Control": [
      "Termite treatment",
      "Rodent control",
      "Insect control",
      "Wildlife removal",
      "Bed bug treatment",
      "Mosquito control",
      "Bee and wasp removal",
      "Flea and tick control",
      "Pest prevention",
      "Eco-friendly pest control"
    ],
    "Plumbing": [
      "Pipe installation",
      "Fixture installation",
      "Water heater repair",
      "Sewer line repair",
      "Drain cleaning",
      "Leak detection",
      "Gas line installation",
      "Backflow prevention",
      "Water softener installation",
      "Plumbing inspections"
    ],
    "Pool and Spa": [
      "Pool installation",
      "Spa installation",
      "Pool maintenance",
      "Pool repair",
      "Pool cleaning",
      "Pool heating systems",
      "Spa maintenance",
      "Pool covers",
      "Pool lighting",
      "Water testing and balancing"
    ],
    "Remodeling": [
      "Kitchen remodeling",
      "Bathroom remodeling",
      "Basement finishing",
      "Home additions",
      "Whole house remodeling",
      "Attic conversions",
      "Garage conversions",
      "Outdoor living spaces",
      "Accessibility modifications",
      "Historic home restoration"
    ],
    "Roofing": [
      "Roof installation",
      "Roof repair",
      "Roof inspection",
      "Gutter installation",
      "Skylight installation",
      "Roof replacement",
      "Roof ventilation",
      "Roof cleaning",
      "Emergency roof repair",
      "Flat roofing"
    ],
    "Septic System": [
      "Septic tank installation",
      "Septic tank pumping",
      "Septic system repair",
      "Drain field installation",
      "Septic inspection",
      "Septic system design",
      "Septic system maintenance",
      "Septic tank cleaning",
      "Septic tank riser installation",
      "Aerobic treatment units"
    ],
    "Solar": [
      "Solar panel installation",
      "Solar panel maintenance",
      "Solar battery storage",
      "Solar system design",
      "Solar consultation",
      "Solar panel cleaning",
      "Solar inverter repair",
      "Solar water heating",
      "Off-grid solar systems",
      "Solar energy audits"
    ],
    "Water Treatment": [
      "Water softener installation",
      "Reverse osmosis systems",
      "Water filtration",
      "UV water purification",
      "Water testing",
      "Iron removal systems",
      "Well water treatment",
      "Whole house water filtration",
      "Water conditioner installation",
      "Bacteria removal systems"
    ],
    "Well Drilling and Pump": [
      "Well drilling",
      "Pump installation",
      "Well inspection",
      "Well repair",
      "Water testing",
      "Well maintenance",
      "Well chlorination",
      "Pressure tank installation",
      "Well casing repair",
      "Geothermal well drilling"
    ],
    "Window and Door": [
      "Window installation",
      "Door installation",
      "Storm window installation",
      "Skylight installation",
      "Weatherstripping",
      "Patio door installation",
      "Garage door installation",
      "French door installation",
      "Window replacement",
      "Entry door installation"
    ],
    "Window Treatment": [
      "Blinds installation",
      "Curtain installation",
      "Shades installation",
      "Drapery installation",
      "Custom window treatments",
      "Motorized blinds",
      "Window film installation",
      "Plantation shutters",
      "Valance installation",
      "Window treatment repair"
    ],
    "Fire Protection": [
      "Fire sprinkler systems",
      "Fire alarm systems",
      "Fire suppression systems",
      "Fire extinguisher services",
      "Fire safety inspections",
      "Fire escape plan design",
      "Fire door installation",
      "Smoke detector installation",
      "Emergency lighting",
      "Fire hydrant maintenance"
    ],
    "Audio/Visual Installation": [
      "Home theater setup",
      "Sound system installation",
      "TV mounting",
      "Smart home integration",
      "Conference room setup",
      "Projector installation",
      "Networking setup",
      "CCTV installation",
      "Remote control programming",
      "Whole home audio"
    ],
    "Gutter": [
      "Gutter installation",
      "Gutter repair",
      "Gutter cleaning",
      "Gutter guards",
      "Downspout installation",
      "Seamless gutter installation",
      "Rain barrel installation",
      "Gutter painting",
      "Gutter replacement",
      "Gutter inspection"
    ],
    "Handyman": [
      "Minor repairs",
      "Furniture assembly",
      "Picture hanging",
      "Fixture replacement",
      "General maintenance",
      "Caulking",
      "Minor plumbing repairs",
      "Minor electrical repairs",
      "Home improvements",
      "Deck repair"
    ],
    "Demolition": [
      "Building demolition",
      "Interior demolition",
      "Concrete demolition",
      "Selective demolition",
      "Site clearing",
      "Pool removal",
      "Asbestos abatement",
      "Hazardous material removal",
      "Excavation",
      "Structural dismantling"
    ]
  };
  
  export default contractorSpecialties;  