import React, { useState } from 'react';
import DefaultBackgroundModal from '../../Syn/DefaultBackgroundModal/DefaultBackgroundModal'; 
import ConfirmationModal from "../../../auth/ConfirmationModal/ConfirmationModal";
import ImageCropModal from '../../Syn/ImageCrop/ImageCropModal';
import CheckUsername from '../CheckUsername';
import { useNavigate } from 'react-router-dom';
import { generateClient } from "aws-amplify/api";
import { signUp, confirmSignUp, resendSignUpCode } from "aws-amplify/auth";
import { useAuth } from "../../../auth/auth";
import { uploadData } from "aws-amplify/storage";
import { createUser } from '../../../graphQL/mutations';
import backgrounds from '../../Syn/backgrounds';
import './CreateProfileUser.css';

export const CreateProfileUser = () => {
  const navigate = useNavigate();
  const client = generateClient();
  const { login } = useAuth(); 

  const [profilePicture, setProfilePicture] = useState({ file: null, previewUrl: '' });
  const [backgroundSelection, setBackgroundSelection] = useState({ file: null, name: '', previewUrl: '' });
  const [profilePictureKey, setProfilePictureKey] = useState(null);
  const [backgroundPictureKey, setBackgroundPictureKey] = useState(null);

  const [isDefaultBackgroundModalOpen, setIsDefaultBackgroundModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState('');
  const [resendCodeLoading, setResendCodeLoading] = useState(false);
  const [confirmationError, setConfirmationError] = useState('');

  const [user, setUser] = useState('');
  const [userId, setUserId] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [usernameValid, setUsernameValid] = useState(false);
  const [password, setPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');

  const [userError, setUserError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [retypePasswordError, setRetypePasswordError] = useState('');
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [imageToCrop, setImageToCrop] = useState(null);
  const [imageCropModalOpen, setImageCropModalOpen] = useState(false);
  const [croppingFor, setCroppingFor] = useState('');

  const requiredFields = ['user', 'email', 'password', 'retypePassword'];

  const isFieldValid = (fieldName) => {
    if (requiredFields.includes(fieldName)) {
      return Boolean((fieldName));
    }
    return true;
  };

  const handleTermsClick = () => {
    navigate("/terms");
  };

  const handleUsernameCheck = (isAvailable, isValidFormat) => {
    if (!isValidFormat) {
      setUserError('Invalid character');
      setUsernameValid(false);
    } else if (isAvailable === null) {
      setUserError('');
      setUsernameValid(false);
    } else if (!isAvailable) {
      setUserError('Username already exists');
      setUsernameValid(false);
    } else {
      setUserError('');
      setUsernameValid(true);
    }
  };

  const handlePhoneChange = (e) => {
    const digits = e.target.value.replace(/\D/g, '');
    
    let formattedValue = '';
  
    const parts = [
      digits.slice(0, 3), 
      digits.slice(3, 6), 
      digits.slice(6, 10)
    ];
  
    if (parts[0].length) {
      formattedValue += parts[0];
      if (parts[0].length === 3) {
        formattedValue += '-';
      }
    }
    if (parts[1].length) {
      formattedValue += parts[1];
      if (parts[1].length === 3) {
        formattedValue += '-';
      }
    }
    formattedValue += parts[2];
  
    setPhone(formattedValue);
  };
  
  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,}$/;
    return passwordRegex.test(password);
  };
  
  const handlePasswordChange = (e) => {
    const newPass = e.target.value;
    setPassword(newPass);
    setPasswordValid(validatePassword(newPass));
    setPasswordsMatch(newPass === retypePassword);
  };
  
  const handleRetypePasswordChange = (e) => {
    const newRetypePass = e.target.value;
    setRetypePassword(newRetypePass);
    setPasswordsMatch(newRetypePass === password);
  };
  
  const getPasswordInputClass = () => {
    if (password.length === 0) return '';
    return passwordValid ? 'valid' : 'invalid';
  };
  
  const getRetypePasswordInputClass = () => {
    if (retypePassword.length === 0) return '';
    return passwordsMatch ? 'valid' : 'invalid';
  };

  const handleDefaultBackgroundModal = () => {
    setIsDefaultBackgroundModalOpen(!isDefaultBackgroundModalOpen);
  };

  const handleSelectBackground = async (backgroundName) => {
    const selectedBackground = backgrounds.find(bg => bg.name === backgroundName);
    const response = await fetch(selectedBackground.src);
    if (response.ok) {
      const blob = await response.blob();
      setBackgroundSelection({ file: blob, name: selectedBackground.name, previewUrl: URL.createObjectURL(blob) });
      handleDefaultBackgroundModal();
    } else {
      console.error('Failed to fetch background image:', response.statusText);
    }
  };

  const handleProfilePictureChange = (event) => {
    if (event.target.files[0]) {
      setImageToCrop(URL.createObjectURL(event.target.files[0]));
      setCroppingFor('profilePicture');
      setImageCropModalOpen(true);
    }
  };

  const handleBackgroundPictureChange = (event) => {
    if (event.target.files[0]) {
      setImageToCrop(URL.createObjectURL(event.target.files[0]));
      setCroppingFor('backgroundPicture');
      setImageCropModalOpen(true);
    }
  };

  const handleCropComplete = (croppedBlob) => {
    if (croppingFor === 'profilePicture') {
      setProfilePicture({
        file: croppedBlob,
        previewUrl: URL.createObjectURL(croppedBlob),
      });
    } else if (croppingFor === 'backgroundPicture') {
      setBackgroundSelection({
        file: croppedBlob,
        previewUrl: URL.createObjectURL(croppedBlob),
      });
    }
  };

  const handleSignUpConfirmation = async (code) => {
    try {
      const result = await confirmSignUp({
        username: email,
        confirmationCode: code,
      });

      if (result.isSignUpComplete) {
        console.log('Sign-up confirmation successful');
        setConfirmationModalOpen(false);

        await login(email, password);
        console.log('User logged in successfully');

        const input = {
          id: userId,
          email,
          user,
          name,
          phone: phone.match(/^\d{3}-\d{3}-\d{4}$/) ? phone : null,
          profilepicturekey: profilePictureKey,
          backgroundpicturekey: backgroundPictureKey,
          invoiceNotifications: true,
          messageNotifications: true,
          paymentNotifications: true,
          requestSatusNotifications: true,
        };

        const createUserResponse = await client.graphql({
          query: createUser,
          variables: { input },
        });
        console.log('User created successfully!', createUserResponse);

        navigate(`/user/${user}`);
      } else {
        console.log('Additional steps required:', result.nextStep);
      }
    } catch (error) {
      console.error('Error confirming sign up:', error);
      setConfirmationError('Invalid verification code provided, please try again.');
    }
  };

  const handleResendCode = async () => {
    setResendCodeLoading(true);
    try {
      await resendSignUpCode(email);
      setResendCodeLoading(false);
    } catch (error) {
      console.error('Error resending code:', error);
      setResendCodeLoading(false);
    }
  };

  const generateUniqueKey = (folder, user) => {
    return `${folder}/${Date.now()}_${Math.random().toString(36).substr(2, 9)}_${user}`;
  };

  const uploadToS3 = async (file, folder, user) => {
    const key = generateUniqueKey(folder, user);
    try {
      const result = await uploadData({
        key: key,
        data: file,
        options: {
          contentType: file.type,
          level: 'public',
        }
      });
  
      console.log('File uploaded successfully:', result);
      return key;
    } catch (error) {
      console.error('Error uploading:', error);
      throw error;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let hasErrors = false;

    setUserError('');
    setEmailError('');
    setPasswordError('');
    setRetypePasswordError('');

    requiredFields.forEach((fieldName) => {
      if (!isFieldValid(fieldName)) {
        if (fieldName === 'user') {
          setUserError('Username is required');
        } else if (fieldName === 'email') {
          setEmailError('Email is required');
        } else if (fieldName === 'password') {
          setPasswordError('Password is required');
        } else if (fieldName === 'retypePassword') {
          setRetypePasswordError('Retype Password is required');
        }
        hasErrors = true;
      }
    });

    if (hasErrors || !usernameValid || !passwordValid || !passwordsMatch) {
      return;
    }

    try {
      const uploadedProfilePictureKey = profilePicture.file 
          ? await uploadToS3(profilePicture.file, 'profile', user)
          : null;
  
      setProfilePictureKey(uploadedProfilePictureKey);
  
      let uploadedBackgroundPictureKey = null;
  
      if (backgroundSelection.file instanceof Blob || backgroundSelection.file instanceof File) {
          uploadedBackgroundPictureKey = await uploadToS3(backgroundSelection.file, 'background', user);
      } else {
          uploadedBackgroundPictureKey = backgroundSelection.name;
      }
  
      setBackgroundPictureKey(uploadedBackgroundPictureKey);

      await handleSignUp({
        user,
        name,
        email,
        phone,
        profilepicturekey: uploadedProfilePictureKey,
        backgroundpicturekey: uploadedBackgroundPictureKey
      });
    } catch (error) {
      console.error('Error during signup:', error);
      setEmailError('An unexpected error occurred during sign-up.');
    }
  };

  const handleSignUp = async () => {
    if (!usernameValid || !passwordValid || !passwordsMatch) {
      console.error('Validation failed.');
      return;
    }

    try {
      const signUpResult = await signUp({
        username: email,
        password,
        attributes: {
          'custom:username': user,
          ...(phone ? { phone_number: phone } : {}),
        }
      });

      console.log('Signup success:', signUpResult);
      setUserId(signUpResult.userId);
      setConfirmationModalOpen(true);
    } catch (error) {
      console.error('Signup failed:', error);
      setEmailError(error.message || 'Failed to sign up.');
    }
  };

  return (
    <div className="uc-create-profile">
      <div className="create-profile-container">
        <div className={`label ${userError ? 'error' : ''}`}>
          <div className="create-profile-header">User</div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="attach-profile-picture">
            <label htmlFor="profilePictureInput" className="file-input-profile-picture">
              <span className="button-text">Choose Profile Picture</span>
              <input
                type="file"
                id="profilePictureInput"
                accept="image/*"
                onChange={handleProfilePictureChange}
                className="file-input"
              />
            </label>
            {profilePicture.previewUrl && (
              <div>
                <img src={profilePicture.previewUrl} alt="Profile Preview" className="profile-picture-preview" />
                <div className="file-name-display">{profilePicture.file?.name || 'Current Picture'}</div>
              </div>
            )}
          </div>
          <div className="background-picture-options">
            <button type="button" className="default-background-button" onClick={handleDefaultBackgroundModal}>
              Choose Default Background
            </button>
            <span className="or-divider">or</span>
            <label htmlFor="backgroundPictureInput" className="file-input-background">
              <span className="background-button">Choose Background Picture</span>
              <input
                type="file"
                id="backgroundPictureInput"
                accept="image/*"
                onChange={handleBackgroundPictureChange}
                className="file-input"
              />
            </label>
            {isDefaultBackgroundModalOpen && (
              <DefaultBackgroundModal
                isOpen={isDefaultBackgroundModalOpen}
                onClose={handleDefaultBackgroundModal}
                onSelectBackground={handleSelectBackground}
              />
            )}
          </div>
          {backgroundSelection.previewUrl && (
            <div>
              <img src={backgroundSelection.previewUrl} alt="Background Preview" className="background-picture-preview" />
              <div className="file-name-display">{backgroundSelection.name || 'Current Background'}</div>
            </div>
          )}
          <div className="username">
            <CheckUsername
                userType="User"
                onUsernameCheck={handleUsernameCheck}
                onChange={(e) => setUser(e.target.value)}
            />
            {userError && <div className="error-message">{userError}</div>}
          </div>
          <div className="name">
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="email">
            <input
              type="email"
              placeholder="*Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailError && <div className="error-message">{emailError}</div>}
          </div>
          <div className="phone-number">
            <input
              type="text"
              placeholder="Phone Number"
              value={phone}
              onChange={handlePhoneChange}
              maxLength="12" 
            />
          </div>
          <div className="password">
            <input
              type="password"
              placeholder="*Password"
              value={password}
              onChange={handlePasswordChange}
              className={getPasswordInputClass()}
            />
            <div className="error-message">{passwordError}</div>
          </div>
          <div className="retype-password">
            <input
              type="password"
              placeholder="*Retype Password"
              value={retypePassword}
              onChange={handleRetypePasswordChange}
              className={getRetypePasswordInputClass()}
            />
            <div className="error-message">{retypePasswordError}</div>
          </div>
          <div className="create-terms-container">
            By creating a profile you agree to the
            <span className="terms-click" onClick={handleTermsClick}>
              Terms of Service
            </span>
          </div>
          <button type="submit" className="create-profile-button">
            Create User Profile
          </button>
        </form>
        <ConfirmationModal
          isOpen={confirmationModalOpen}
          onClose={() => setConfirmationModalOpen(false)}
          onConfirm={handleSignUpConfirmation}
          setCode={setConfirmationCode}
          code={confirmationCode}
          errorMessage={confirmationError}
          resendCode={handleResendCode}
          username={email}
        />
        <ImageCropModal
          isOpen={imageCropModalOpen}
          imageSrc={imageToCrop}
          onClose={() => setImageCropModalOpen(false)}
          onCropComplete={handleCropComplete}
          aspectRatio={croppingFor === 'profilePicture' ? 1 / 1 : 5 / 1}
        />
      </div>
    </div>
  );
};

export default CreateProfileUser;
