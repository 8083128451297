import React, { useState, useEffect, useRef } from "react";
import "./CompanySavedReviews.css";
import { useNavigate } from "react-router-dom";
import { generateClient } from 'aws-amplify/api';
import { listSavedReviews, getReview, getUser } from '../../../graphQL/queries';
import { createSavedReview, deleteSavedReview } from '../../../graphQL/mutations';
import { useAuth } from "../../../auth/auth";
import { format } from 'date-fns';
import ImageModal from "../../Syn/ImageModal/ImageModal";

const CompanySavedReviews = () => {
  const [savedReviews, setSavedReviews] = useState([]);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [currentAttachments, setCurrentAttachments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const reviewsPerPage = 7;
  const navigate = useNavigate();
  const client = generateClient();
  const { user } = useAuth();
  const menuRefs = useRef({});

  const openModal = (index, attachments) => {
    setSelectedImageIndex(index);
    setCurrentAttachments(attachments);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const fetchSavedReviews = async () => {
    if (!user || !user.id) {
      console.error("No user ID available.");
      return;
    }

    try {
      const savedData = await client.graphql({
        query: listSavedReviews,
        variables: { filter: { savedID: { eq: user.id } } }
      });

      const savedReviewItems = savedData.data.listSavedReviews.items;

      const savedReviewsWithUserData = await Promise.all(savedReviewItems.map(async (item) => {
        try {
          const reviewData = await client.graphql({
            query: getReview,
            variables: { id: item.reviewID }
          });

          const review = reviewData.data.getReview;

          const userData = await client.graphql({
            query: getUser,
            variables: { id: review.userID }
          });

          const user = userData.data.getUser;

          const userWithImageUrls = {
            ...user,
            profilePictureUrl: user?.profilepicturekey
              ? `https://media.spiggl.com/public/${user.profilepicturekey}`
              : "placeholder-profile-image.png"
          };

          const reviewWithAttachmentUrls = {
            ...review,
            attachments: review.attachmentkey ? review.attachmentkey.map(att => `https://media.spiggl.com/public/${att}`) : []
          };

          return { ...reviewWithAttachmentUrls, user: userWithImageUrls, savedReviewId: item.id };
        } catch (error) {
          console.error('Error fetching review or user data:', error);
          return null;
        }
      }));

      setSavedReviews(savedReviewsWithUserData.filter(review => review !== null));
    } catch (error) {
      console.error("Error fetching saved reviews:", error);
    }
  };

  useEffect(() => {
    fetchSavedReviews();
  }, [user]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      Object.keys(menuRefs.current).forEach(key => {
        if (menuRefs.current[key] && !menuRefs.current[key].contains(event.target)) {
        }
      });
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const renderStars = (rating) => {
    return [...Array(5)].map((e, i) => (
      <span key={i} className={`star ${i < rating ? "filled" : "empty"}`}>★</span>
    ));
  };

  const renderAttachments = (attachments) => {
    const additionalCount = attachments.length > 4 ? attachments.length - 4 : 0;

    return (
      <div className="company-saved-review-attachments">
        {attachments.slice(0, 4).map((attachment, index) => (
          <div key={index} className="saved-attachment-wrapper" onClick={() => openModal(index, attachments)}>
            <img
              src={attachment}
              alt={`Attachment ${index + 1}`}
              className="company-saved-review-attachments__icon"
            />
            {index === 3 && additionalCount > 0 && (
              <div className="overlay">
                +{additionalCount}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const navigateToUserProfile = (user) => {
    navigate(`/user/${user}`);
  };

  const toggleDescriptionExpansion = (reviewId) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [reviewId]: !prev[reviewId]
    }));
  };

  const renderDescription = (description, reviewId) => {
    const limit = 550;
    const isExpanded = expandedDescriptions[reviewId];

    return (
      <div className="review-description">
        {isExpanded ? description : `${description.slice(0, limit)}`}
        {description.length > limit && (
          <button className="more-button" onClick={() => toggleDescriptionExpansion(reviewId)}>
            {isExpanded ? 'Show less' : 'More'}
          </button>
        )}
      </div>
    );
  };

  const handleSaveReview = async (reviewId) => {
    const savedReview = savedReviews.find(review => review.id === reviewId);
    if (savedReview) {
      try {
        await client.graphql({
          query: deleteSavedReview,
          variables: { input: { id: savedReview.savedReviewId } },
        });
        setSavedReviews((prev) => prev.filter(review => review.id !== reviewId));
      } catch (error) {
        console.error("Error unsaving review:", error);
      }
    } else {
      try {
        await client.graphql({
          query: createSavedReview,
          variables: { input: { savedID: user.id, reviewID: reviewId } },
        });
        fetchSavedReviews();
      } catch (error) {
        console.error("Error saving review:", error);
      }
    }
  };

  const indexOfLastReview = currentPage * reviewsPerPage;
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
  const currentReviews = savedReviews.slice(indexOfFirstReview, indexOfLastReview);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(savedReviews.length / reviewsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0); 
  };

  return (
    <div className="company-saved-reviews-container">
      <ImageModal 
        show={showModal} 
        attachments={currentAttachments} 
        selectedIndex={selectedImageIndex} 
        onClose={closeModal}
        onNext={() => setSelectedImageIndex((prevIndex) => (prevIndex + 1) % currentAttachments.length)}
        onPrev={() => setSelectedImageIndex((prevIndex) => (prevIndex - 1 + currentAttachments.length) % currentAttachments.length)}
      />
      {currentReviews.length === 0 ? (
        <div className="no-posts-container">
          <p>No Saved Reviews</p>
          <img src="/icons/write.png" alt="No posts" className="no-posts-icon" />
        </div>
      ) : (
        currentReviews.map((review) => (
          <div className="company-review" key={review.id}>
            <div className="company-review-header">
              {review.user?.profilePictureUrl && (
                <img src={review.user.profilePictureUrl} alt="User Profile" />
              )}
              <div className="user-id-container">
                <span
                  className="user-id"
                  onClick={() => navigateToUserProfile(review.user?.user)}
                  style={{ cursor: "pointer" }}
                >
                  {review.user?.name || review.user?.userName}
                </span>
                <div className="review-menu" ref={el => menuRefs.current[review.id] = el}>
                  <span className="review-date">{format(new Date(review.createdAt), 'M/d/yy')}</span>
                  <button
                    className={`save-button ${savedReviews.find(saved => saved.id === review.id) ? 'saved' : ''}`}
                    onClick={() => handleSaveReview(review.id)}
                  >
                    {savedReviews.find(saved => saved.id === review.id) ? 'Saved' : 'Save'}
                  </button>
                </div>
              </div>
            </div>
            <div className="company-review-title">{review.title}</div>
            <div className="company-review-body">
              <div className="star-ratings-container">
                <div className="company-review-ratings">
                  <div className="star-ratings">
                    <span>Quality:</span>
                    {renderStars(review.quality)}
                  </div>
                  <div className="star-ratings">
                    <span>Cost:</span>
                    {renderStars(review.cost)}
                  </div>
                  <div className="star-ratings">
                    <span>Timeliness:</span>
                    {renderStars(review.timeliness)}
                  </div>
                  <div className="star-ratings">
                    <span>Overall:</span>
                    {renderStars(review.overall)}
                  </div>
                </div>
              </div>
              <div className="company-review-attachments">
                {renderAttachments(review.attachments || [])}
              </div>
            </div>
            <div className="company-review-description">
              {renderDescription(review.description, review.id)}
            </div>
          </div>
        ))
      )}
      <div className="company-pagination">
        {pageNumbers.map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => handlePageClick(pageNumber)}
            className={`page-number ${currentPage === pageNumber ? 'active' : ''}`}
          >
            {pageNumber}
          </button>
        ))}
      </div>
    </div>
  );  
};

export default CompanySavedReviews;
