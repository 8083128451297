import React, { useState, useEffect } from "react";
import './InternalUserProfile.css';
import { generateClient } from "aws-amplify/api";
import { useAuth } from "../../../auth/auth";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as queries from "../../../graphQL/queries";
import ProfileHeader from '../../Headers/ProfileHeader/ProfileHeader';
import Footer from '../../Footer/Footer';
import UserPastReviews from "../UserPastReviews/UserPastReviews";
import UserSavedReviews from "../UserSavedReviews/UserSavedReviews";

export const InternalUserProfile = () => {
  const navigate = useNavigate();
  const { userID } = useAuth();
  const client = generateClient();
  const [activeTab, setActiveTab] = useState("past reviews");
  const [userData, setUserData] = useState({});
  const [background, setBackground] = useState("/icons/default-background.jpg");
  const [profilePicture, setProfilePicture] = useState("");

  const handleEditProfileClick = () => {
    navigate(`/user/${userData.user}/edit-profile`);
  };

  const handlePostReviewClick = () => {
    navigate(`/user/${userData.user}/post-review`);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (userID) {
        try {
          const { data } = await client.graphql({
            query: queries.getUser,
            variables: { id: userID },
          });
    
          if (data.getUser) {
            const userDataWithImageUrls = {
              ...data.getUser,
              backgroundpicturekey: data.getUser.backgroundpicturekey,
              profilepicturekey: data.getUser.profilepicturekey || null,
            };
            setUserData(userDataWithImageUrls);
    
            if (data.getUser.backgroundpicturekey) {
              setBackground(
                `https://media.spiggl.com/public/${data.getUser.backgroundpicturekey}`
              ); 
            } else {
              setBackground("/icons/default-background.jpg");
            }
    
            if (data.getUser.profilepicturekey) {
              setProfilePicture(
                `https://media.spiggl.com/public/${data.getUser.profilepicturekey}`
              );
            } else {
              setProfilePicture(createDefaultProfileImage(data.getUser.user));
            }
          } else {
            console.error('User not found with id:', userID);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        console.error('No user ID provided in URL parameters.');
      }
    };

    fetchUserData();
  }, [userID, client]);

  function createDefaultProfileImage(user) {
    const firstChar = user ? user.charAt(0).toUpperCase() : '';
    return `data:image/svg+xml;base64,${btoa(`<svg xmlns="http://www.w3.org/2000/svg" width="150" height="150">
      <circle cx="75" cy="75" r="75" fill="#23313d"/>
      <text x="50%" y="50%" font-family="Arial, sans-serif" font-size="64" dy=".3em" fill="#FFF" text-anchor="middle">${firstChar}</text>
    </svg>`)}`;
  }

  return (
    <div className="user-profile">
      <ProfileHeader />
      <Helmet>
        <title>{userData.user}</title>
      </Helmet>
        <div className="ei-user">
          <div className="background-picture">
            <img src={background} alt="User Background" />
            <div className="profile-picture">
              <img src={profilePicture || createDefaultProfileImage(userData.user)} alt="User Profile" />
            </div>
          </div>
          <div className="user-white-background-container">
            <div className="user-info">
              <div className="user-info-container">
                <div className="profile-user-name">{userData.user}</div>
                <div className="profile-user-real-name">{userData.name || <span>&nbsp;</span>}</div>
              </div>
              <div className="user-profile-buttons">
                <div onClick={handlePostReviewClick} className="post-review">
                  <div className="create-post-button">
                    <img src="/icons/plus.png" alt="Create Post" />
                    review
                  </div>
                </div>
                <div onClick={handleEditProfileClick} className="user-profile-edit-profile">
                  <img src="/icons/edit.png" alt="Edit Profile" />
                </div>
              </div>
            </div>
          </div>
          <div className="user-review-tabs">
            <button
              className={activeTab === "past reviews" ? "active" : ""}
              onClick={() => handleTabClick("past reviews")}
            >
              Past Reviews
            </button>
            <button
              className={activeTab === "saved reviews" ? "active" : ""}
              onClick={() => handleTabClick("saved reviews")}
            >
              Saved Reviews
            </button>
          </div>
        </div>
        <div className="which-review">
          {activeTab === "past reviews" && <UserPastReviews userID={userID} />}
          {activeTab === "saved reviews" && <UserSavedReviews userID={userID} />}
        </div>
      <Footer />
    </div>
  );
};

export default InternalUserProfile;
