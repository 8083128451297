import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from './cropImage';
import './ImageCropModal.css';

const ImageCropModal = ({ isOpen, imageSrc, onClose, onCropComplete: handleCropCompleteProp, aspectRatio }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleCrop = async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      handleCropCompleteProp(croppedImage);
      onClose();
    } catch (error) {
      console.error('Error cropping image:', error);
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="image-crop-modal-overlay">
      <div className="image-crop-modal">
        <div className="crop-container">
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={aspectRatio}
            onCropChange={onCropChange}
            onZoomChange={onZoomChange}
            onCropComplete={onCropComplete}
          />
        </div>
        <div className="controls">
          <button className="cancel-button" onClick={onClose}>Cancel</button>
          <button onClick={handleCrop}>Crop</button>
        </div>
      </div>
    </div>
  );
};

export default ImageCropModal;
