import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import { uploadData } from 'aws-amplify/storage';
import { createReview } from '../../../graphQL/mutations';
import * as queries from '../../../graphQL/queries';
import { Helmet } from 'react-helmet';
import FormHeader from '../../Headers/FormHeader/FormHeader';
import SearchCompany from '../../Syn/SearchCompany/SearchCompany';
import { useAuth } from '../../../auth/auth';
import './PostReview.css';

const PostReview = ( ) => {
  const navigate = useNavigate();
  const location = useLocation();
  const client = generateClient();
  const { user } = useAuth();
  const [companyName, setCompanyName] = useState(location.state?.companyUser || '');
  const [initialCompany, setInitialCompany] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [qualityRating, setQualityRating] = useState(0);
  const [timelinessRating, setTimelinessRating] = useState(0);
  const [costRating, setCostRating] = useState(0);
  const [overallRating, setOverallRating] = useState(0);
  const [reviewTitle, setReviewTitle] = useState('');
  const [reviewDescription, setReviewDescription] = useState('');
  const [selectedCompany, setSelectedCompany] = useState(null);

  const [companyNameError, setCompanyNameError] = useState('');
  const [attachmentsError, setAttachmentsError] = useState('');
  const [qualityRatingError, setQualityRatingError] = useState('');
  const [timelinessRatingError, setTimelinessRatingError] = useState('');
  const [costRatingError, setCostRatingError] = useState('');
  const [overallRatingError, setOverallRatingError] = useState('');
  const [reviewTitleError, setReviewTitleError] = useState('');
  const [reviewDescriptionError, setReviewDescriptionError] = useState('');

  const [qualityHover, setQualityHover] = useState(0);
  const [costHover, setCostHover] = useState(0);
  const [timelinessHover, setTimelinessHover] = useState(0);
  const [overallHover, setOverallHover] = useState(0);

  useEffect(() => {
    if (companyName) {
      fetchCompanyDetails(companyName);
    }
  }, [companyName]);

  useEffect(() => {
    if (location.state?.companyUser) {
      fetchCompanyDetails(location.state.companyUser);
    }
  }, [location.state]);

  const fetchCompanyDetails = async (companyUser) => {
    try {
      const response = await client.graphql({
        query: queries.listCompanies,
        variables: {
          filter: {
            companyUser: {
              eq: companyUser,
            },
          },
        },
      });
  
      const companies = response.data.listCompanies.items;
      if (companies.length > 0) {
        setSelectedCompany(companies[0]);
        setInitialCompany(companies[0]);
      } else {
        setSelectedCompany(null);
        setInitialCompany(null);
      }
    } catch (err) {
      console.error('Error fetching companies:', err);
      setCompanyNameError('Error fetching companies');
    }
  };

  const handleAttachmentsChange = (event) => {
    const newAttachments = Array.from(event.target.files);
    setAttachments((prevAttachments) => {
      const uniqueAttachments = newAttachments.filter(
        (newFile) => !prevAttachments.some((file) => file.name === newFile.name)
      );
      return [...prevAttachments, ...uniqueAttachments];
    });
    setAttachmentsError('');
  };

  const handleRemoveAttachment = (index) => {
    setAttachments((prevAttachments) => prevAttachments.filter((_, i) => i !== index));
  };

  const countWords = (str) => {
    return str.trim().split(/\s+/).length;
  };

  const handleReviewTitleChange = (event) => {
    const value = event.target.value;
    const wordCount = countWords(value);
    if (value.length <= 160 && wordCount <= 20) {
      setReviewTitle(value);
      setReviewTitleError('');
    } else {
      setReviewTitleError('Title is too long');
    }
  };

  const handleReviewDescriptionChange = (event) => {
    setReviewDescription(event.target.value);
    setReviewDescriptionError('');
  };

  const handleStarClick = (category, rating) => {
    switch (category) {
      case 'quality':
        setQualityRating(Math.round(rating));
        setQualityRatingError('');
        break;
      case 'timeliness':
        setTimelinessRating(Math.round(rating));
        setTimelinessRatingError('');
        break;
      case 'cost':
        setCostRating(Math.round(rating));
        setCostRatingError('');
        break;
      case 'overall':
        setOverallRating(Math.round(rating));
        setOverallRatingError('');
        break;
      default:
        break;
    }
  };
  
  const generateUniqueKey = (file) => {
    return `reviews/${Date.now()}_${Math.random().toString(36).substr(2, 9)}_${file.name}`;
  };

  const uploadToS3 = async (file) => {
    const key = generateUniqueKey(file);
    try {
      const result = await uploadData({
        key,
        data: file,
        options: {
          contentType: file.type,
          level: 'public',
        },
      });
      console.log('File uploaded successfully:', key);
      return key;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let hasErrors = false;

    if (!companyName) {
      setCompanyNameError('Please enter a company name');
      hasErrors = true;
      return;
    }

    if (!selectedCompany) {
      setCompanyNameError('Company name not recognized');
      hasErrors = true;
      return;
    }

    if (qualityRating === 0) {
      setQualityRatingError('Please rate quality');
      hasErrors = true;
      return;
    }
    if (timelinessRating === 0) {
      setTimelinessRatingError('Please rate timeliness');
      hasErrors = true;
      return;
    }
    if (costRating === 0) {
      setCostRatingError('Please rate cost');
      hasErrors = true;
      return;
    }
    if (overallRating === 0) {
      setOverallRatingError('Please rate overall');
      hasErrors = true;
      return;
    }

    if (hasErrors) {
      return;
    }

    const attachmentKeys = await Promise.all(
      attachments.map(file => uploadToS3(file))
    );

    const input = {
      companyID: selectedCompany.id,
      userID: user.userId,
      attachmentkey: attachmentKeys.map(key => key),
      quality: qualityRating,
      timeliness: timelinessRating,
      cost: costRating,
      overall: overallRating,
      title: reviewTitle,
      description: reviewDescription,
    };

    console.log('Review input:', input);

    try {
      const response = await client.graphql({
        query: createReview,
        variables: { input },
      });

      console.log('Review posted successfully:', response.data.createReview);
      navigate(-1);
    } catch (error) {
      console.error('Error posting review:', error);
    }
  };

  return (
    <div className="post-review">
      <FormHeader />
      <Helmet>
        <title>Post Review</title>
      </Helmet>
      <div className="post-review-container">
        <form onSubmit={handleSubmit} className="review-form">
          <div className="form-header">Post Review</div>
          <div className="rating-container">
            <div className="form-field">
              <label className="review-rating-label">Quality:</label>
              <div className="ratings">
                {[1, 2, 3, 4, 5].map((rating) => (
                  <span
                    key={rating}
                    className={`star ${rating <= (qualityHover || qualityRating) ? 'active' : ''}`}
                    onMouseEnter={() => setQualityHover(rating)}
                    onMouseLeave={() => setQualityHover(0)}
                    onClick={() => handleStarClick('quality', rating)}
                  >
                    ★
                  </span>
                ))}
              </div>
              <div className="error-message">{qualityRatingError}</div>
            </div>
            <div className="form-field">
              <label className="review-rating-label">Cost:</label>
              <div className="ratings">
                {[1, 2, 3, 4, 5].map((rating) => (
                  <span
                    key={rating}
                    className={`star ${rating <= (costHover || costRating) ? 'active' : ''}`}
                    onMouseEnter={() => setCostHover(rating)}
                    onMouseLeave={() => setCostHover(0)}
                    onClick={() => handleStarClick('cost', rating)}
                  >
                    ★
                  </span>
                ))}
              </div>
              <div className="error-message">{costRatingError}</div>
            </div>
            <div className="form-field">
              <label className="review-rating-label">Timeliness:</label>
              <div className="ratings">
                {[1, 2, 3, 4, 5].map((rating) => (
                  <span
                    key={rating}
                    className={`star ${rating <= (timelinessHover || timelinessRating) ? 'active' : ''}`}
                    onMouseEnter={() => setTimelinessHover(rating)}
                    onMouseLeave={() => setTimelinessHover(0)}
                    onClick={() => handleStarClick('timeliness', rating)}
                  >
                    ★
                  </span>
                ))}
              </div>
              <div className="error-message">{timelinessRatingError}</div>
            </div>
            <div className="form-field">
              <label className="review-rating-label">Overall:</label>
              <div className="ratings">
                {[1, 2, 3, 4, 5].map((rating) => (
                  <span
                    key={rating}
                    className={`star ${rating <= (overallHover || overallRating) ? 'active' : ''}`}
                    onMouseEnter={() => setOverallHover(rating)}
                    onMouseLeave={() => setOverallHover(0)}
                    onClick={() => handleStarClick('overall', rating)}
                  >
                    ★
                  </span>
                ))}
              </div>
              <div className="error-message">{overallRatingError}</div>
            </div>
          </div>
          <div className="form-field">
            <SearchCompany
              userId={user.userId}
              initialCompany={initialCompany}
              onSelectResult={(result) => {
                setCompanyName(result.companyUser);
                setSelectedCompany(result);
              }}
            />
            <div className="error-message">{companyNameError}</div>
          </div>
          <div className="form-field">
            <input
              type="text"
              placeholder="Review Title"
              value={reviewTitle}
              onChange={handleReviewTitleChange}
            />
            <div className="error-message">{reviewTitleError}</div>
          </div>
          <div className="form-field">
            <textarea
              placeholder="Review Description"
              value={reviewDescription}
              onChange={handleReviewDescriptionChange}
            />
            <div className="error-message">{reviewDescriptionError}</div>
          </div>
          <div className="form-field">
            <label htmlFor="fileInput" className="file-input-label">
              <span className="button-text">Choose File(s)</span>
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                multiple
                onChange={handleAttachmentsChange}
                className="file-input"
              />
            </label>
            {attachments && attachments.length > 0 && (
              <div className="file-name-display">
                {attachments.map((file, index) => (
                  <div key={index} className="file-item">
                    <span>{file.name}</span>
                    <button type="button" className="remove-button" onClick={() => handleRemoveAttachment(index)}>x</button>
                  </div>
                ))}
              </div>
            )}
            <div className="error-message">{attachmentsError}</div>
          </div>
          <button className="post-button" type="submit">
            Post
          </button>
        </form>
      </div>
    </div>
  );
};

export default PostReview;
