import React, { useState } from "react";
import { generateClient } from "aws-amplify/api";
import { createReport } from "../../../graphQL/mutations";
import "./ReportModal.css";

const ReportModal = ({ conversationID, userID, reportedUserID, onClose, onSubmit }) => {
  const [selectedReason, setSelectedReason] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const client = generateClient();

  const reasons = [
    "Spam",
    "Harassment",
    "Inappropriate Content",
    "Fake Account",
    "Other",
  ];

  const handleReasonSelect = (reason) => {
    setSelectedReason(reason);
  };

  const handleSubmitReport = async () => {
    try {
      await client.graphql({
        query: createReport,
        variables: {
          input: {
            conversationID,
            userID,
            reportedUserID,
            reasonGiven: selectedReason,
            reasonWhy: additionalInfo,
          },
        },
      });

      await invokeReportEmailLambda(conversationID, userID, reportedUserID, selectedReason, additionalInfo);

      setSubmitted(true);
      onSubmit(selectedReason);
    } catch (error) {
      console.error("Error submitting report:", error);
    }
  };

  const invokeReportEmailLambda = async (conversationID, userID, reportedUserID, reasonGiven, reasonWhy) => {
    const API_ENDPOINT = process.env.REACT_APP_EMAIL_REPORT_NOTIFICATION_API;

    try {
      const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          conversationID,
          userID,
          reportedUserID,
          reasonGiven,
          reasonWhy,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to send report notification');
      }

      console.log('Report notification sent successfully');
    } catch (error) {
      console.error('Error sending report notification:', error);
    }
  };

  return (
    <div className="report-modal-overlay">
      <div className="report-modal-content">
        <button className="close-button" onClick={onClose}>
          ×
        </button>
        {!submitted ? (
          <>
            {!selectedReason ? (
              <div className="report-reason-step">
                <h2>Report Conversation</h2>
                <ul className="report-reasons-list">
                  {reasons.map((reason) => (
                    <li key={reason} onClick={() => handleReasonSelect(reason)}>
                      {reason}
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div className="report-details-step">
                <h2>Report: {selectedReason}</h2>
                <div>Optional:</div>
                <textarea
                  placeholder="Provide additional details"
                  value={additionalInfo}
                  onChange={(e) => setAdditionalInfo(e.target.value)}
                />
                <div className="report-buttons">
                  <button onClick={() => setSelectedReason("")}>Back</button>
                  <button onClick={handleSubmitReport}>Submit</button>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="report-submitted-message">
            <h3>Report Submitted ✓</h3>
            <p>Thank you for reporting. We will review the conversation.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportModal;
