import React from 'react';
import './PaymentLoader.css';

const PaymentLoader = () => {
  return (
    <div className="payment-loader-modal">
      <div className="payment-loader-container">
        <svg
          className="loader-svg"
          viewBox="0 -30 60 200"
        >
          <path
            className="loader-path"
            d="M 0 65.3 L 4.8 57.3 Q 8.4 60.3 12.9 62.2 A 23.348 23.348 0 0 0 18.27 63.71 A 32.118 32.118 0 0 0 23.4 64.1 A 27.981 27.981 0 0 0 27.947 63.754 Q 30.37 63.354 32.331 62.494 A 13.701 13.701 0 0 0 34.8 61.1 Q 38.848 58.209 38.995 53.366 A 12.116 12.116 0 0 0 39 53 A 11.94 11.94 0 0 0 38.663 50.107 A 9.816 9.816 0 0 0 37.8 47.85 A 8.097 8.097 0 0 0 36.6 46.194 Q 35.33 44.813 33.184 43.471 A 25.81 25.81 0 0 0 33.15 43.45 Q 30.034 41.508 24.265 39.28 A 118.584 118.584 0 0 0 23 38.8 Q 15.8 36 11.5 33.15 A 21.931 21.931 0 0 1 8.411 30.703 Q 6.694 29.053 5.638 27.188 A 13.303 13.303 0 0 1 5.35 26.65 Q 3.5 23 3.5 18 Q 3.5 13 6.25 8.95 Q 9 4.9 14.35 2.45 A 25.558 25.558 0 0 1 20.25 0.615 Q 23.493 0 27.3 0 Q 33.5 0 38.45 1.25 Q 43.4 2.5 48.1 4.8 L 44.6 12.9 A 31.496 31.496 0 0 0 40.474 10.881 A 41.686 41.686 0 0 0 36.65 9.55 Q 32.1 8.2 27.1 8.2 A 27.187 27.187 0 0 0 23.07 8.48 Q 20.938 8.8 19.2 9.487 A 12.616 12.616 0 0 0 16.7 10.8 A 9.902 9.902 0 0 0 14.537 12.765 A 7.359 7.359 0 0 0 12.9 17.5 A 10.464 10.464 0 0 0 13.192 20.022 A 8.562 8.562 0 0 0 14 22.1 Q 15.056 24.019 18.091 25.847 A 23.503 23.503 0 0 0 18.35 26 A 33.003 33.003 0 0 0 20.566 27.172 Q 22.835 28.275 26.046 29.523 A 130.016 130.016 0 0 0 28.1 30.3 A 81.228 81.228 0 0 1 33.078 32.329 Q 37.464 34.3 40.45 36.4 A 26.044 26.044 0 0 1 43.49 38.878 Q 45.713 41.006 46.9 43.35 A 17.579 17.579 0 0 1 48.641 49.213 A 22.155 22.155 0 0 1 48.8 51.9 A 21.71 21.71 0 0 1 48.133 57.401 A 17.105 17.105 0 0 1 45.55 63 Q 42.3 67.6 36.55 70 A 30.521 30.521 0 0 1 29.14 72.004 A 40.027 40.027 0 0 1 23.4 72.4 Q 16.1 72.4 10.1 70.45 A 38.206 38.206 0 0 1 4.733 68.268 A 28.433 28.433 0 0 1 0 65.3 Z"
            stroke="#045096"
            fill="none"
            strokeWidth="5"
          />
        </svg>
      </div>
    </div>
  );
};

export default PaymentLoader;
