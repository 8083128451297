import React from 'react';
import InternalUserProfile from './InternalUserProfile/InternalUserProfile';
import ExternalUserProfile from './ExternalUserProfile/ExternalUserProfile';
import { useAuth } from '../../auth/auth';
import { useParams } from 'react-router-dom';

function UserProfile() {
  const { user, isOwnProfile } = useAuth();
  const { user: profileUser } = useParams();

  const isOwn = user && profileUser && isOwnProfile(profileUser);

  return isOwn ? (
    <InternalUserProfile username={profileUser} />
  ) : (
    <ExternalUserProfile username={profileUser} />
  );
}

export default UserProfile;
