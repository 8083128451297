import React from 'react';
import InvoiceHeader from '../../../Headers/InvoiceHeader/InvoiceHeader';
import Footer from '../../../Footer/Footer';
import Invoices from '../Invoices/Invoices';
import ProfileInvoice from '../ProfileInvoice/ProfileInvoice';
import CurrentInvoice from '../CurrentInvoice/CurrentInvoice';
import PaymentHistory from '../PaymentHistory/PaymentHistory';
import { useAuth } from '../../../../auth/auth';
import { Helmet } from 'react-helmet';
import './InvoicePage.css';

const InvoicePage = () => {
  const { userID } = useAuth();

  return (
    <div className="invoice-full-page">
      <InvoiceHeader />
      <Helmet>
        <title>Invoices</title>
      </Helmet>
        <div className="invoice-page">
          <div className="profile-section">
            <ProfileInvoice userID={userID} />
            <CurrentInvoice userID={userID} />
            <PaymentHistory userID={userID} />
          </div>
          <div className="invoices-section">
            <Invoices userID={userID} />
          </div>
        </div>
      <Footer />
    </div>
  );
};

export default InvoicePage;
