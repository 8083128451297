import React, { useState, useRef, useEffect } from 'react';
import { resendSignUpCode } from 'aws-amplify/auth';
import './ConfirmationModal.css';

const ConfirmationModal = ({ isOpen, onConfirm, setCode, code, username, errorMessage }) => {
  const [codeDigits, setCodeDigits] = useState(Array(6).fill(''));
  const inputsRef = useRef([]);

  useEffect(() => {
    if (codeDigits.every((digit) => digit !== '')) {
      onConfirm(codeDigits.join(''));
    }
  }, [codeDigits, onConfirm]);

  const handleChange = (e, index) => {
    const newCodeDigits = [...codeDigits];
    newCodeDigits[index] = e.target.value.slice(-1);
    setCodeDigits(newCodeDigits);
    setCode(newCodeDigits.join(''));

    if (e.target.value && index < inputsRef.current.length - 1) {
      inputsRef.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && !e.target.value) {
      inputsRef.current[index - 1].focus();
    }
  };

  const resendCode = async () => {
    await resendSignUpCode({ username });
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="confirmation-modal-overlay">
      <div className="confirmation-modal">
        <div className="confirmation-modal-header"></div>
        <div className="confirmation-modal-body">
          <h2>Confirm Email</h2>
          <div className="code-inputs">
            {codeDigits.map((digit, index) => (
              <input
                key={index}
                type="text"
                value={digit}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                ref={(el) => (inputsRef.current[index] = el)}
                maxLength="1"
              />
            ))}
          </div>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <div className='confirmation-modal-text-message'>
            No email? Check spam or 
            <span className="resend-link" onClick={resendCode}>resend code</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
