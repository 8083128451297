import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { useAuth } from '../../auth/auth';
import './SignInModal.css';

const SignInModal = ({ onClose }) => { 
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login } = useAuth(); 
  const navigate = useNavigate(); 

  const onCreateAccount = () => {
    navigate('/create-profile'); 
    onClose(); 
  };

  const handleSignIn = async (e) => { 
    e.preventDefault();

    if (!email) {
      setError('Please enter an email.');
      return;
    }

    if (!password) {
      setError('Please enter a password.');
      return;
    }

    try {
      await login(email, password); 
      onClose(); 
    } catch (error) {
      console.error('Error signing in:', error);
      setError(error.message);
    }
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password'); 
  };

  return (
    <div className="sign-in-modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>×</button>
        <label className="modal-sign-in-head">Log In</label>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleSignIn} className="sign-in-form">
          <div className="input-group">
            <label htmlFor="email">Email</label> 
            <input 
              type="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input 
              type="password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
            />
            <button type="button" onClick={handleForgotPassword} className="forgot-password-link">Forgot Password</button>
          </div>
          <button type="submit" className="sign-in-button">Log In</button>
        </form>
        <div className="create-account">
          <button onClick={onCreateAccount} className="create-account-button">Create Profile</button>
        </div>
      </div>
    </div>
  );
};

export default SignInModal;
