import React, { useState, useRef, useEffect } from 'react';

const CodeConfirmationModal = ({ isOpen, onConfirm, username, resendCode, errorMessage }) => {
  const [codeDigits, setCodeDigits] = useState(Array(6).fill(''));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const inputsRef = useRef([]);

  useEffect(() => {
    if (isOpen) {
      setCodeDigits(Array(6).fill(''));
      setIsSubmitting(false);
    }
  }, [isOpen]);

  const handleChange = async (e, index) => {
    if (isSubmitting) return;

    const newCodeDigits = [...codeDigits];
    newCodeDigits[index] = e.target.value.slice(-1);
    setCodeDigits(newCodeDigits);

    if (e.target.value && index < inputsRef.current.length - 1) {
      inputsRef.current[index + 1]?.focus();
    }

    if (newCodeDigits.every(digit => digit !== '')) {
      setIsSubmitting(true);
      const enteredCode = newCodeDigits.join('');
      const result = await onConfirm(enteredCode);
      
      if (!result.success) {
        setCodeDigits(Array(6).fill(''));
        inputsRef.current[0]?.focus();
      }
      setIsSubmitting(false);
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && !e.target.value) {
      const newCodeDigits = [...codeDigits];
      newCodeDigits[index - 1] = '';
      setCodeDigits(newCodeDigits);
      inputsRef.current[index - 1]?.focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    const pastedData = e.clipboardData.getData('text').slice(0, 6);
    const newCodeDigits = [...codeDigits];
    
    for (let i = 0; i < pastedData.length; i++) {
      if (/^\d$/.test(pastedData[i])) {
        newCodeDigits[i] = pastedData[i];
      }
    }
    
    setCodeDigits(newCodeDigits);
    
    if (newCodeDigits.every(digit => digit !== '')) {
      handleChange({ target: { value: newCodeDigits[5] } }, 5);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="confirmation-modal-overlay">
      <div className="confirmation-modal">
        <div className="confirmation-modal-header"></div>
        <div className="confirmation-modal-body">
          <h2>Enter Verification Code</h2>
          <p>Please enter the code sent to {username}</p>
          <div className="code-inputs">
            {codeDigits.map((digit, index) => (
              <input
                key={index}
                type="text"
                value={digit}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onPaste={index === 0 ? handlePaste : undefined}
                ref={(el) => (inputsRef.current[index] = el)}
                maxLength="1"
                disabled={isSubmitting}
                pattern="\d*"
                inputMode="numeric"
                autoComplete="off"
              />
            ))}
          </div>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          {isSubmitting && <div className="loading-message">Verifying code...</div>}
          <div className="confirmation-modal-text-message">
            No email? Check spam or{' '}
            <span 
              className="resend-link" 
              onClick={!isSubmitting ? resendCode : undefined}
              style={{ cursor: isSubmitting ? 'not-allowed' : 'pointer' }}
            >
              resend code
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodeConfirmationModal;