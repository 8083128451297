import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './auth/auth'; 
// Syn
import Mainframe from './components/Syn/Mainframe/Mainframe';
import SearchResults from './components/Syn/Searched/Searched';
import NotFound from './components/Syn/NotFound/NotFound';
// Footer
import Support from './components/Syn/Support/Support';
import AboutUs from './components/Syn/AboutUs/AboutUs';
import Terms from './components/Syn/Terms/Terms';
import Privacy from './components/Syn/Privacy/Privacy';
import Contact from './components/Syn/Contact/Contact';
// Forgot Password
import ForgotPassword from './auth/ForgotPassword/ForgotPassword';
// Create Profile
import CreateProfile from './components/CreateProfile/CreateProfile/CreateProfile'; 
// Profile
import UserProfile from './components/User/UserProfile';
import CompanyProfile from './components/Company/CompanyProfile';
// Schedule
import Schedule from './components/Company/Schedule/Schedule';
// Update Profile
import EditProfileUser from './components/UpdateProfile/EditProfileUser/EditProfileUser';
import EditProfileCompany from './components/UpdateProfile/EditProfileCompany/EditProfileCompany'
// Post
import PostHighlight from "./components/Post/PostHighlight/PostHighlight";
import PostReview from "./components/Post/PostReview/PostReview";
// User Invoices
import InvoicePage from "./components/Transaction/UserInvoice/InvoicePage/InvoicePage";
import Payment from "./components/Transaction/UserInvoice/Payment";
import InvoiceZoom from "./components/Transaction/UserInvoice/InvoiceZoom/InvoiceZoom";
// Company Invoices
import CompanyInvoicePage from "./components/Transaction/CompanyInvoice/CompanyInvoicePage/CompanyInvoicePage";
import CompanyInvoiceZoom from './components/Transaction/CompanyInvoice/CompanyInvoiceZoom/CompanyInvoiceZoom';
import SendInvoice from './components/Transaction/CompanyInvoice/SendInvoice/SendInvoice';
import Bank from "./components/Transaction/CompanyInvoice/Bank";
import UpdateBank from "./components/Transaction/CompanyInvoice/UpdateBank";

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Mainframe />} />
            <Route path="*" element={<NotFound />} /> 
            <Route path="/search-results/:type/:zip" element={<SearchResults />} />
            <Route path="/create-profile" element={<CreateProfile />} />  
            <Route path="/user/:user" element={<UserProfile />} />
            <Route path="/contractor/:companyUser" element={<CompanyProfile />} />
            <Route path="/contractor/:companyUser/calendar" element={<Schedule />} />
            <Route path="/support" element={<Support />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/contractor/:companyUser/post-highlight" element={<PostHighlight />} />
            <Route path="/contractor/:companyUser/edit-profile" element={<EditProfileCompany />} />
            <Route path="/user/:user/post-review" element={<PostReview />} />
            <Route path="/user/:user/edit-profile" element={<EditProfileUser />} />
            <Route path="/user/:user/invoice-dash" element={<InvoicePage />} />
            <Route path="/user/:user/invoice-dash/invoice/:invoiceID" element={<InvoiceZoom />} />
            <Route path="/user/:user/invoice-dash/card" element={<Payment />} />
            <Route path="/contractor/:companyUser/invoice-dash" element={<CompanyInvoicePage />} />
            <Route path="/contractor/:companyUser/invoice-dash/link-bank" element={<Bank />} />
            <Route path="/contractor/:companyUser/invoice-dash/update-bank" element={<UpdateBank />} />
            <Route path="/contractor/:companyUser/invoice-dash/invoice/:invoiceID" element={<CompanyInvoiceZoom />} />
            <Route path="/contractor/:companyUser/invoice-dash/new-invoice" element={<SendInvoice />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;