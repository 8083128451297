import React from 'react';
import './NotFound.css';
import { Helmet } from 'react-helmet';

const NotFound = () => {
  return (
    <div className="body-not-found">
      <Helmet>
        <title>Can't find it</title>
      </Helmet>
      <div className="not-found-container">
        <div className="nf-header">
          <img src='/favicon.png' alt="Company Logo" className="nf-logo" />
          <h1>Page not found</h1>
        </div>
        <div className="content-container">
          <p>The page you're looking for doesn't exist.</p>
          <div className="bottom-row">
            <a href="/" className="button">Back to search</a>
            <img src='/icons/broken.png' alt="Broken Sink" className="broken-sink" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
