import React, { useState, useEffect, useRef } from 'react';
import { generateClient } from "aws-amplify/api";
import { uploadData } from "aws-amplify/storage";
import { updateHighlight } from '../../../../graphQL/mutations';
import { useNavigate } from 'react-router-dom';
import "./EditHighlightModal.css";

const EditHighlightModal = ({ highlight, show, onClose, onUpdate }) => {
  const client = generateClient();
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [newAttachments, setNewAttachments] = useState([]);

  useEffect(() => {
    if (highlight) {
      setTitle(highlight.title);
      setDescription(highlight.description);
      setAttachments(highlight.attachmentkey.map(key => ({ name: key.split('/').pop(), key })));
    }
  }, [highlight]);

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleAttachmentsChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setNewAttachments((prev) => [...prev, ...newFiles]);
  };

  const handleRemoveAttachment = (index, isExisting) => {
    if (isExisting) {
      setAttachments((prev) => prev.filter((_, i) => i !== index));
    } else {
      setNewAttachments((prev) => prev.filter((_, i) => i !== index));
    }
  };

  const uploadToS3 = async (file) => {
    const key = `highlights/${Date.now()}_${file.name}`;
    try {
      await uploadData({
        key,
        data: file,
        options: {
          contentType: file.type,
          level: 'public',
        }
      });
      return key;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const newAttachmentKeys = await Promise.all(
      newAttachments.map(file => uploadToS3(file))
    );

    const updatedAttachmentKeys = [
      ...attachments.map(att => att.key),
      ...newAttachmentKeys
    ];

    const input = {
      id: highlight.id,
      title,
      description,
      attachmentkey: updatedAttachmentKeys,
      companyID: highlight.companyID,
    };

    try {
      const response = await client.graphql({
        query: updateHighlight,
        variables: { input },
      });

      onUpdate(response.data.updateHighlight);
      navigate(0);
      onClose();
    } catch (error) {
      console.error('Error updating highlight:', error);
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div className="edit-highlight-modal-backdrop" onClick={onClose}>
      <div className="edit-highlight-modal-content" onClick={(e) => e.stopPropagation()}>
        <form onSubmit={handleSubmit} className="edit-highlight-form">
          <div className="edit-highlight-form-header">Edit Highlight</div>
          <div className="edit-highlight-form-field">
            <input
              type="text"
              placeholder="Title"
              value={title}
              onChange={handleTitleChange}
              className="edit-highlight-input"
            />
          </div>
          <div className="edit-highlight-form-field">
            <textarea
              placeholder="Description"
              value={description}
              onChange={handleDescriptionChange}
              className="edit-highlight-textarea"
            />
          </div>
          <div className="edit-highlight-form-field file-upload-field">
            <label htmlFor="fileInput" className="edit-highlight-file-input-label">
              <span className="button-text">Choose File(s)</span>
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                multiple
                onChange={handleAttachmentsChange}
                className="file-input"
              />
            </label>
            {attachments && attachments.length > 0 && (
              <div className="edit-highlight-file-name-display">
                {attachments.map((file, index) => (
                  <div key={index} className="edit-highlight-file-item">
                    <span>{file.name}</span>
                    <button type="button" className="edit-highlight-remove-button" onClick={() => handleRemoveAttachment(index, true)}>x</button>
                  </div>
                ))}
              </div>
            )}
            {newAttachments && newAttachments.length > 0 && (
              <div className="edit-highlight-file-name-display">
                {newAttachments.map((file, index) => (
                  <div key={index} className="edit-highlight-file-item">
                    <span>{file.name}</span>
                    <button type="button" className="edit-highlight-remove-button" onClick={() => handleRemoveAttachment(index, false)}>x</button>
                  </div>
                ))}
              </div>
            )}
          </div>
          <button className="edit-highlight-post-button" type="submit">Update</button>
        </form>
        <button className="edit-highlight-close-button" onClick={onClose}>×</button>
      </div>
    </div>
  );
};

export default EditHighlightModal;
