import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-wrapper">
        <div className="footer-container">
          <div className="footer-company">
            <div className="company-header">
              <img src="/favicon.png" alt="Company Logo" className="company-logo" />
              <div className="footer-company-name">Spiggl</div>
            </div>
            <p className="mission-statement">Find, message, schedule, pay</p>
            <div className="download-apps">
              <div className="download-title">Easier ways to browse</div>
              <div className="app-icons">
                <a href="https://play.google.com/store/apps/details?id=yourapp" target="_blank" rel="noopener noreferrer">
                  <img src="/icons/android.png" alt="Android App" className="app-icon" />
                </a>
                <a href="https://apps.apple.com/us/app/yourapp/id123456789" target="_blank" rel="noopener noreferrer">
                  <img src="/icons/apple.png" alt="Apple App" className="app-icon" />
                </a>
              </div>
            </div>
          </div>
          <div className="footer-contact">
            <div className="contact-title">Contact</div>
            <span>
              Spiggl LLC<br />
              <br />
              30 S 15th St<br />
              Philadelphia, Pennsylvania 19102<br />
            </span>
            <br/>
            <a href="mailto:support@spiggl.com">support@spiggl.com</a>
          </div>
          <div className="footer-links">
            <div className="footer-links-title">Links</div>
            <ul className="footer-links-list">
              <li><Link to="/terms">Terms</Link></li>
              <li><Link to="/privacy">Privacy</Link></li>
              <li><Link to="/support">Support</Link></li>
              <li><Link to="/about-us">About Us</Link></li>
              <li><Link to="/contact">Contact Us</Link></li>
            </ul>
          </div>
          <div className="social-icons">
            <div className="social-title">Socials</div>
            <a href="https://x.com/spiggl" target="_blank" rel="noopener noreferrer">
              <img src="/icons/twitter.png" alt="Twitter Icon" className="social-icon" />
            </a>
            <a href="https://www.tiktok.com/spiggl" target="_blank" rel="noopener noreferrer">
              <img src="/icons/tik-tok.png" alt="TikTok Icon" className="social-icon" />
            </a>
            <a href="https://www.instagram.com/spiggl" target="_blank" rel="noopener noreferrer">
              <img src="/icons/instagram.png" alt="Instagram Icon" className="social-icon" />
            </a>
            <a href="https://www.linkedin.com/company/spiggl" target="_blank" rel="noopener noreferrer">
              <img src="/icons/linkedin.png" alt="LinkedIn Icon" className="social-icon" />
            </a>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="footer-border"></div>
          <p>© 2024 Spiggl LLC. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
