import React, { useState, useEffect } from 'react';
import './ExternalSchedule.css';
import { generateClient } from "aws-amplify/api";
import * as queries from "../../../../graphQL/queries";
import * as mutations from "../../../../graphQL/mutations";
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../auth/auth';
import { Helmet } from 'react-helmet';
import FormHeader from '../../../Headers/FormHeader/FormHeader';
import Footer from '../../../Footer/Footer';

const ExternalSchedule = () => {
  const { companyUser } = useParams();
  const { userID } = useAuth();
  const client = generateClient();
  const [schedule, setSchedule] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showModal, setShowModal] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [companyData, setCompanyData] = useState({});
  const [profilePicture, setProfilePicture] = useState("");
  const [showMonthPicker, setShowMonthPicker] = useState(false);
  const [hoveredTime, setHoveredTime] = useState(null);
  const [hoveredPosition, setHoveredPosition] = useState({ top: 0, left: 0 });
  const [requestData, setRequestData] = useState({
    startTime: '',
    duration: '',
    description: '',
    address: ''
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [userDetails, setUserDetails] = useState({}); 

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const { data } = await client.graphql({
          query: queries.listCompanies,
          variables: { filter: { companyUser: { eq: companyUser } } },
        });
        if (data.listCompanies.items.length > 0) {
          const company = data.listCompanies.items[0];
          setCompanyData(company);
          if (company.profilepicturekey) {
            setProfilePicture(`https://media.spiggl.com/public/${company.profilepicturekey}`);
          } else {
            setProfilePicture(createDefaultProfileImage(company.companyUser));
          }
          fetchSchedule(company.id);
        }
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    };

    const fetchSchedule = async (companyId) => {
      try {
        const { data } = await client.graphql({
          query: queries.schedulesByCompanyID,
          variables: { companyID: companyId },
        });
        if (data.schedulesByCompanyID) {
          setSchedule(data.schedulesByCompanyID.items);
        }
      } catch (error) {
        console.error('Error fetching schedule:', error);
      }
    };

    if (companyUser) {
      fetchCompanyData();
    }
  }, [companyUser, client]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const { data } = await client.graphql({
          query: queries.getUser,
          variables: { id: userID },
        });
        if (data && data.getUser) {
          setUserDetails(data.getUser); 
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    if (userID) {
      fetchUserDetails(); 
    }
  }, [userID, client]); 

  const handleDateClick = (date) => {
    const daySchedule = schedule.filter(entry => new Date(entry.day).toISOString().split('T')[0] === date.toISOString().split('T')[0]);
    setModalData(daySchedule);
    setSelectedDate(date);
    setShowModal(true);
  };

  const isTimeSlotAvailable = (startTime, duration) => {
    const dayUnavailable = schedule.some(entry => {
      return new Date(entry.day).toISOString().split('T')[0] === selectedDate.toISOString().split('T')[0] && entry.allDay;
    });

    if (dayUnavailable) {
      return false;
    }

    const start = new Date(`${selectedDate.toISOString().split('T')[0]}T${startTime}`);
    const end = new Date(start.getTime() + duration * 60 * 60 * 1000);

    for (const entry of schedule) {
      const entryStart = new Date(`${new Date(entry.day).toISOString().split('T')[0]}T${entry.from}`);
      const entryEnd = new Date(`${new Date(entry.day).toISOString().split('T')[0]}T${entry.to}`);

      if ((start >= entryStart && start < entryEnd) || (end > entryStart && end <= entryEnd) || (start <= entryStart && end >= entryEnd)) {
        return false;
      }
    }
    return true;
  };

  const invokeRequestEmailLambda = async (recipientEmail, senderName, requestDetails, companyUser) => {
    const API_ENDPOINT = process.env.REACT_APP_EMAIL_REQUEST_NOTIFICATION_API;
  
    try {
      const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          recipientEmail,
          senderName,
          requestDetails,
          companyUser, 
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to send request notification');
      }
  
      console.log('Request notification sent successfully');
    } catch (error) {
      console.error('Error sending request notification:', error);
    }
  };
  
  const handleRequestSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
  
    if (requestData.description.split(' ').length > 100) {
      setErrorMessage('Description should not exceed 100 words.');
      return;
    }
  
    if (!isTimeSlotAvailable(requestData.startTime, requestData.duration)) {
      setErrorMessage('Time slot unavailable.');
      return;
    }
  
    try {
      const input = {
        userID,
        companyID: companyData.id,
        requestDate: selectedDate.toISOString().split('T')[0],
        startTime: requestData.startTime,
        duration: requestData.duration,
        description: requestData.description,
        address: requestData.address,
        isConfirmed: false,
        isPending: true,
        putOff: false,
      };
  
      await client.graphql({ query: mutations.createRequest, variables: { input } });
  
      const requestDetails = {
        requestDate: selectedDate.toISOString().split('T')[0],
        startTime: convertToStandardTime(requestData.startTime),
        endTime: convertToStandardTime(addHours(requestData.startTime, requestData.duration)),
        description: requestData.description,
        address: requestData.address,
      };
  
      if (companyData.requestNotifications) {
        invokeRequestEmailLambda(
          companyData.email,
          userDetails.userName || userDetails.user, 
          requestDetails,
          companyData.companyUser
        );
      }
  
      setShowRequestModal(false);
      setShowModal(false);
      setSuccessMessage('Time requested, waiting for confirmation');
      setTimeout(() => setSuccessMessage(''), 4000);
    } catch (error) {
      console.error('Error submitting request:', error);
      setErrorMessage('Failed to submit request. Please try again.');
    }
  };

  const handleMonthClick = (month) => {
    setSelectedDate(new Date(selectedDate.getFullYear(), month, 1));
    setShowMonthPicker(false);
  };

  const handleMouseEnter = (event, entry) => {
    const rect = event.target.getBoundingClientRect();
    setHoveredPosition({ top: rect.top + window.scrollY, left: rect.left + window.scrollX });
    setHoveredTime(`${convertToStandardTime(entry.from)} - ${convertToStandardTime(entry.to)}`);
  };

  const handleMouseLeave = () => {
    setHoveredTime(null);
  };

  const renderDayView = () => {
    const adjustedDate = new Date(selectedDate);
    adjustedDate.setDate(selectedDate.getDate() + 1);

    const daySchedule = schedule.filter(entry => new Date(entry.day).toISOString().split('T')[0] === selectedDate.toISOString().split('T')[0]);

    return (
      <div className="day-view">
        <div className="day-view-header">
          {adjustedDate.toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' })}
        </div>
        <div className="timeline">
          {Array.from({ length: 18 }, (_, index) => {
            const hour = index + 6;
            const time = `${hour % 12 || 12} ${hour < 12 ? 'AM' : 'PM'}`;
            
            return (
              <div key={hour} className="timeline-slot">
                <div className="timeline-time">{time}</div>
                <div className="timeline-entries">
                  {daySchedule.map((entry, idx) => {
                    if (entry.allDay) {
                      if (hour === 6) {
                        return (
                          <div
                            key={idx}
                            className="timeline-entry all-day-entry"
                            style={{
                              top: '0%',
                              height: '1750%'
                            }}
                          >
                            <div className="entry-time">All Day</div>
                          </div>
                        );
                      }
                    } else {
                      const startHour = parseInt(entry.from.split(':')[0]);
                      const endHour = parseInt(entry.to.split(':')[0]);
  
                      if (startHour === hour) {
                        const entryHeight = ((endHour - startHour) * 60 + (parseInt(entry.to.split(':')[1]) - parseInt(entry.from.split(':')[1]))) / 60 * 100;
  
                        return (
                          <div
                            key={idx}
                            className="timeline-entry"
                            style={{
                              top: `${(parseInt(entry.from.split(':')[1]) / 60) * 100}%`,
                              height: `${entryHeight}%`
                            }}
                          >
                            <div className="entry-time">{convertToStandardTime(entry.from)} - {convertToStandardTime(entry.to)}</div>
                          </div>
                        );
                      }
                    }
  
                    return null;
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderCalendarDays = () => {
    const daysInMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0).getDate();
    const firstDayOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1).getDay();
    const calendarDays = [];
    for (let i = 0; i < firstDayOfMonth; i++) {
      calendarDays.push(<div key={`empty-${i}`} className="calendar-day empty"></div>);
    }
    for (let i = 1; i <= daysInMonth; i++) {
      const date = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), i));
      const daySchedule = schedule.filter(entry => new Date(entry.day).toISOString().split('T')[0] === date.toISOString().split('T')[0])
                                  .sort((a, b) => new Date(`1970-01-01T${a.from}Z`) - new Date(`1970-01-01T${b.from}Z`));
      const isUnavailable = daySchedule.some(entry => entry.allDay);
      const partialUnavailable = daySchedule.some(entry => !entry.allDay);
      calendarDays.push(
        <div key={i} className={`calendar-day ${isUnavailable ? 'unavailable' : partialUnavailable ? 'partial-unavailable' : ''}`} onClick={() => handleDateClick(date)}>
          <span className="day-number">{i}</span>
          {partialUnavailable && !isUnavailable && (
            <div className="availability-details">
              {daySchedule.filter(entry => !entry.allDay).slice(0, 3).map((entry, idx) => (
                <div 
                  key={idx} 
                  className="availability-time"
                  onMouseEnter={(event) => handleMouseEnter(event, entry)}
                  onMouseLeave={handleMouseLeave}
                >
                  {convertToStandardTime(entry.from)} - {convertToStandardTime(entry.to)}
                </div>
              ))}
            </div>
          )}
          {isUnavailable && <div className="unavailable-label">Unavailable</div>}
        </div>
      );
    }
    return calendarDays;
  };

  const addHours = (time, hours) => {
    const [hoursPart, mins] = time.split(':').map(Number);
    const date = new Date(0, 0, 0, hoursPart, mins);
    date.setHours(date.getHours() + Number(hours));
    return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  };

  const convertToStandardTime = (time) => {
    if (!time) return 'N/A';
    let [hours, minutes] = time.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    return `${hours}:${minutes.toString().padStart(2, '0')} ${period}`;
  };

  const createDefaultProfileImage = (user) => {
    const firstChar = user ? user.charAt(0).toUpperCase() : '';
    return `data:image/svg+xml;base64,${btoa(`<svg xmlns="http://www.w3.org/2000/svg" width="150" height="150">
      <circle cx="75" cy="75" r="75" fill="#23313d"/>
      <text x="50%" y="50%" font-family="Arial, sans-serif" font-size="64" dy=".3em" fill="#FFF" text-anchor="middle">${firstChar}</text>
    </svg>`)}`;
  };

  return (
    <div className="calendar-container">
      <FormHeader />
      <Helmet>
        <title>
          {companyData.companyName ? `${companyData.companyName}'s Calendar` : companyData.companyUser ? `${companyData.companyUser}'s Calendar` : 'Calendar'}
        </title>
      </Helmet>
      <div className="schedule">
        <div className="profile-header">
          {profilePicture && <img src={profilePicture} alt="Profile" />}
          <div className="company-name">
            {companyData.companyName || companyData.companyUser}
          </div>
        </div>
        <div className="calendar">
          <div className="calendar-header">
            <button onClick={() => setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth() - 1, 1))}> ❮ </button>
            <span onClick={() => setShowMonthPicker(true)}>{selectedDate.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span>
            <button onClick={() => setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 1))}> ❯ </button>
          </div>
          <div className="calendar-day-names">
            {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
              <div key={day} className="calendar-day-name">{day}</div>
            ))}
          </div>
          <div className="calendar-grid"> {renderCalendarDays()} </div>
        </div>
        {showModal && (
          <div className="calendar-modal-overlay">
            <div className="calendar-modal-content">
              <div className="modal-header sticky-header">
                <button className="calendar-close-modal-button" onClick={() => setShowModal(false)}>×</button>
                <div className="modal-unavailable-indicator"></div>
                <span>Unavailable</span>
                <button className="modal-header-request-time" onClick={() => setShowRequestModal(true)}>Request time</button>
              </div>
              {renderDayView()}
            </div>
          </div>
        )}
        {showRequestModal && (
          <div className="request-modal-overlay">
            <div className="request-modal-content">
              <button className="calendar-close-modal-button" onClick={() => setShowRequestModal(false)}>×</button>
              <form onSubmit={handleRequestSubmit}>
                <span className="request-label">Start Time</span>
                <input
                  type="time"
                  className="request-input"
                  value={requestData.startTime}
                  onChange={(e) => setRequestData({ ...requestData, startTime: e.target.value })}
                  required
                />
                <input
                  type="number"
                  className="request-input"
                  value={requestData.duration}
                  onChange={(e) => setRequestData({ ...requestData, duration: e.target.value })}
                  placeholder="Estimated duration (hours)"
                  required
                />
                <input
                  type="text"
                  className="request-input"
                  value={requestData.address}
                  onChange={(e) => setRequestData({ ...requestData, address: e.target.value })}
                  placeholder="Address"
                  required
                />
                <textarea
                  className="request-input"
                  value={requestData.description}
                  onChange={(e) => setRequestData({ ...requestData, description: e.target.value })}
                  placeholder="Job description (max 100 words)"
                  required
                />
                {errorMessage && <div className="error-message">{errorMessage}</div>}
                <button className="submit-request-modal-button" type="submit">Submit Request</button>
              </form>
            </div>
          </div>
        )}
        {showMonthPicker && (
          <div className="month-picker" onClick={() => setShowMonthPicker(false)}>
            <div className="month-picker-content" onClick={(e) => e.stopPropagation()}>
              <div className="month-picker-header">
                <button onClick={() => setSelectedDate(new Date(selectedDate.getFullYear() - 1, selectedDate.getMonth(), 1))}> ❮ </button>
                <span>{selectedDate.getFullYear()}</span>
                <button onClick={() => setSelectedDate(new Date(selectedDate.getFullYear() + 1, selectedDate.getMonth(), 1))}> ❯ </button>
              </div>
              <div className="month-picker-grid">
                {Array.from({ length: 12 }).map((_, index) => (
                  <div key={index} className="month-picker-item" onClick={() => handleMonthClick(index)}>
                    {new Date(0, index).toLocaleString('en-US', { month: 'long' })}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      {hoveredTime && (
        <div 
          className="hover-modal" 
          style={{ top: hoveredPosition.top, left: hoveredPosition.left }}
        >
          {hoveredTime}
        </div>
      )}
      <Footer />
      {successMessage && <div className="success-message-overlay"><div className="success-message">{successMessage}</div></div>}
    </div>
  );
};

export default ExternalSchedule;
