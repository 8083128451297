import React, { useState, useEffect, useCallback } from 'react';
import { generateClient } from 'aws-amplify/api';
import { useAuth } from "../../../auth/auth";
import { getUser, getCompany } from '../../../graphQL/queries';
import { updateUser, updateCompany } from '../../../graphQL/mutations';
import './Blocked.css';

const Blocked = () => {
  const [blockedEntities, setBlockedEntities] = useState([]);
  const [loading, setLoading] = useState(true);
  const { userID } = useAuth();
  const client = generateClient();

  const fetchBlockedEntities = useCallback(async () => {
    try {
      const result = await client.graphql({ query: getUser, variables: { id: userID } });
      const user = result.data.getUser;
      let blockedEntityData = [];

      if (!user) {
        const companyResult = await client.graphql({ query: getCompany, variables: { id: userID } });
        const company = companyResult.data.getCompany;

        if (company && company.blocked) {
          blockedEntityData = await Promise.all(
            company.blocked.map(async (blockedID) => {
              const blockedUserResult = await client.graphql({ query: getUser, variables: { id: blockedID } });
              if (blockedUserResult.data.getUser) {
                return { ...blockedUserResult.data.getUser, type: 'User' };
              }

              const blockedCompanyResult = await client.graphql({ query: getCompany, variables: { id: blockedID } });
              if (blockedCompanyResult.data.getCompany) {
                return { ...blockedCompanyResult.data.getCompany, type: 'Company' };
              }

              return null;
            })
          );
        }
      } else if (user.blocked) {
        blockedEntityData = await Promise.all(
          user.blocked.map(async (blockedID) => {
            const blockedUserResult = await client.graphql({ query: getUser, variables: { id: blockedID } });
            if (blockedUserResult.data.getUser) {
              return { ...blockedUserResult.data.getUser, type: 'User' };
            }

            const blockedCompanyResult = await client.graphql({ query: getCompany, variables: { id: blockedID } });
            if (blockedCompanyResult.data.getCompany) {
              return { ...blockedCompanyResult.data.getCompany, type: 'Company' };
            }

            return null;
          })
        );
      }

      setBlockedEntities(blockedEntityData.filter((entity) => entity !== null));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching blocked entities:', error);
      setLoading(false);
    }
  }, [userID, client]);

  const handleUnblockEntity = async (unblockedID) => {
    try {
      const result = await client.graphql({ query: getUser, variables: { id: userID } });
      const user = result.data.getUser;

      if (!user) {
        const companyResult = await client.graphql({ query: getCompany, variables: { id: userID } });
        const company = companyResult.data.getCompany;

        const updatedCompany = {
          ...company,
          blocked: company.blocked.filter(id => id !== unblockedID),
        };

        await client.graphql({
          query: updateCompany,
          variables: { input: { id: userID, blocked: updatedCompany.blocked } },
        });

        setBlockedEntities((prevBlockedEntities) => prevBlockedEntities.filter((entity) => entity.id !== unblockedID));
      } else {
        const updatedUser = {
          ...user,
          blocked: user.blocked.filter(id => id !== unblockedID),
        };

        await client.graphql({
          query: updateUser,
          variables: { input: { id: userID, blocked: updatedUser.blocked } },
        });

        setBlockedEntities((prevBlockedEntities) => prevBlockedEntities.filter((entity) => entity.id !== unblockedID));
      }
    } catch (error) {
      console.error('Error unblocking entity:', error);
    }
  };

  useEffect(() => {
    fetchBlockedEntities();
  }, [fetchBlockedEntities]);

  return (
    <div className="blocked-container">
      <div className="blocked-header">Blocked Entities</div>
      {loading ? (
        <div>Loading...</div>
      ) : blockedEntities.length === 0 ? (
        <div className="no-blocked-entities">You have no blocked users.</div>
      ) : (
        <div className="blocked-list">
          {blockedEntities.map((entity) => (
            <div key={entity.id} className="blocked-item">
              <div className="entity-info">
                <img
                  src={
                    entity.profilepicturekey
                      ? `https://media.spiggl.com/public/${entity.profilepicturekey}`
                      : '/default-profile.png' 
                  }
                  alt="Profile"
                  className="entity-profile-picture"
                />
                <div className="entity-details">
                  <div className="entity-name">{entity.name || entity.companyName}</div>
                  <div className="entity-username">{entity.user || entity.companyUser}</div>
                </div>
              </div>
              <button className="unblock-button" onClick={() => handleUnblockEntity(entity.id)}>
                Unblock
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Blocked;
