import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CheckUsername = ({ userType, onUsernameCheck, onChange }) => {
    const [query, setQuery] = useState('');
    const [isAvailable, setIsAvailable] = useState(null);
    const [isValidFormat, setIsValidFormat] = useState(true);

    const checkUsernameAvailability = async (username) => {
        try {
            const requestBody = {
                query: username,
                userType,
            };

            const response = await axios.post(process.env.REACT_APP_SEARCH_API, requestBody);
            const results = response.data;

            const isUsernameTaken = results.length > 0 && results.some(result => 
                (result.__typename === 'User' && result.user === username) || 
                (result.__typename === 'Company' && result.companyUser === username)
            );

            setIsAvailable(!isUsernameTaken);
            onUsernameCheck(!isUsernameTaken, isValidFormat);
        } catch (error) {
            console.error("Error making API call:", error);
            setIsAvailable(null);
            onUsernameCheck(null, isValidFormat);
        }
    };

    useEffect(() => {
        if (query.length > 0) {
            const delayDebounceFn = setTimeout(() => {
                const validFormat = /^[a-zA-Z0-9._-]{1,21}$/.test(query);
                setIsValidFormat(validFormat);

                if (validFormat) {
                    checkUsernameAvailability(query);
                } else {
                    setIsAvailable(null);
                    onUsernameCheck(null, false);
                }
            }, 500);

            return () => clearTimeout(delayDebounceFn);
        } else {
            setIsAvailable(null);
            setIsValidFormat(true);
            onUsernameCheck(null, true);
        }
    }, [query]);

    const handleInputChange = (e) => {
        const newUsername = e.target.value.replace(/\s+/g, '').slice(0, 21); 
        setQuery(newUsername);
        onChange(e); 
    };

    const getInputClass = () => {
        if (!isValidFormat) return 'invalid';
        if (isAvailable === false) return 'invalid';
        if (isValidFormat && isAvailable === true) return 'valid';
        return '';
    };

    return (
        <input
            type="text"
            value={query}
            onChange={handleInputChange}
            placeholder="Enter username"
            className={getInputClass()}
        />
    );
};

export default CheckUsername;