import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { generateClient } from "aws-amplify/api";
import { uploadData } from "aws-amplify/storage";
import { updateUser, deleteUser } from '../../../graphQL/mutations';
import { getUser } from '../../../graphQL/queries';
import { useAuth } from "../../../auth/auth";
import { Helmet } from 'react-helmet';
import UpdateCheckUsername from '../UpdateCheckUsername';
import DefaultBackgroundModal from '../../Syn/DefaultBackgroundModal/DefaultBackgroundModal';
import ImageCropModal from '../../Syn/ImageCrop/ImageCropModal';
import FormHeader from '../../Headers/FormHeader/FormHeader';
import Blocked from '../Blocked/Blocked';
import UserNotifications from '../UserNotifications/UserNotifications';
import ChangePassword from '../ChangePassword/ChangePassword';
import './EditProfileUser.css';
import backgrounds from '../../Syn/backgrounds';

const EditProfileUser = () => {
  const navigate = useNavigate();
  const client = generateClient();
  const { userID, SignOut } = useAuth();

  const [profileData, setProfileData] = useState({
    profilePicture: { file: null, previewUrl: '' },
    backgroundSelection: { file: null, name: '', previewUrl: '' },
    profilePictureKey: null,
    backgroundpicturekey: null,
    user: '',
    name: '',
    email: '',
    phone: '',
  });
 
  const [isDefaultBackgroundModalOpen, setIsDefaultBackgroundModalOpen] = useState(false);
  const [imageToCrop, setImageToCrop] = useState(null);
  const [imageCropModalOpen, setImageCropModalOpen] = useState(false);
  const [croppingFor, setCroppingFor] = useState('');
  const [userError, setUserError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const [errors, setErrors] = useState({
    user: '',
    email: '',
    profilePicture: '',
    backgroundPicture: '',
  });

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isFloatingMenuOpen, setIsFloatingMenuOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [deleteError, setDeleteError] = useState('');
  const [isUsernameValid, setIsUsernameValid] = useState(true);
  const [originalUsername, setOriginalUsername] = useState('');

  const [view, setView] = useState('profile');

  const toggleFloatingMenu = () => {
    setIsFloatingMenuOpen(!isFloatingMenuOpen);
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const { data } = await client.graphql({
          query: getUser,
          variables: { id: userID },
        });

        const userData = data.getUser;
        setProfileData({
          profilePicture: { file: null, previewUrl: userData.profilepicturekey ? `https://media.spiggl.com/public/${userData.profilepicturekey}` : createDefaultProfileImage(userData.user) },
          backgroundSelection: { file: null, previewUrl: `https://media.spiggl.com/public/${userData.backgroundpicturekey}` },
          profilepicturekey: userData.profilepicturekey,
          backgroundpicturekey: userData.backgroundpicturekey,
          user: userData.user,
          name: userData.name,
          email: userData.email,
          phone: userData.phone || '',
          messageNotifications: userData.messageNotifications,
          paymentNotifications: userData.paymentNotifications,
          requestSatusNotifications: userData.requestSatusNotifications,
          invoiceNotifications: userData.invoiceNotifications,
          pushMessageNotifications: userData.pushMessageNotifications,
          pushPaymentNotifications: userData.pushPaymentNotifications,
          pushRequestSatusNotifications: userData.pushRequestSatusNotifications,
          pushInvoiceNotifications: userData.pushInvoiceNotifications,
        });

        setOriginalUsername(userData.user);

      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    fetchProfileData();
  }, [userID]);

  const handleDefaultBackgroundModal = () => {
    setIsDefaultBackgroundModalOpen(!isDefaultBackgroundModalOpen);
  };

  const handleSelectBackground = async (backgroundName) => {
    const selectedBackground = backgrounds.find(bg => bg.name === backgroundName);
    const response = await fetch(selectedBackground.src);
    if (response.ok) {
      const blob = await response.blob();
      setProfileData((prevState) => ({
        ...prevState,
        backgroundSelection: { file: blob, name: selectedBackground.name, previewUrl: URL.createObjectURL(blob) }
      }));
      handleDefaultBackgroundModal();
    } else {
      console.error('Failed to fetch background image:', response.statusText);
    }
  };

  const handleProfilePictureChange = (event) => {
    if (event.target.files[0]) {
      setImageToCrop(URL.createObjectURL(event.target.files[0]));
      setCroppingFor('profilePicture');
      setImageCropModalOpen(true);
    }
  };

  const handleBackgroundPictureChange = (event) => {
    if (event.target.files[0]) {
      setImageToCrop(URL.createObjectURL(event.target.files[0]));
      setCroppingFor('backgroundPicture');
      setImageCropModalOpen(true);
    }
  };

  const handleCropComplete = (croppedBlob) => {
    if (croppingFor === 'profilePicture') {
      setProfileData((prevState) => ({
        ...prevState,
        profilePicture: {
          file: croppedBlob,
          previewUrl: URL.createObjectURL(croppedBlob),
        },
      }));
    } else if (croppingFor === 'backgroundPicture') {
      setProfileData((prevState) => ({
        ...prevState,
        backgroundSelection: {
          file: croppedBlob,
          previewUrl: URL.createObjectURL(croppedBlob),
        },
      }));
    }
  };

  const uploadToS3 = async (file, key, folder) => {
    const fullKey = `${folder}/${key}`;
    try {
      await uploadData({
        key: fullKey,
        data: file,
        options: {
          contentType: file.type,
          level: 'public',
        }
      });
      return fullKey;
    } catch (error) {
      console.error('Error uploading:', error);
      throw error;
    }
  };

  const handleUsernameCheck = (isAvailable, isValidFormat, newUsername) => {
    if (!isValidFormat) {
      setUserError('Invalid character');
      setIsUsernameValid(false);
    } else if (isAvailable === null) {
      setUserError('');
      setIsUsernameValid(false);
    } else if (!isAvailable && newUsername !== originalUsername) {
      setUserError('Username already exists');
      setIsUsernameValid(false);
    } else {
      setUserError('');
      setIsUsernameValid(true);
    }
  };

  const handleUsernameChange = (e) => {
    const newUsername = e.target.value.replace(/\s+/g, '');
    setProfileData((prevState) => ({ ...prevState, companyUser: newUsername }));
    setUserError('');
  };

  const handleNameChange = (e) => {
    const newName = e.target.value;
    if (newName.length <= 25) {
      setProfileData((prevState) => ({ ...prevState, name: newName }));
    }
  };

  const handlePhoneChange = (e) => {
    const digits = e.target.value.replace(/\D/g, '');

    let formattedValue = '';

    const parts = [
      digits.slice(0, 3),
      digits.slice(3, 6),
      digits.slice(6, 10)
    ];

    if (parts[0].length) {
      formattedValue += parts[0];
      if (parts[0].length === 3) {
        formattedValue += '-';
      }
    }
    if (parts[1].length) {
      formattedValue += parts[1];
      if (parts[1].length === 3) {
        formattedValue += '-';
      }
    }
    formattedValue += parts[2];

    setProfileData((prevState) => ({ ...prevState, phone: formattedValue }));

    if (formattedValue.length > 0 && formattedValue.length < 12) {
      setPhoneError('Invalid phone number');
    } else {
      setPhoneError('');
    }
  };

  const createDefaultProfileImage = (username) => {
    const firstChar = username ? username.charAt(0).toUpperCase() : '';
    return `data:image/svg+xml;base64,${btoa(
      `<svg xmlns="http://www.w3.org/2000/svg" width="150" height="150">
         <circle cx="75" cy="75" r="75" fill="#23313d"/>
         <text x="50%" y="50%" font-family="Arial, sans-serif" font-size="64" dy=".3em" fill="#FFF" text-anchor="middle">${firstChar}</text>
       </svg>`
    )}`;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let hasErrors = false;
    const newErrors = { ...errors };
    let updatedProfilePictureKey = profileData.profilepicturekey;
    let updatedBackgroundPictureKey = profileData.backgroundpicturekey;

    if (profileData.profilePicture.file) {
      try {
        updatedProfilePictureKey = await uploadToS3(profileData.profilePicture.file, `user_${profileData.user}_profile_picture.jpg`, 'profile');
      } catch (error) {
        setErrors((prevState) => ({ ...prevState, profilePicture: 'Error uploading profile picture' }));
        hasErrors = true;
      }
    }

    if (profileData.backgroundSelection.file) {
      try {
        updatedBackgroundPictureKey = await uploadToS3(profileData.backgroundSelection.file, `user_${profileData.user}_background_picture.jpg`, 'background');
      } catch (error) {
        setErrors((prevState) => ({ ...prevState, backgroundPicture: 'Error uploading background picture' }));
        hasErrors = true;
      }
    }

    if (profileData.phone && profileData.phone.replace(/\D/g, '').length !== 10) {
      setPhoneError('Invalid phone number');
      hasErrors = true;
    } else {
      setPhoneError('');
    }

    const requiredFields = ['user', 'email'];
    requiredFields.forEach(field => {
      if (!profileData[field] || (Array.isArray(profileData[field]) && profileData[field].length === 0)) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
        hasErrors = true;
      } else {
        newErrors[field] = '';
      }
    });

    if (!isUsernameValid) {
      newErrors.user = 'Invalid username';
      hasErrors = true;
    }

    setErrors(newErrors);

    if (hasErrors) return;

    const input = {
      id: userID,
      email: profileData.email,
      user: profileData.user,
      name: profileData.name,
      profilepicturekey: updatedProfilePictureKey,
      backgroundpicturekey: updatedBackgroundPictureKey,
      phone: profileData.phone || null,
    };

    try {
      const response = await client.graphql({
        query: updateUser,
        variables: { input },
      });

      console.log('Profile updated successfully:', response.data.updateUser);
      navigate(`/user/${profileData.user}`);
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const handleDeleteProfile = async () => {
    try {
      await SignOut();
      await client.graphql({
        query: deleteUser,
        variables: { input: { id: userID } },
      });
      console.log('Profile deleted successfully');
      navigate('/');
    } catch (error) {
      console.error('Error deleting profile:', error);
      setDeleteError('Error deleting profile');
    }
  };

  const confirmDelete = async (event) => {
    event.preventDefault();
    try {
      await client.auth.signIn(profileData.email, password);
      await handleDeleteProfile();
      setDeleteModalOpen(false);
    } catch (error) {
      setDeleteError('Incorrect password');
      console.error('Error confirming password:', error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsFloatingMenuOpen(true);
      } else {
        setIsFloatingMenuOpen(false); 
      }
    };

    handleResize(); 
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderView = () => {
    switch (view) {
      case 'profile':
        return (
          <div className="edit-profile-container">
            <div className="edit-profile-header">Edit Profile</div>
            <form onSubmit={handleSubmit}>
              <div className="attach-profile-picture">
                <label htmlFor="profilePictureInput" className="file-input-profile-picture">
                  <span className="button-text">Choose Profile Picture</span>
                  <input
                    type="file"
                    id="profilePictureInput"
                    accept="image/*"
                    onChange={handleProfilePictureChange}
                    className="file-input"
                  />
                </label>
                {profileData.profilePicture.previewUrl && (
                  <div>
                    <img src={profileData.profilePicture.previewUrl} alt="Profile Preview" className="profile-picture-preview" />
                    <div className="file-name-display">{profileData.profilePicture.file?.name || 'Current Picture'}</div>
                  </div>
                )}
              </div>
              <div className="background-picture-options">
                <button type="button" className="default-background-button" onClick={handleDefaultBackgroundModal}>
                  Choose Default Background
                </button>
                <span className="or-divider">or</span>
                <label htmlFor="backgroundPictureInput" className="file-input-background">
                  <span className="background-button">Choose Background Picture</span>
                  <input
                    type="file"
                    id="backgroundPictureInput"
                    accept="image/*"
                    onChange={handleBackgroundPictureChange}
                    className="file-input"
                  />
                </label>
                {isDefaultBackgroundModalOpen && (
                  <DefaultBackgroundModal
                    isOpen={isDefaultBackgroundModalOpen}
                    onClose={handleDefaultBackgroundModal}
                    onSelectBackground={handleSelectBackground}
                  />
                )}
              </div>
              {profileData.backgroundSelection.previewUrl && (
                <div>
                  <img src={profileData.backgroundSelection.previewUrl} alt="Background Preview" className="background-picture-preview" />
                  <div className="file-name-display">{profileData.backgroundSelection.name || 'Current Background'}</div>
                </div>
              )}
              <div className="username">
                <UpdateCheckUsername
                  userType="User"
                  onUsernameCheck={handleUsernameCheck}
                  value={profileData.user}
                  onChange={handleUsernameChange}
                  initialValue={originalUsername}
                />
                {errors.user && <div className="error-message">{errors.user}</div>}
              </div>
              <div className="name">
                <input
                  type="text"
                  placeholder="Name"
                  value={profileData.name}
                  onChange={handleNameChange}
                  maxLength="25"
                />
              </div>
              <div className="email">
                <input
                  type="email"
                  placeholder="*Email"
                  value={profileData.email}
                  readOnly
                  onChange={(e) => setProfileData((prevState) => ({ ...prevState, email: e.target.value }))}
                />
                {errors.email && <div className="error-message">{errors.email}</div>}
              </div>
              <div className="phone-number">
                <input
                  type="text"
                  placeholder="Phone Number"
                  value={profileData.phone}
                  onChange={handlePhoneChange}
                  maxLength="12"
                />
                {phoneError && <div className="error-message">{phoneError}</div>}
              </div>
              <div className="buttons-container">
                <button type="button" className="edit-profile-cancel-button" onClick={() => navigate(-1)}>
                  Cancel
                </button>
                <button type="submit" className="update-profile-button">
                  Update
                </button>
              </div>
            </form>
            <ImageCropModal
              isOpen={imageCropModalOpen}
              imageSrc={imageToCrop}
              onClose={() => setImageCropModalOpen(false)}
              onCropComplete={handleCropComplete}
              aspectRatio={croppingFor === 'profilePicture' ? 1 / 1 : 5 / 1}
            />
          </div>
        );
      case 'password':
        return <ChangePassword />;
      case 'notifications':
        return (
          <UserNotifications 
            messageNotifications={profileData.messageNotifications} 
            setMessageNotifications={(value) => setProfileData(prevState => ({ ...prevState, messageNotifications: value }))} 
            paymentNotifications={profileData.paymentNotifications} 
            setPaymentNotifications={(value) => setProfileData(prevState => ({ ...prevState, paymentNotifications: value }))} 
            requestSatusNotifications={profileData.requestSatusNotifications} 
            setRequestSatusNotifications={(value) => setProfileData(prevState => ({ ...prevState, requestSatusNotifications: value }))} 
            invoiceNotifications={profileData.invoiceNotifications} 
            setInvoiceNotifications={(value) => setProfileData(prevState => ({ ...prevState, invoiceNotifications: value }))} 
            pushMessageNotifications={profileData.pushMessageNotifications} 
            setPushMessageNotifications={(value) => setProfileData(prevState => ({ ...prevState, pushMessageNotifications: value }))} 
            pushPaymentNotifications={profileData.pushPaymentNotifications} 
            setPushPaymentNotifications={(value) => setProfileData(prevState => ({ ...prevState, pushPaymentNotifications: value }))} 
            pushRequestSatusNotifications={profileData.pushRequestSatusNotifications} 
            setPushRequestSatusNotifications={(value) => setProfileData(prevState => ({ ...prevState, pushRequestSatusNotifications: value }))} 
            pushInvoiceNotifications={profileData.pushInvoiceNotifications} 
            setPushInvoiceNotifications={(value) => setProfileData(prevState => ({ ...prevState, pushInvoiceNotifications: value }))} 
            userId={userID} 
          />
        );
      case 'blocked':
        return <Blocked />;
      default:
        return null;
    }
  };

  return (
    <div className='edit-profile'>
      <FormHeader />
      <Helmet>
        <title>Edit Profile</title>
      </Helmet>
      <div className="edit-profile-layout">
        <div className="sidebar">
          <button className={`sidebar-button ${view === 'profile' ? 'active' : ''}`} onClick={() => setView('profile')}>
            <img src="/icons/profile.png" alt="Profile" />
            Profile
          </button>
          <button className={`sidebar-button ${view === 'password' ? 'active' : ''}`} onClick={() => setView('password')}>
            <img src="/icons/password.png" alt="Password" />
            Password
          </button>
          <button className={`sidebar-button ${view === 'notifications' ? 'active' : ''}`} onClick={() => setView('notifications')}>
            <img src="/icons/notification.png" alt="notifications" />
            Notifications
          </button>
          <button className={`sidebar-button ${view === 'blocked' ? 'active' : ''}`} onClick={() => setView('blocked')}>
            <img src="/icons/block.png" alt="Blocked" />
            Blocked
          </button>
          <button className="delete-sidebar-button" onClick={() => setDeleteModalOpen(true)}>
            <img src="/icons/delete.png" alt="Delete" />
            Delete Profile
          </button>
        </div>
        <div className="edit-profile-form">
          {renderView()}
        </div>
        {(window.innerWidth < 700 || isFloatingMenuOpen) && (
          <button className="floating-menu-button" onClick={toggleFloatingMenu}>
            ☰
          </button>
        )}
        {isFloatingMenuOpen && (
          <div className="floating-menu">
            <button className={`sidebar-button ${view === 'profile' ? 'active' : ''}`} onClick={() => setView('profile')}>
              <img src="/icons/profile.png" alt="Profile" />
              Profile
            </button>
            <button className={`sidebar-button ${view === 'password' ? 'active' : ''}`} onClick={() => setView('password')}>
              <img src="/icons/password.png" alt="Password" />
              Password
            </button>
            <button className={`sidebar-button ${view === 'notifications' ? 'active' : ''}`} onClick={() => setView('notifications')}>
              <img src="/icons/notification.png" alt="notifications" />
              Notifications
            </button>
            <button className={`sidebar-button ${view === 'blocked' ? 'active' : ''}`} onClick={() => setView('blocked')}>
              <img src="/icons/block.png" alt="Blocked" />
              Blocked
            </button>
            <button className="delete-sidebar-button" onClick={() => setDeleteModalOpen(true)}>
              <img src="/icons/delete.png" alt="Delete" />
              Delete Profile
            </button>
          </div>
        )}
      </div>
      {deleteModalOpen && (
        <div className="delete-modal">
          <div className="delete-modal-content">
            <h2>Are you sure you want to delete your profile?</h2>
            <form onSubmit={confirmDelete}>
              <label htmlFor="password">Confirm Password</label>
                <input 
                  type="password" 
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              <div className="delete-modal-buttons">
                <button type="button" onClick={() => setDeleteModalOpen(false)}>Cancel</button>
                <button type="submit">Confirm</button>
              </div>
              {deleteError && <div className="error-message">{deleteError}</div>}
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditProfileUser;
