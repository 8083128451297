import architect from '../../default-banners/architect.png';
import bench from '../../default-banners/bench.png';
import bromine from '../../default-banners/bromine.png';
import city from '../../default-banners/city.png';
import electric from '../../default-banners/electric.png';
import growth from '../../default-banners/growth.png';
import interior from '../../default-banners/interior.png';
import landscape from '../../default-banners/landscape.png';
import lucid from '../../default-banners/lucid.png';
import pool from '../../default-banners/pool.png';
import rustic from '../../default-banners/rustic.png';
import shalom from '../../default-banners/shalom.png';
import solar from '../../default-banners/solar.png';
import vent from '../../default-banners/vent.png';
import workshop from '../../default-banners/workshop.png';

const backgrounds = [
  { name: 'architect', src: architect },
  { name: 'bench', src: bench },
  { name: 'bromine', src: bromine },
  { name: 'city', src: city },
  { name: 'electric', src: electric },
  { name: 'growth', src: growth },
  { name: 'interior', src: interior },
  { name: 'landscape', src: landscape },
  { name: 'lucid', src: lucid },
  { name: 'pool', src: pool },
  { name: 'rustic', src: rustic },
  { name: 'shalom', src: shalom },
  { name: 'solar', src: solar },
  { name: 'vent', src: vent },
  { name: 'workshop', src: workshop },
];

export default backgrounds;